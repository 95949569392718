<template>
  <div class="row justify-content-end align-items-center">
    <div class="col-md-2">
      <label class="form-label">Data inicial:</label>
      <Calendar class="fw-bold" v-model="baseDate"
                dateFormat="dd/mm/yy" :min-date="minDate"
                @date-select="changeBaseDate"
      />
    </div>
    <div class="col-md-10 d-none d-lg-block">
      <div class="row">
        <div class="col-6 col-md-2 px-0">
          <span class="btn btn-sm btn-outline btn-bg-secondary btn-active-light-dark"
                  style="cursor: default; padding: 0.10rem 1.25rem; min-width: 125px">
            <i class="me-2 bi-unlock-fill text-dark"></i>Agenda Fixa
          </span>
        </div>
        <div class="col-6 col-md-2 px-0">
          <span class="btn btn-sm btn-primary"
                  style="cursor: default; padding: 0.10rem 1.25rem; min-width: 125px">
            <i class="me-2 bi-unlock-fill"></i>Liberado
          </span>
        </div>
        <div class="col-6 col-md-2 px-0">
          <span class="btn btn-sm btn-outline btn-outline-primary btn-active-light-primary"
                  style="cursor: default; padding: 0.10rem 1.25rem; min-width: 125px">
            <i class="me-2 bi-lock-fill text-primary"></i>Não Liberado
          </span>
        </div>
        <div class="col-6 col-md-2 px-0">
          <span class="btn btn-sm btn-outline btn-outline-danger btn-active-light-danger"
                  style="cursor: default; padding: 0.10rem 1.25rem; min-width: 125px">
            <i class="me-2 bi-lock-fill text-danger"></i>Bloqueado
          </span>
        </div>
        <div class="col-6 col-md-2 px-0">
          <span class="btn btn-sm btn-outline btn-success"
                  style="cursor: default; padding: 0.10rem 1.25rem; min-width: 125px">
            <i class="me-2 bi-clock-fill"></i>Agendado
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 mb-6 mt-4" v-if="professional?.id_prof">
    <div class="table-responsive mt-3 pe-4">
      <table class="table table-sm table-borderless">
        <tbody>

        <tr>
          <th v-if="!hideBtnPreviousDatesProf" scope="col"
              class="text-start" @click="previousPageProf();"
              style="cursor: pointer;">
            <i class="bi bi-arrow-left text-primary"></i>
          </th>
          <th v-else></th>

          <th scope="col" class="text-center" v-for="availabilityDate in availabilityDates">
            <small>
              {{ availabilityDate.name_available_date }} <br>
              <b>
                {{
                  date(availabilityDate.available_date).format('DD')
                }}/{{ availabilityDate.name_month_available_date }}
              </b>
            </small>
          </th>

          <th v-if="hideBtnNextDatesProf"></th>
          <th v-else
              scope="col" class="text-end" @click="nextPageProf();"
              style="cursor: pointer;">
            <i class="bi bi-arrow-right text-primary"></i>
          </th>
        </tr>

        <tr v-if="availabilityDates <= 0">
          <td colspan="12">
            <img :src="require('@/assets/images/thinking_face-cuate.svg')"
                 alt="Doctor" class="rounded img-fluid mx-auto d-block w-25">
            <br>
            <p class="text-center text-muted para-desc mx-auto">
                    <span class="text-dark">
                      Nenhum horário disponível.
                    </span>
              <br>
            </p>
          </td>
        </tr>

        <tr v-else v-for="indexRow in (quantityRows = getQuantityRows(availabilityDates))"
            class="text-center"
            :class="quantityRows > 10 && indexRow > 10 && !expandedTimesProf ? 'd-none' : ''">
          <th></th>
          <td v-for="(availabilityDate, indexTime) in availabilityDates">

            <button class="btn btn-sm btn-outline btn-success d-flex align-items-center"
                    title="Esse horário está agendado."
                    style="padding-right: 0.25rem"
                    v-if="availabilityDate.availability_times[indexRow-1]?.busy === 1 && hasPermission('Update Availability')">
              <b>{{ availabilityDate.availability_times[indexRow - 1].available_time }}</b>
              <i class="ms-2 bi-clock-fill"></i>
            </button>

            <button @click="unblock(availabilityDate.availability_times[indexRow-1])"
                    class="btn btn-sm btn-outline btn-outline-danger btn-active-light-danger d-flex align-items-center"
                    :disabled="!availabilityDate.availability_times[indexRow-1].allow_unblock"
                    title="Esse horário está bloqueado"
                    style="padding-right: 0.25rem"
                    v-else-if="availabilityDate.availability_times[indexRow-1]?.block === 1 && hasPermission('Update Availability')">
              <b>{{ availabilityDate.availability_times[indexRow - 1].available_time }}</b>
              <i class="ms-2 bi-lock-fill text-danger"></i>
            </button>

            <button @click="blockHours(availabilityDate.availability_times[indexRow-1]) && hasPermission('Update Availability')"
                    class="btn btn-sm btn-outline btn-bg-secondary btn-active-light-dark d-flex align-items-center"
                    title="Esse horário está liberado pela agenda fixa"
                    style="padding-right: 0.25rem"
                    v-else-if="((isSeletedHour(availabilityDate.availability_times[indexRow-1]) ||
                              availabilityDate.availability_times[indexRow-1]?.exists_avail)) && !availabilityDate.availability_times[indexRow-1]?.allow_release">
              <b>{{ availabilityDate.availability_times[indexRow - 1].available_time }}</b>
              <i class="ms-2 bi-unlock-fill text-dark"></i>
            </button>

            <button @click="removeHour(availabilityDate.availability_times[indexRow-1], indexRow-1, indexTime)"
                    class="btn btn-sm btn-primary d-flex align-items-center"
                    title="Esse horário está liberado para agendamento"
                    style="padding-right: 0.25rem"
                    v-else-if="(availabilityDate.availability_times[indexRow-1]?.exists_avail || isSeletedHour(availabilityDate.availability_times[indexRow-1])) && hasPermission('Update Availability')">
              <b>{{ availabilityDate.availability_times[indexRow - 1].available_time }}</b>
              <i class="ms-2 bi-unlock-fill"></i>
            </button>

            <button @click="selectHour(availabilityDate.availability_times[indexRow-1])"
                    title="Clique para liberar o horário"
                    style="padding-right: 0.25rem"
                    class="btn btn-sm btn-outline btn-outline-primary btn-active-light-primary d-flex align-items-center"
                    v-else-if="availabilityDate.availability_times[indexRow-1] && hasPermission('Update Availability')">
              <b>{{ availabilityDate.availability_times[indexRow - 1].available_time }}</b>
              <i class="ms-2 bi-lock-fill"></i>
            </button>
          </td>
          <th></th>
        </tr>

        </tbody>
      </table>
    </div>
    <div class="text-center" v-if="quantityRows > 10">
      <button v-if="expandedTimesProf" class="btn btn-link"
              @click="toggleExpandedTimesProf()">
        Mostrar menos
        <i class="bi bi-arrow-up text-primary"></i>
      </button>
      <button v-else-if="availabilityDates?.length > 0"
              @click="toggleExpandedTimesProf()" class="btn btn-link">
        Mostrar todos
        <i class="bi bi-arrow-down text-primary"></i>
      </button>
    </div>
  </div>
  <div v-else>
    <p class="text-center">Nenhuma informação disponível</p>
  </div>
</template>

<script>
import http from "../../../../helpers/http";
import Permission from "../../../../helpers/permission";
import Calendar from "primevue/calendar";

export default {
  name: "AvailTime",
  components: {
   Calendar,
  },
  emits: ["onRefreshed"],
  props: {
    professional: null,
    specId: null,
    releaseFor: String,
    allowReturn: Boolean,
    refresh: Boolean,
  },
  data() {
    return {
      availabilityDates: [],
      selectedHours: new Set(),
      quantityRows: null,
      expandedTimesProf: false,
      hideBtnPreviousDatesProf: true,
      hideBtnNextDatesProf: false,
      baseDate: new Date(),
      minDate: new Date(),
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    refresh(newvalue, oldvalue) {
      if ((newvalue ?? false) && this.specId) {
        this.getTimes();
        this.$emit('onRefreshed');
      }
    },
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    getQuantityRows() {
      let quantityRows = 0;
      this.availabilityDates?.forEach(function (availabilityDate) {
        let quantityTimes = availabilityDate.availability_times?.length
        if (quantityTimes > quantityRows)
          quantityRows = quantityTimes;
      });
      return quantityRows;
    },
    toggleExpandedTimesProf() {
      this.expandedTimesProf = !this.expandedTimesProf;
    },
    isSeletedHour(availabilityTime) {
      return this.selectedHours.has(availabilityTime);
    },
    releaseHours(availabilityTime) {
      let form = {
        avail_date_begin: availabilityTime.available_date,
        avail_date_end: availabilityTime.available_date,
        release_for: this.releaseFor,
        allow_return: this.allowReturn,
        id_spec: this.specId,
        id_place: this.place.id_place,
        id_prof: this.professional?.id_prof,
        active: true,
        interval_minutes: availabilityTime.interval_minutes,
        time_begin: availabilityTime.available_time,
        time_end: availabilityTime.available_time,
        week_day: this.date(availabilityTime.available_date).day() + 1,
      };
      let url = `/places/${this.place.id_place}/professionals/${this.professional?.id_prof}/availabilities/release`;
      http.post(url, form).then((response) => {
        let availability = response.data;
        availabilityTime.id_avail = availability.id_avail;
      }).catch((e) => {
        let errors = e.response.data.errors;
        console.log(errors);
      });
    },
    selectHour(availabilityTime) {
      this.selectedHours.add(availabilityTime);
      this.releaseHours(availabilityTime)
    },
    removeHour(availabilityTime) {
      this.selectedHours.delete(availabilityTime);
      availabilityTime.exists_avail = 0;
      this.unReleaseHours(availabilityTime)
    },
    unblock(availabilityTime) {
      let url = `/places/${this.place.id_place}/professionals/${this.professional?.id_prof}/availabilities-blocks/${availabilityTime.id_avail_block}`;
      http.delete(url);

      availabilityTime.block = 0;
      this.selectHour(availabilityTime);
    },
    nextPageProf() {
      let availabilityDates = this.availabilityDates;

      let startAvailability = availabilityDates[1];
      let endAvailability = availabilityDates[availabilityDates.length - 1];
      if (!startAvailability) {
        this.hideBtnNextDatesProf = true;
        return;
      }

      let startDate = startAvailability.available_date;
      let endDate = endAvailability.available_date;
      http.get(`/places/${this.place.id_place}/professionals/${this.professional?.id_prof}/availabilities/all-times`, {
        params: {
          date_begin: this.date(startDate).format('YYYY-MM-DD'),
          date_end: this.date(endDate).add(1, 'day').format('YYYY-MM-DD'),
          id_spec: this.specId,
          avail_online: this.releaseFor === 'B' ? null : this.releaseFor === 'O',
          allow_return: this.allowReturn,
        }
      }).then((response) => {
        let availability = response.data;
        this.hideBtnNextDatesProf = false
        this.hideBtnPreviousDatesProf = false;
        this.availabilityDates = availability;
      });
    },
    previousPageProf() {
      let availabilityDates = this.availabilityDates;
      let startAvailability = availabilityDates[0];
      let endAvailability = availabilityDates[availabilityDates.length - 1];

      http.get(`/places/${this.place.id_place}/professionals/${this.professional?.id_prof}/availabilities/all-times`, {
        params: {
          date_begin: this.date(startAvailability.available_date).add(-1, 'day').format('YYYY-MM-DD'),
          date_end: this.date(endAvailability.available_date).add(-1, 'day').format('YYYY-MM-DD'),
          id_spec: this.specId,
          avail_online: this.releaseFor === 'B' ? null : this.releaseFor === 'O',
          allow_return: this.allowReturn,
        }
      }).then((response) => {
        let availability = response.data;
        let firstDate = availability[0].available_date;
        this.hideBtnPreviousDatesProf = this.date(firstDate) <= this.date();
        this.hideBtnNextDatesProf = false
        this.availabilityDates = availability;
      });
    },
    getTimes() {
      this.availabilityDates = null;
      console.log(this.baseDate);
      let params = {
        date_begin: this.date(this.baseDate).format('YYYY-MM-DD'),
        date_end: this.date(this.baseDate).add(7, 'day').format('YYYY-MM-DD'),
        id_spec: this.specId,
        avail_online: this.releaseFor === 'B' ? null : this.releaseFor === 'O',
        allow_return: this.allowReturn,
      };

      http.get(`/places/${this.place.id_place}/professionals/${this.professional?.id_prof}/availabilities/all-times`, {params: params}).then((response) => {
        this.availabilityDates = response.data;
      });
    },
    blockHours(availabilityTime) {
      let formBlock = {
        dsc_avail_block: 'Bloqueio de horário ' + availabilityTime.available_time,
        date_begin: availabilityTime.available_date,
        date_end: availabilityTime.available_date,
        time_begin: availabilityTime.available_time,
        time_end: availabilityTime.available_time,
        id_prof: this.professional?.id_prof,
      };
      let url = `/places/${this.place.id_place}/professionals/${this.professional?.id_prof}/availabilities-blocks`;
      http.post(url, formBlock).then((response) => {
        let availblock = response.data;
        availabilityTime.id_avail_block = availblock.id_avail_block;
        availabilityTime.block = 1;
        availabilityTime.allow_unblock = 1;
      });
    },
    unReleaseHours(availabilityTime) {
      let url = `/places/${this.place.id_place}/professionals/${this.professional?.id_prof}/availabilities/${availabilityTime.id_avail}/release`;
      http.delete(url, {data: {release_for: this.releaseFor}}).then(() => {
        availabilityTime.id_avail = null;
      });
    },
    changeBaseDate() {
      this.getTimes();
    },
  }
}
</script>

<style scoped>

</style>