<template>
  <div class="modal fade" tabindex="-1" id="modal_prof_place_paymethod" ref="modal_prof_place_paymethod">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
              Formas de recebimento por profissional<br>
              <span class="text-muted">{{ professional.prof_name }}</span>
          </h5>

          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="fv-row mb-5">
              <div class="row">
                  <div class="col-md-12">
                      <table class="table table-row-bordered gy-5">
                          <thead>
                          <tr class="fw-bold fs-6 text-muted">
                              <th>Nome</th>
                              <th>Ação</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="paymentMethod in paymentMethods">
                              <td>{{ paymentMethod.paymeth_name }}</td>
                              <td>
                                  <button class="btn btn-sm btn-danger me-1"
                                          v-if="paymentMethod.enabled_to_prof_place"
                                          title="Excluir" @click="removeProfPaymentMethod(paymentMethod.id_paymeth)">
                                      <i class="bi bi-trash"></i> Remover
                                  </button>
                                  <button class="btn btn-sm btn-success me-1"
                                          v-else
                                          title="Adicionar" @click="saveProfPaymentMethod(paymentMethod.id_paymeth)">
                                      <i class="bi bi-save"></i> Adicionar
                                  </button>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";

export default {
  name: "ModalProfPlacePaymethod",
  props: ['professional'],
  data() {
    return {
        paymentMethods: [],
    }
  },
  mounted() {
    const modalElement = document.getElementById('modal_prof_place_paymethod');
    modalElement.addEventListener('shown.bs.modal', () => {
      this.loadPaymentMethods();
    });
    modalElement.addEventListener('hidden.bs.modal', () => {
      this.paymentMethods = [];
    });
  },
  computed: {
      place() {
          return this.$store.state.auth.place;
      },
  },
  methods: {
      loadPaymentMethods() {
          return http.get(`/places/${this.place.id_place}/professionals/${this.professional.id_prof}/payment-methods`).then((response) => {
              this.paymentMethods = response.data;
          });
      },
      saveProfPaymentMethod(id) {
          let url = `/places/${this.place.id_place}/professionals/${this.professional.id_prof}/payment-methods`;
          return http.post(url, {id_paymeth: id}).then(() => {
              this.loadPaymentMethods();

              Alert.toastSuccess('Forma de pagamento adicionada com sucesso.');
          });
      },
      removeProfPaymentMethod(id) {
          Swal.fire({
              title: 'Remover forma de pagamento?',
              text: "Isso removerá as disponibilidades já existentes.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim, remover!',
              cancelButtonText: 'Cancelar',
              customClass: {
                  confirmButton: "btn btn-danger",
                  cancelButton: 'btn btn-secondary'
              }
          }).then((result) => {
              if (result.isConfirmed) {
                  let url = `/places/${this.place.id_place}/professionals/${this.professional.id_prof}/payment-methods/${id}`;
                  return http.delete(url).then(() => {
                      this.loadPaymentMethods();

                      Alert.toastSuccess('Forma de pagamento removida com sucesso.');
                  });
              }
          });
      },
  }
}
</script>

<style scoped>

</style>