<template>
    <div class="d-flex flex-row justify-content-center mt-2">
        <nav>
            <ul class="pagination" :class="styleType">
                <li class="page-item page-indicator">
                    <RouterLink class="page-link"
                       v-if="data.prev_page_url"  
                       :to="parseUrl(data.prev_page_url)">
                        <i class="fas fa-angle-double-left"></i>
                    </RouterLink>
                </li>

                <li class="page-item text-cefmEW" :class="{'active': link.active}" v-for="(link, k) in cleanLinks" :key="k">
                    <RouterLink class="page-link text-center" :to="link.url ? parseUrl(link.url) : ''">
                        {{ link.label }}
                    </RouterLink>
                </li>

                <li class="page-item page-indicator">
                    <RouterLink class="page-link"
                                  v-if="data.next_page_url"
                                  :to="parseUrl(data.next_page_url)">
                        <i class="fas fa-angle-double-right"></i>
                    </RouterLink>
                </li>
            </ul>
            <p class="text-muted text-center" style="font-size: 80%" v-if="data.total === 0">
                Nenhum registro encontrado
            </p>
            <p class="text-muted text-center" style="font-size: 80%" v-else>
                Exibindo {{ data.data?.length }} de {{ data.total }} registros
            </p>
        </nav>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        data: Object,
        queryParams: Object,
        styleType: String
    },
    computed: {
        cleanLinks() {
            const cleanLinks = [...this.data?.links ?? []];
            cleanLinks.shift();
            cleanLinks.pop();
            return cleanLinks;
        },
    },
    methods: {
        parseUrl(url) {
            const urlObj = new URL(url);
            const query = Object.fromEntries(urlObj.searchParams.entries());
            const path = this.$route.path;
            return { path, query };
        }
    }
}
</script>

<style scoped>

</style>
