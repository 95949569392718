<template>
  <div class="col-md-12">
    <div class="d-flex align-items-center position-relative my-1">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                    </svg>
                  </span>
      <input type="text"
             data-kt-user-table-filter="search"
             :value="modelValue"
             @input="updateValue"
             autofocus
             class="form-control form-control-solid ps-14"
             placeholder="Pesquisar o exame..."
             @keyup="getExams()">
    </div>
  </div>
  <div class="col-md-12 mb-6">
    <ul v-if="isOpenSearchExam" :id="'autocomplete_exams_' + idModal" class="scroll-y mh-200px mh-lg-350px">
      <li v-for="(exam, index) in exams" :key="index"  @click="setSearchExam(exam)" class="d-flex text-dark text-hover-primary align-items-center mb-5" >
        {{ exam.exam_tuss_code + ' - ' + exam.exam_name }}
      </li>
    </ul>
  </div>
</template>

<script>
import http from "../helpers/http";

export default {
  name: "InputSearchExam",
  emits: ["onSetSearchExam", "update:modelValue"],
  props: {
    modelValue: ''
  },
  data() {
    return {
      exams: [],
      isOpenSearchExam: false,
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    getExams() {
      http.get(`/exams`, {params:{ search: this.modelValue.toLowerCase()}}).then((response) => {
        this.exams = response.data;
        this.isOpenSearchExam = this.exams.length > 0;
      });
    },
    setSearchExam(exam) {
      this.$emit('update:modelValue', exam.exam_tuss_code + ' - ' + exam.exam_name);
      this.isOpenSearchExam = false;
      this.$emit('onSetSearchExam', exam);
    },
  }
}
</script>

<style scoped>

</style>