<template>
  <div class="modal fade" id="modal_waiting_list" data-bs-keyboard="false"
       data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="fw-bolder" data-kt-calendar="title">
            Lista de espera
          </h2>

          <button data-bs-toggle="collapse" href="#collapseWaitingList" role="button" aria-expanded="false"
                  @click="creating = !creating"
                  aria-controls="collapseWaitingList" class="btn" :class="creating ? 'btn-secondary' : 'btn-success'">
            {{creating ? 'Cancelar' : 'Adicionar'}}
          </button>

          <div class="btn btn-icon btn-sm btn-active-icon-primary"
               data-bs-dismiss="modal" aria-label="Fechar">
              <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="black"/>
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                        fill="black"/>
                </svg>
              </span>
          </div>
        </div>
        <div class="modal-body pt-1">
          <form @submit.prevent="onSubmit">
            <div id="collapseWaitingList" class="collapse row">
              <div class="col-md-5">
                <div class="fv-row">
                  <label class="fs-6 required fw-bold mb-2" for="id_prof">Profissional</label>
                  <Dropdown :options="professionals"
                            :filter="true"
                            appendTo="self"
                            @change="onChangeProf($event.value);"
                            v-model="form.id_prof"
                            optionLabel="prof_name"
                            optionValue="id_prof"
                            empty-message="Nenhuma opção"
                            empty-filter-message="Nenhuma opção encontrada"
                            id="id_prof"
                            class="form-control form-control-solid w-full md:w-14rem"
                            placeholder="Profissional"
                            style="display: flex;"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="fv-row">
                  <label class="fs-6 required fw-bold mb-2">Especialidade</label>
                  <Dropdown :options="specialties"
                            :filter="true"
                            appendTo="self"
                            v-model="form.id_spec"
                            optionLabel="spec_name"
                            optionValue="id_spec"
                            empty-message="Nenhuma opção"
                            empty-filter-message="Nenhuma opção encontrada"
                            id="id_spec"
                            class="form-control form-control-solid w-full md:w-14rem"
                            placeholder="Especialidades"
                            style="display: flex;"
                  />
                </div>
              </div>

              <div class="col-md-3">
                <div class="fv-row">
                  <label class="fs-6 fw-bold mb-2 required">Forma de recebimento</label>
                  <Dropdown :options="paymentMethods"
                            v-model="form.id_paymeth"
                            optionLabel="paymeth_name"
                            optionValue="id_paymeth"
                            appendTo="self"
                            empty-message="Nenhuma opção"
                            empty-filter-message="Nenhuma opção encontrada"
                            id="id_paymeth"
                            class="form-control form-control-solid w-full md:w-14rem"
                            placeholder="Forma de recebimento"
                            style="display: flex;"
                  />
                </div>
              </div>

              <div class="col-md-5">
                <div class="fv-row">
                  <label class="fs-6 fw-bold required mb-2">Paciente</label>
                  <SearchPatient v-model="form.wl_name"
                                 :value="form.wl_name"
                                 :professionalId="parseInt(form.id_prof)"
                                 :key="form.id_prof + '-' + uniqueKey"
                                 @onInput="onClickSearchedPatient"
                                 @onClick="onClickSearchedPatient"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="fv-row">
                  <label class="fs-6 required fw-bold mb-2">CPF</label>
                  <input type="text" class="form-control form-control-solid"
                         v-mask="'###.###.###-##'" required
                         placeholder="999.999.999-99" v-model="form.wl_cpf"/>
                </div>
              </div>

              <div class="col-md-3">
                <div class="fv-row">
                  <label class="fs-6 required fw-bold mb-2">Telefone</label>
                  <input type="text" class="form-control form-control-solid"
                         v-mask="['(##) ####-####', '(##) #####-####']" required
                         placeholder="(99) 9 9999-9999" v-model="form.wl_phone"/>
                </div>
              </div>

              <div class="col-md-8"  v-show="allowInsurance()">
                <div class="fv-row">
                  <label class="fs-6 fw-bold mb-2">Convênio</label>
                  <Dropdown :options="insurances"
                            :filter="true"
                            appendTo="self"
                            v-model="form.id_insurance"
                            optionLabel="insurance_name"
                            optionValue="id_insurance"
                            :filter-fields="['insurance_name', 'trading_name']"
                            empty-message="Nenhuma opção"
                            empty-filter-message="Nenhuma opção encontrada"
                            id="id_insurance"
                            class="form-control form-control-solid w-full md:w-14rem form-control-sm"
                            placeholder="Convênio"
                            style="display: flex;"
                  >
                    <template #option="slotProps">
                      <div class="flex align-items-center text-truncate w-75">
                        <div v-if="slotProps.option.trading_name">
                          {{ slotProps.option.trading_name }}
                          -
                          {{ slotProps.option.insurance_name }}
                        </div>
                        <div v-else>
                          {{ slotProps.option.insurance_name }}
                        </div>
                      </div>
                    </template>
                  </Dropdown>
                </div>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2 required">
                  Data limite
                  <i class="bi bi-info-circle" title="Até qual dia o cliente ficará na lista de espera."></i>
                </label>
                <Calendar class="fw-bold form-control-sm form-control" v-model="form.wl_wait_at" placeholder="DD/MM/YYYY"
                          dateFormat="dd/mm/yy" :min-date="new Date()"
                />
              </div>
              <div class="col-md-12">
                <label class="fs-6 fw-bold mb-2">Observação</label>
                <textarea class="form-control form-control-solid"
                          v-model="form.wl_obs"
                          placeholder="Observação da consulta"/>
              </div>

              <div class="col-md-12 text-end">
                <br>
                <button type="submit" class="btn btn-primary">Salvar lista de espera</button>
              </div>
            </div>
          </form>
          <hr>
          <div v-show="!creating">
            <div class="row">
              <p>Filtrar lista de espera: </p>
              <div class="col-md-6">
                <div class="fv-row">
                  <label class="fs-6 required fw-bold mb-2" for="id_prof">Profissional</label>
                  <Dropdown :options="professionals"
                            :filter="true"
                            appendTo="self"
                            showClear
                            @change="getWaitingLists()"
                            v-model="filter.id_prof"
                            optionLabel="prof_name"
                            optionValue="id_prof"
                            empty-message="Nenhuma opção"
                            empty-filter-message="Nenhuma opção encontrada"
                            id="id_prof"
                            class="form-control form-control-solid w-full md:w-14rem"
                            placeholder="Profissional"
                            style="display: flex;"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="fv-row">
                  <label class="fs-6 required fw-bold mb-2">Especialidade</label>
                  <Dropdown :options="specialties"
                            :filter="true"
                            appendTo="self"
                            showClear
                            @change="getWaitingLists()"
                            v-model="filter.id_spec"
                            optionLabel="spec_name"
                            optionValue="id_spec"
                            empty-message="Nenhuma opção"
                            empty-filter-message="Nenhuma opção encontrada"
                            id="id_spec"
                            class="form-control form-control-solid w-full md:w-14rem"
                            placeholder="Especialidades"
                            style="display: flex;"
                  />
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="table-responsive">
                <table class="table gs-7 gy-7 gx-7">
                  <thead>
                  <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th scope="col">Paciente</th>
                    <th scope="col">Contato</th>
                    <th scope="col">Profissional</th>
                    <th scope="col">Convênio</th>
                    <th scope="col">Obs</th>
                    <th scope="col">Data Cadastro</th>
                    <th scope="col">Data Limite</th>
                    <th scope="col">Ações</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="waitingList in waitingLists">
                    <th>{{waitingList.wl_name}}</th>
                    <td>{{waitingList.wl_phone}}</td>
                    <td>{{waitingList.professional?.prof_name}}</td>
                    <td>{{waitingList.insurance?.insurance_name}}</td>
                    <td>{{waitingList.wl_obs}}</td>
                    <td>{{this.date(waitingList.created_at).format('DD/MM/YYYY HH:mm')}}</td>
                    <td>{{this.date(waitingList.wl_wait_at).format('DD/MM/YYYY')}}</td>
                    <td>
                      <div class="btn-group" role="group">
                        <button class="btn btn-sm btn-success" @click="scheduleFromWaitingList(waitingList)">
                          <i class="bi bi-plus-circle"></i>
                        </button>
                        <button class="btn btn-sm btn-danger" @click="removeWaitingList(waitingList.id_wl)">
                          <i class="bi bi-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchPatient from "../../components/SearchPatient.vue";
import http from "../../helpers/http";
import {mask} from "vue-the-mask";
import Modal from "../../helpers/modal";
import Dropdown from "primevue/dropdown";
import Alert from "../../helpers/alert";
import Calendar from "primevue/calendar";

export default {
  name: "ModalWaitingList",
  components: {SearchPatient, Dropdown, Calendar},
  props: ['currentProf', 'currentSpec'],
  emits: ['onCloseModal', 'onClickBtnCreateSchedule'],
  directives: {
    mask
  },
  data() {
    return {
      waitingLists: [],
      specialties: [],
      paymentMethods: [],
      insurances: [],
      showFormStore: false,
      submiting: false,
      creating: false,
      uniqueKey: Date.now(),
      filter: {
        id_prof: '',
        id_spec: '',
      },
      form: {
        wl_name: '',
        wl_cpf: '',
        wl_phone: '',
        id_patient: '',
        id_user: '',
        id_insurance: '',
        id_paymeth: '',
        id_prof: '',
        id_spec: '',
        id_place: '',
        wl_wait_at: '',
        wl_obs: '',
      }
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professionals() {
      return this.$store.state.auth.professionals;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    Modal.onShow('#modal_waiting_list', () => {
      this.form.id_user = this.userAuth.id_user;
      this.form.id_prof = this.currentProf ?? this.userAuth.id_prof ?? '';
      this.form.id_spec = this.currentSpec ?? '';

      this.filter.id_prof = this.form.id_prof;
      this.filter.id_spec = this.form.id_spec;

      this.onChangeProf(this.form.id_prof);
      this.getWaitingLists();
    });
    Modal.onClose('#modal_waiting_list', () => {
      this.resetForm();
      this.$emit('onCloseModal')
    });
  },
  methods: {
    date: moment,
    resetForm() {
      this.form = {
        wl_name: '',
        wl_cpf: '',
        wl_phone: '',
        id_patient: '',
        id_user: '',
        id_insurance: '',
        id_paymeth: '',
        wl_wait_at: '',
        wl_obs: '',
      }
    },
    getWaitingLists() {
      http.get(`/places/${this.place.id_place}/waiting-lists`, {params: this.filter}).then((response) => {
        this.waitingLists = response.data;
      });
    },
    onChangeProf(idProf) {
      if (!idProf)
        return;

      this.loadSpecialties(idProf);
      this.loadPaymentMethods(idProf);
      this.loadInsurances(idProf);
    },
    scheduleFromWaitingList(waitingList) {
      this.$emit('onClickBtnCreateSchedule', waitingList);
      Modal.toggle('#modal_waiting_list');
    },
    removeWaitingList(id) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secondary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/waiting-lists/${id}`).then(_ => {
            this.getWaitingLists();

            Alert.toastSuccess("Paciente removido com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          });
        }
      });
    },
    onSubmit() {
      if (!this.form.wl_wait_at) {
        return Alert.toastwarning('Informe a data limite que o paciente ficará na lista de espera.');
      }

      if (!this.form.id_paymeth) {
        return Alert.toastwarning('Informe a forma de pagamento.');
      }

      if (!this.form.id_prof) {
        return Alert.toastwarning('Informe o profissional.');
      }

      if (!this.form.id_spec) {
        return Alert.toastwarning('Informe a especialidade.');
      }

      http.post(`/places/${this.place.id_place}/waiting-lists`, this.form).then(() => {
        this.getWaitingLists();
        this.resetForm();
        this.creating = false;

        Alert.toastSuccess("Paciente adicionado com sucesso.");
        new bootstrap.Collapse(document.getElementById('collapseWaitingList')).collapse();
      }).catch(e => {
        Alert.toastError(e.response.data);
      });
    },
    loadSpecialties(idProf) {
      return http.get(`/places/${this.place.id_place}/professionals/${idProf}/specialties`).then((response) => {
        this.specialties = response.data;
        if (this.specialties.length === 1) {
          this.form.id_spec = this.specialties[0].id_spec;
        }

        return response;
      });
    },
    loadPaymentMethods(idProf) {
      let params = {from_calendar: true}
      return http.get(`/places/${this.place.id_place}/professionals/${idProf}/payment-methods`, {params: params}).then((response) => {
        this.paymentMethods = response.data.filter(payMethod => payMethod.enabled_to_prof_place === 1 && ['BO', 'PL'].includes(payMethod.allowed_for));
        if (this.paymentMethods.length === 1) {
          this.form.id_paymeth = this.paymentMethods[0].id_paymeth;
        }

        return response;
      });
    },
    loadInsurances(idProf) {
      return http.get(`/places/${this.place.id_place}/professionals/${idProf}/insurances`).then((response) => {
        this.insurances = response.data.filter(insurance => insurance.enabled_to_prof_place === 1);
        return response;
      });
    },
    onClickSearchedPatient(patient) {
      if (patient.patient_name)
        this.form.wl_name = patient.patient_name;
      if (patient.patient_cpf)
        this.form.wl_cpf = patient.patient_cpf;
      if (patient.id_patient)
        this.form.id_patient = patient.id_patient;
      if (patient.patient_phone)
        this.form.wl_phone = patient.patient_phone;
      if (patient.id_insurance_last) {
        let exists = this.insurances.some(insurance => insurance.id_insurance === patient.id_insurance_last);
        if (exists)
          this.form.id_insurance = patient.id_insurance_last;
      }
    },
    allowInsurance() {
      if (!this.form.id_paymeth)
        return true;

      let paymentMethod = this.paymentMethods.find(p => p.id_paymeth === this.form.id_paymeth);
      return paymentMethod?.is_insurance;
    },
  }
}
</script>

<style>
.p-calendar > .p-inputtext {
  border: none!important;
  background-color: #f5f8fa;
}
</style>