<template>
    <Toolbar />
    <div class="container-xxl">
        <div class="card">
            <div class="card-header">
                <div class="card-title">
                    <div class="d-flex align-items-center position-relative my-1">
                      <span class="svg-icon svg-icon-1 position-absolute ms-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                              <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="black"></path>
                            </svg>
                        </span>
                        <input type="search" v-model="search"
                               @change="loadPatients(this.currentProfessionalId)"
                               class="form-control form-control-solid w-250px ps-14"
                               placeholder="Nome ou CPF...">
                    </div>
                </div>
                <div class="card-toolbar">
                    <div v-show="professionals.length > 1">
                        <DropdownUbbi
                            :showClear="true"
                            :options="professionals"
                            :filter="true"
                            @change="resetPage(); loadPatients($event);"
                            v-model="currentProfessionalId"
                            optionLabel="prof_name"
                            optionValue="id_prof"
                            id="id_prof"
                            placeholder="Selecione um profissional"
                        />
                    </div>
                </div>
            </div>
            <div class="card-body py-4">
              <div class="table-responsive">
                <table class="table table-hover table-row-bordered">
                    <thead>
                    <tr class="fw-bold fs-6 text-muted">
                        <th>Nome completo</th>
                        <th class="text-center">Data nascimento</th>
                        <th>Telefone</th>
                        <th>Último agendamento</th>
                        <th class="text-center">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="patient in patients.data">
                        <td class="d-flex align-items-center">
                            <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                <div class="symbol-label" @click.stop="takePicture(patient)">
                                    <img :src="url(patient.patient_photo)"
                                         v-if="patient.patient_photo"
                                         alt="Foto do patiente" class="w-100 h-100">
                                    <img :src="require('@/assets/images/no-photo.png')"
                                         alt="Foto do patiente" class="w-100 h-100" v-else/>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="text-gray-800 text-hover-primary mb-1">{{ patient.patient_name }}</span>
                                <span>{{ patient.patient_cpf }}</span>
                            </div>
                        </td>
                        <td class="text-center">
                            {{ patient.patient_birthdate_formatted }}
                            <br>
                            {{ patient.patient_full_age }}
                        </td>
                        <td>{{ maskIt(patient.patient_phone, '(##) #####-####') }}</td>
                        <td>
                            {{ patient.patient_last_schedule ? date(patient.patient_last_schedule).format('DD/MM/YYYY') : '' }}
                        </td>
                        <td class="text-center" style="min-width: 125px;">
                            <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-1 border border-gray-400"
                                    @click="medicalRecord(patient.id_patient)"
                                    data-bs-toggle="tooltip" title="Prontuário">
                                <i class="bi bi-clipboard2-pulse"></i>
                            </button>
                            <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-1 border border-gray-400" type="button"
                                    title="Editar paciente" @click="openModal = false; editPatient(patient)">
                                <i class="bi bi-pen"></i>
                            </button>
                            <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-1 border border-gray-400" type="button"
                                    title="Agendamentos" @click="openModalSchedules = false; showPatientSchedules(patient);">
                                <i class="bi bi-person-lines-fill"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Pagination :data="patients"/>
              </div>
            </div>

            <ModalPatient
                :patient="currrentPatient"
                :open-modal="openModal"
                :unique-key="uniqueKey"
                :key="'modal-patient-' + currrentPatient?.id_patient"
                @onCloseModal="openModal = false; currrentPatient = null"
            />

            <ModalPatientSchedules
                :patient="currrentPatient"
                :open-modal="openModalSchedules"
                :unique-key="uniqueKey"
                :key="'modal-schedules-' + currrentPatient?.id_patient"
                @onCloseModal="openModalSchedules = false; currrentPatient = null"
                @openModalEditSchedle="handleOpenModalEditSchedule"
            />

            <CalendarModalSchedule
                :schedule-edit="scheduleEdit"
                :open-modal="openModalScheduleEdit"
                :key="scheduleEdit?.sched_id"
                :showHistory="false"
                @onArriveSchedule="uniqueKey = this.date().unix().toString()"
                @onCloseModal="openModalScheduleEdit = false; scheduleEdit = null;"
                @onScheduleStore="uniqueKey = this.date().unix().toString()"
                @onScheduleCancel="uniqueKey = this.date().unix().toString()"
                @onConfirmSchedule="uniqueKey = this.date().unix().toString()"
                @onScheduleUpdate="uniqueKey = this.date().unix().toString()"
            />

            <ModalPhoto
                :openModal="openModalPhoto"
                :initialPhoto="currrentPatient?.patient_photo"
                @onSaveClick="savePhoto"
                @onClearClick="savePhoto"
                @onClose="openModalPhoto=false;"
            />
        </div>
    </div>
</template>

<script>
import http from "../../helpers/http";
import DropdownUbbi from "../../components/DropdownUbbi";
import ModalPatient from "./ModalPatient";
import ModalPatientSchedules from "./ModalPatientSchedules";
import Pagination from "../../components/Pagination";
import CalendarModalSchedule from "../calendar/ModalSchedule.vue";
import {ScheduleService} from "../../service/scheduleService";
import Alert from "../../helpers/alert";
import strMask from "../../helpers/strMask";
import str from "../../helpers/str";
import ModalPhoto from "../../components/ModalPhoto";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
    name: "Patient",
    components: {
        ModalPhoto,
        CalendarModalSchedule,
        DropdownUbbi,
        Pagination,
        ModalPatient,
        ModalPatientSchedules,
        Toolbar,
    },
    data() {
        return {
            uniqueKey: null,
            currentProfessionalId: '',
            search: '',
            patients: [],
            currrentPatient: null,
            openModal: null,
            openModalSchedules: null,
            openModalScheduleEdit: null,
            scheduleEdit: null,
            openModalPhoto: false,
        }
    },
    watch: {
        '$route.query.page'() {
            this.loadPatients(this.currentProfessionalId);
        },
    },
    computed: {
        place() {
            return this.$store.state.auth.place;
        },
        professionals() {
            return this.$store.state.auth.professionals;
        },
    },
    mounted() {
        this.currentProfessionalId = this.$store.state.auth.user.id_prof;
        this.loadPatients(this.currentProfessionalId);
    },
    methods: {
        date: moment,
        url(path) {
            return str.url(path)
        },
        maskIt(value, mask) {
            return strMask.it(value, mask);
        },
        takePicture(patient) {
            this.currrentPatient = patient;
            this.openModalPhoto = true;
        },
        savePhoto(photo) {
            this.openModalPhoto = false;

            const formData = new FormData();
            formData.append('_method', 'put');
            if (photo)
                formData.append('patient_photo', str.convertFileToBase64(photo.split(',')[1]));

            http({
                method: 'post',
                url: `/places/${this.place.id_place}/patients/${this.currrentPatient.id_patient}`,
                data: formData
            }).then((_) => {
                this.currrentPatient = null;
                this.$emit('onPhotoSaved');
                Alert.toastSuccess('Foto armazenada com sucesso!')
            }).catch((e) => {
                Alert.toastError(e.response.data);
            });
        },
        editPatient(patient) {
            this.currrentPatient = patient;
            this.openModal = true;
        },
        showPatientSchedules(patient) {
          this.uniqueKey = this.date().unix().toString()
          this.currrentPatient = patient;
          this.openModalSchedules = true;
        },
        loadPatients(professionalId) {
            this.patients = [];
            this.currentProfessionalId = professionalId;
            http.get(`/places/${this.place.id_place}/patients`, {
                params: {
                    'id_prof': this.currentProfessionalId,
                    'page': this.$route.query.page,
                    'search': this.search,
                    'per_page': 10,
                }
            }).then((response) => {
                this.patients = response.data;
            });
        },
        handleOpenModalEditSchedule(schedule) {
            this.openModalScheduleEdit = true;
            this.scheduleEdit = schedule;
        },
        arriveSchedule(idSched, isSchedArrive) {
            ScheduleService.new().arriveSchedule(this.place, idSched, isSchedArrive).then(() => {
                Alert.toastSuccess("Agendamento atualizada com sucesso.");
                this.uniqueKey = this.date().unix().toString();
            }).catch((e) => {
                Alert.toastError(e.response.data);
            });
        },
        medicalRecord(patientId) {
            if (this.currentProfessionalId && patientId)
                this.$router.push({path: `/patients/${patientId}`, query: {prof_id: this.currentProfessionalId}});
        },
        resetPage() {
            const query = {...this.$route.query, page: 1};
            this.$router.push({path: this.$route.path, query});
        }
    }
}
</script>

<style scoped>

</style>