<template>
  <div class="modal fade" tabindex="-1" id="modal_store_presc_prot">
    <div class="modal-dialog modal-xl">
      <form method="post" @submit.prevent="submitForm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isInserting?'Acicionar':'Editar' }} Protocolo</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-10">
                <label for="id_presc_prot_store" class="required form-label">Descrição do protocolo</label>
                <input class="form-control form-control-solid"
                       v-model="formStore.presc_prot_name" id="id_presc_prot_store" autofocus required>
                <small class="text-danger" v-if="formStoreErrors.presc_prot_name">{{ formStoreErrors.presc_prot_name[0] }}</small>
              </div>
              <div class="col-md-2 mt-12">
                <label for="presc_prot_active_store" class="required form-label">Ativo?</label>
                <input class="form-check-input" type="checkbox" v-model="formStore.active" id="presc_prot_active_store" checked>
                <small class="text-danger" v-if="formStoreErrors.active">{{ formStoreErrors.active[0] }}</small>
              </div>
            </div>
          </div>

          <div class="card ms-4">
            <hr>
            <h5 class="card-title mt-6">Medicamentos</h5>
            <div class="card-body">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <label for="drug_name_drug_store" class="required form-label required">Medicamento</label>
                    <textarea type="text" ref="drugNameDrugStore" class="form-control form-control-solid"
                           v-model="formStoreDrug.drug_name" id="drug_name_drug_store" autofocus maxlength="500" rows="3">
                    </textarea>
                    <small class="text-danger" v-if="formStoreDrugErrors.drug_name">{{ formStoreDrugErrors.drug_name[0] }}</small>
                  </div>
                  <div class="col-md-6">
                    <label for="dosage_desc_drug_store" class="required form-label required">Posologia</label>
                    <input class="form-control form-control-solid"
                           v-model="formStoreDrug.dosage_desc" id="dosage_desc_drug_store"  maxlength="500">
                    <small class="text-danger" v-if="formStoreDrugErrors.dosage_desc">{{ formStoreDrugErrors.dosage_desc[0] }}</small>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label for="presc_type_store" class="required form-label required">Tipo de Prescrição</label>
                    <select class="form-control form-select" id="presc_type_store"
                            aria-label="Selecione o tipo de prescrição" v-model="formStoreDrug.id_presc_type">
                      <option value="" disabled selected>--Selecione--</option>
                      <option :value="prescType.id_presc_type" v-for="prescType in prescTypes">
                        {{ prescType.presc_type_name }}
                      </option>
                    </select>
                    <small class="text-danger" v-if="formStoreDrugErrors.id_presc_type">{{ formStoreDrugErrors.id_presc_type[0] }}</small>
                  </div>
                  <div class="col-md-6">
                    <label for="drug_admin_store" class="required form-label required">Via de Administração</label>
                    <select class="form-control form-select" id="drug_admin_store"
                            aria-label="Selecione a via de adminstração" v-model="formStoreDrug.id_drug_admin">
                      <option value="" disabled selected>--Selecione--</option>
                      <option :value="drugAdmin.id_drug_admin" v-for="drugAdmin in drugAdmins">
                        {{ drugAdmin.drug_admin_name }}
                      </option>
                    </select>
                    <small class="text-danger" v-if="formStoreDrugErrors.id_drug_admin">{{ formStoreDrugErrors.id_drug_admin[0] }}</small>
                  </div>
                </div>

                <div class="col-md-4 mt-4">
                  <button type="button" v-if="!editingStore && hasPermission('Store Prescription Protocol Drug')" class="btn btn-primary" @click="addStoreDrug()">Incluir</button>
                  <button type="button" v-if="editingStore" class="btn btn-light me-2" @click="clearStoreDrug()">Cancelar</button>
                  <button type="button" v-if="editingStore && hasPermission('Update Prescription Protocol Drug')" class="btn btn-primary" @click="updStoreDrug()">Alterar</button>
                </div>

                <small class="text-danger" v-if="formStoreDrugErrors.drugs">{{ formStoreDrugErrors.drugs[0] }}</small>
                <div class="col-md-12 mb-6 mt-4">
                  <table class="table table-row-bordered table-hover table-light gy-3">
                    <thead>
                    <tr class="fw-bold fs-6 text-muted">
                      <th style="text-align: center">#</th>
                      <th>Medicamento</th>
                      <th>Posologia</th>
                      <th style="text-align: center">Adminsitração</th>
                      <th>Tipo de Prescrição</th>
                      <th style="text-align: center">Açoes</th>
                    </tr>
                    </thead>
                    <tr v-for="(drug, index) in formStore.drugs" :key="drug.id_presc_prot_drug">
                      <td style="text-align: center">{{ index + 1 }}</td>
                      <td style="white-space:pre-wrap; word-wrap:break-word">{{ drug.drug_name }}</td>
                      <td>{{ drug.dosage_desc }}</td>
                      <td style="text-align: center">{{ drugAdmins.find((element) => { return element.id_drug_admin === drug.id_drug_admin })?.drug_admin_name }}</td>
                      <td style="text-align: center">{{ prescTypes.find((element) => { return element.id_presc_type === drug.id_presc_type })?.presc_type_name }}</td>
                      <td style="text-align: center">
                        <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Update Prescription Protocol Drug')"
                                data-bs-toggle="tooltip" title="Editar Opção" @click="edtStoreDrug(index);">
                          <i class="bi bi-pencil-square"></i>
                        </button>
                        <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Delete Prescription Protocol Drug')"
                                data-bs-toggle="tooltip" title="Excluir Opção" @click="delStoreDrug(index);">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" :disabled="hasPermission(isInserting?'Store':'Update'+' Prescription Protocol Drug')" class="btn btn-primary">{{ isInserting?'Cadastrar':'Alterar'}}</button>
          </div>
        </div>
      </form>
    </div>
  </div> <!-- modal_store_presc_prot -->
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Permission from "../../helpers/permission";

export default {
  name: "PrescriptionProtocolDrugs",
  props: {
    prescProt: Object,
    prescTypes: Array,
    drugAdmins: Array,
    currentProfessionalId: Number,
  },
  data() {
    return {
      formStore: {
        id_presc_prot: '',
        presc_prot_name: '',
        active: true,
        drugs: [],
      },
      formStoreErrors: [],
      editingStore: false,
      indexStoreDrug: '',
      formStoreDrug: {
        id_presc_prot_drug: '',
        id_presc_prot: '',
        id_presc_type: '',
        id_drug_admin: '',
        drug_name: '',
        dosage_desc: '',
      },
      formStoreDrugErrors: [],
    }
  },
  computed: {
    isInserting() {
      return this.prescProt?.id_presc_prot == null;
    }
  },
  watch: {
    prescProt(newPrescProt, oldPrescProte) {
      if (newPrescProt)
        this.formStore = newPrescProt
      else
        this.clearStoreForm();
      this.clearStoreDrug();
    }
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    clearStoreForm() {
      this.formStore = {
        id_presc_prot: '',
        presc_prot_name: '',
        active: true,
        drugs: [],
      }
    },
    submitForm() {
      if (this.isInserting)
        this.submitFormStore();
      else
        this.submitFormEdit();
    },
    hasDrugs() {
      if (this.formStore?.drugs?.length === 0) {
        Alert.toastError("Protocolo deve ter no mínimo um medicamento associado.");
        return false;
      } else return true;
    },
    submitFormStore() {
      this.formStoreErrors = [];
      if (this.hasDrugs()) {
        http.post(`/professionals/${this.currentProfessionalId}/prescription-protocols`, this.formStore).then(_ => {
          Alert.toastSuccess("Protocolo cadastrado com sucesso.");
          this.clearStoreForm();
          bootstrap.Modal.getInstance(document.getElementById('modal_store_presc_prot')).toggle();
          this.$emit('onClose');
        }).catch(e => {
          Alert.toastError(e.response.data.message??e.response.data);
          if (e.response.data.errors) {
            this.formStoreErrors = e.response.data.errors;
          }
        });
      }
    },
    submitFormEdit() {
      this.formEditErrors = [];
      if (this.hasDrugs()) {
        http.put(`/professionals/${this.currentProfessionalId}/prescription-protocols/${this.formStore.id_presc_prot}`, this.formStore).then(() => {
          Alert.toastSuccess("Protocolo atualizado com sucesso.");
          bootstrap.Modal.getInstance(document.getElementById('modal_store_presc_prot')).toggle();
          this.$emit('onClose');
        }).catch(e => {
          Alert.toastError(e.response.data.message??e.response.data);
          if (e.response.data.errors) {
            this.formEditErrors = e.response.data.errors;
          }
        });
      }
    },
    clearStoreDrug() {
      this.formStoreDrug =
          {
            id_presc_prot_drug: '',
            id_presc_prot: '',
            id_presc_type: '',
            id_drug_admin: '',
            drug_name: '',
            dosage_desc: '',
          };
      this.editingStore = false;
    },
    addStoreDrug() {
      if (this.validateFormDrug()) {
        this.formStore.drugs.push(this.formStoreDrug);
        this.clearStoreDrug();
      }
    },
    edtStoreDrug(index) {
      this.formStoreDrug.id_presc_prot_drug = this.formStore.drugs[index].id_presc_prot_drug;
      this.formStoreDrug.id_presc_type      = this.formStore.drugs[index].id_presc_type;
      this.formStoreDrug.id_drug_admin      = this.formStore.drugs[index].id_drug_admin;
      this.formStoreDrug.drug_name          = this.formStore.drugs[index].drug_name;
      this.formStoreDrug.dosage_desc        = this.formStore.drugs[index].dosage_desc;
      this.indexStoreDrug = index;
      this.editingStore = true;
      this.$refs.drugNameDrugStore.focus();
    },
    delStoreDrug (index) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o medicamento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.formStore.drugs.splice(index,1);
          this.clearStoreDrug();
        }
      });
    },
    updStoreDrug() {
      this.formStore.drugs[this.indexStoreDrug] = this.formStoreDrug;
      this.clearStoreDrug();
    },
    validateFormDrug() {
      this.formStoreDrugErrors = [];
      var error = false;

      if (!this.formStoreDrug.drug_name) { error = true; this.formStoreDrugErrors.drug_name = [ "O nome do medicamento é obrigatório!" ] };
      if (!this.formStoreDrug.dosage_desc) { error = true; this.formStoreDrugErrors.dosage_desc = [ "A dosagem do medicamento é obrigatória!" ] };
      if (!this.formStoreDrug.id_presc_type) { error = true; this.formStoreDrugErrors.id_presc_type = [ "O tipo de prescrição é obrigatório!" ] };
      if (!this.formStoreDrug.id_drug_admin) { error = true; this.formStoreDrugErrors.id_drug_admin = [ "A posologia é obrigatória!" ] };

      return ! error;
    }
  },
}
</script>

<style scoped>

</style>