<template>
  <div class="table-responsive">
    <table class="table table-hover table-bordered table-row-dashed">
      <thead>
      <tr class="text-center fw-bolder">
        <th scope="col">#</th>
        <th scope="col">Data</th>
        <th scope="col">Telefone</th>
        <th scope="col">Mensagem</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="messages.length < 1">
        <td colspan="12" class="text-center">
          Nenhuma mensagem encontrada.
        </td>
      </tr>
      </tbody>
      <tbody :key="message.id_sm" v-for="message in messages" class="text-center">
      <tr>
        <td>
          <button class="btn btn-sm btn-outline btn-outline-secondary"
                  type="button" data-bs-toggle="collapse" v-if="(message.statuses?.length ?? 0) > 1"
                  :data-bs-target="'#subTableMessage-' + message.id_sm" aria-expanded="false"
                  aria-controls="collapseExample">
            <i class="bi bi-plus-circle"></i>
          </button>
        </td>
        <td>
          {{this.date(message.sm_sent_at).format('DD/MM/YYYY HH:mm')}}
        </td>
        <td>{{maskIt(message.sm_phone, '(##) #####-####')}}</td>
        <td>{{message.template}}</td>
      </tr>
      <tr>
        <td colspan="12" class="p-0">
          <div :id="'subTableMessage-' + message.id_sm" class="collapse">
            <hr>
            <h4 class="text-center">Histórico</h4>
            <hr>
            <table class="table table-bordered border-primary">
              <thead>
              <tr>
                <th>Data/Hora</th>
                <th>Status</th>
              </tr>
              </thead>
              <tbody v-for="status in message.statuses">
              <tr>
                <td>{{this.date(status.received_at).format('DD/MM/YYYY HH:mm')}}</td>
                <td>{{status.status_dsc}} <span v-if="status.status === 'answer' && message.sm_answer">({{message.sm_answer}})</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import strMask from "../../helpers/strMask";
import http from "../../helpers/http";

export default {
  name: "ScheduleMessageTable",
  props: ['scheduleId', 'uniqueKey'],
  data() {
    return {
      messages: [],
    }
  },
  watch: {
    uniqueKey() {
      this.getScheduleMessages();
    },
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  methods: {
    date: moment,
    maskIt(value, mask) {
      return strMask.it(value, mask);
    },
    getScheduleMessages() {
      if (!this.scheduleId)
        return;

      http.get(`/places/${this.place.id_place}/schedules/${this.scheduleId}/messages`).then((response) => {
        this.messages = response.data;
      });
    },
  }
}
</script>

<style scoped>

</style>