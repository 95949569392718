<template>

  <div v-if="!hasPermission('List Exam Protocol')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"></path>
                </svg>
              </span>
                <input type="text" data-kt-user-table-filter="search" v-model="search"
                       class="form-control form-control-solid w-250px ps-14"
                       placeholder="Pesquisar ...">
              </div>
            </div>
            <div class="card-toolbar">
              <div class="col-md-6" v-show="professionals.length > 1">
                <select class="form-select form-control-solid" aria-label="Selecionar Profissional"
                        v-model="currentProfessionalId" @change="loadExamProts($event.target.value)">
                  <option value="" disabled selected v-if="professionals.length > 1">--Selecione--</option>
                  <option :value="professional.id_prof" v-for="professional in professionals">
                    {{ professional.prof_name }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                        title="Novo Protocolo" data-bs-target="#modal_store_exam_prot"
                        v-if="hasPermission('Store Exam Protocol')"
                        @click="searchExamProt=''; currentExamProt=''">
                  <i class="bi bi-plus-lg"></i> Adicionar
                </button>
              </div>
            </div>
          </div> <!-- card header -->
          <div class="card-body py-4">
            <table class="table table-row-bordered gy-5">
              <thead>
              <tr class="fw-bold fs-6 text-muted">
                <th>Protocolo</th>
                <th style="text-align: center">Ativo</th>
                <th style="text-align: center">Ações</th>
              </tr>
              </thead>
              <tbody v-for="examProt in examProtsFiltered" :key="examProt.id_exam_prot">
              <tr>
                <td>{{ examProt.exam_prot_name }}</td>
                <td style="text-align: center">
                  <span class="badge badge-light-success" v-if="examProt.active">Sim</span>
                  <span class="badge badge-light-danger" v-else>Não</span>
                </td>
                <td style="text-align: center">
                  <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_store_exam_prot"
                          v-if="hasPermission('Update Exam Protocol')"
                          @click="onClickBtnEdit(examProt)"
                          title="Editar">
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          title="Ver detalhes"
                          rel="tooltip"
                          v-if="hasPermission('List Exam Protocol Item')"
                          @click="getExams(examProt.id_exam_prot);">
                    <i class="bi bi-eye"></i>
                  </button>
                  <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          v-if="hasPermission('Delete Exam Protocol')"
                          @click="deleteExamProt(examProt.id_exam_prot)"
                          data-bs-toggle="tooltip" title="Excluir Protocolo">
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
              <tr v-if="!examsHide  && currentExamProtId === examProt.id_exam_prot && !loading">
                <td colspan="3">
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-light-primary me-3" data-bs-toggle="modal"
                            v-if="hasPermission('Store Exam Protocol Item')"
                            title="Novo Exame" :data-bs-target="'#modal_store_exam_' + currentExamProtId">
                      <i class="bi bi-plus-lg"></i> Novo Exame
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="!examsHide  && (exams.length) && currentExamProtId === examProt.id_exam_prot && !loading">
                <td colspan="3">
                  <table class="table table-row-bordered table-hover table-light gy-3">
                    <thead>
                    <tr class="fw-bold fs-6 text-muted">
                      <th style="text-align: center">#</th>
                      <th style="text-align: center">TUSS</th>
                      <th>Exame</th>
                      <th style="text-align: center">Açoes</th>
                    </tr>
                    </thead>
                    <tr v-for="(exam, index) in exams" :key="exam.id_exam">
                      <td style="text-align: center">{{ index + 1 }}</td>
                      <td style="text-align: center">{{ exam.exam_tuss_code }}</td>
                      <td>{{ exam.exam_name }}</td>
                      <td style="text-align: center">
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Delete Exam Protocol Item')"
                                @click="deleteExam(exam.id_exam)"
                                data-bs-toggle="tooltip" title="Excluir Exame">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr> <!-- exam table -->
              </tbody>
            </table>
          </div> <!-- card body -->
        </div> <!-- card -->

        <ExamProtocolExams :current-professional-id="this.currentProfessionalId"
                           :exam-prot="currentExamProt"
                           @onClose="this.loadExamProts(this.currentProfessionalId); examsHide = true;">
        </ExamProtocolExams>

        <SearchExam :id-modal="currentExamProtId" @onSubmitFormStoreExam="submitFormStoreExam"></SearchExam>

      </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import draggable from 'vuedraggable'
import SearchExam from "../../components/SearchExam";
import ExamProtocolExams from "./ExamProtocolExams";
import Permission from "../../helpers/permission";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "ExamProt",
  components: {
    SearchExam,
    draggable,
    ExamProtocolExams,
      Toolbar,
  },
  data() {
    return {
      search: '',
      examsHide: true,
      loading: false,
      updatingExam: false,
      currentProfessionalId: '',
      currentExamProtId: '',
      currentExamProt: '',
      examProts: [],
      allExamProts: [],
      exams: [],
      searchExamProt: '',
      formStore: {
        id_exam_prot: '',
        id_prof: '',
        exam_prot_name: '',
        active: true
      },
      formStoreErrors: [],
      formEdit: {
        id_exam_prot: '',
        id_prof: '',
        exam_prot_name: '',
        active: false,
      },
      formEditErrors: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professionals() {
      return this.$store.state.auth.professionals;
    },
    examProtsFiltered() {
      return this.examProts.filter(examProt => {
        return examProt.exam_prot_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  mounted() {
    if (this.professionals.length > 0) {
      this.currentProfessionalId = this.$store.state.auth.user.id_prof ?? this.professionals[0].id_prof;
      this.loadExamProts(this.currentProfessionalId);
    }
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadExamProts(professionalId) {
      this.examProts = [];
      this.currentProfessionalId = professionalId;
      http.get(`/professionals/${professionalId}/exam-protocols`).then((response) => {
        this.examProts = response.data;
      });
    },
    onClickBtnEdit(examProt) {
      this.loadExams(examProt.id_exam_prot).then((response) => {
        this.currentExamProt = examProt;
        this.currentExamProt.exams = response.data;
      });
    },
    submitFormStoreExam(formStoreExam) {
      formStoreExam.id_exam_prot = this.currentExamProtId;
      http.post(`/professionals/${this.currentProfessionalId}/exam-protocols/${this.currentExamProtId}/exams`, formStoreExam).then(_ => {
        this.getExams(this.currentExamProtId, false);
        Alert.toastSuccess("Exame cadastrado com sucesso.");
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
    deleteExamProt(examProtId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o protocolo de exame?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.currentProfessionalId}/exam-protocols/${examProtId}`).then(_ => {
            this.loadExamProts(this.currentProfessionalId);
            Alert.toastSuccess("Protocolo excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    deleteExam(examId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o exame?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.currentProfessionalId}/exam-protocols/${this.currentExamProtId}/exams/${examId}`).then(_ => {
            this.getExams(this.currentExamProtId, false);
            Alert.toastSuccess("Exame excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    async getExams(examProtId, switchExamsHide = true) {
      if (!this.examsHide && switchExamsHide && this.currentExamProtId === examProtId) {
        this.examsHide = true;
        return;
      }
      this.loading = switchExamsHide ? true : this.loading;
      this.currentExamProtId = examProtId;
      this.loadExams(examProtId).then((response) => {
        this.exams = response.data;
        this.loading = false;
        this.examsHide = false;
      });
    },
    loadExams(examProtId) {
      return http.get(`/professionals/${this.currentProfessionalId}/exam-protocols/${examProtId}/exams`).then((response) => {
        return response;
      }).catch(e => {
        Alert.toastError(e.response.data);
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>
</style>