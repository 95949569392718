<template>
    <Toolbar />
    <div class="container-xxl">
      <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>Documento</th>
                  <th>Status</th>
                  <th>Área</th>
                  <th>Data/hora envio</th>
                  <th>Data/hora aprovação</th>
                </tr>
                </thead>
                <tbody v-for="(profDocument, index) in profDocuments">
                <tr>
                  <td>{{ profDocument.doc_type_name }}</td>
                  <td>
                      <span class="badge badge-primary" v-if="profDocument.status === 'P'">
                        Enviado
                      </span>
                    <span class="badge badge-success" v-if="profDocument.status === 'A'">
                        {{ profDocument.dsc_status }}
                      </span>
                    <span class="badge badge-danger" v-if="profDocument.status === 'R'">
                        {{ profDocument.dsc_status }}
                      </span>
                    <span class="badge badge-secondary" v-if="profDocument.status === null">
                        Não enviado
                      </span>
                  </td>
                  <td>{{ profDocument.kind_name }}</td>
                  <td>{{ profDocument.created_at ? date(profDocument.created_at).format('DD/MM/YYYY HH:mm:ss') : '' }}</td>
                  <td>{{ profDocument.validated_at ? date(profDocument.validated_at).format('DD/MM/YYYY HH:mm:ss') : '' }}
                  </td>
                </tr>
                <tr v-if="profDocument.status === null || profDocument.status === 'R'">
                  <td colspan="12">
                    <DropZone :headers="headers" :key="'dropzone-' + profDocument.id_doc_type"
                              maxFiles="2"
                              :acceptedFiles="['doc', 'image', 'pdf']"
                              class="h-100 mx-3"
                              :ref="'profDocument-' + profDocument.id_doc_type"
                              @uploaded="uploaded"
                              :url="endpointUpload(profDocument)">
                      <template v-slot:message>
                        <p class="mt-6 mb-3" v-if="profDocument.id_prof_document">
                          Arraste aqui o "{{ profDocument.doc_type_name }}" para alterar o documento anterior.
                        </p>
                        <p class="mt-6" v-else>
                          Arraste o "{{ profDocument.doc_type_name }}" aqui
                        </p>
                      </template>
                    </DropZone>

                    <hr v-if="profDocuments.length-1 > index">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import 'dropzone-vue/dist/dropzone-vue.common.css';
import DropZone from "dropzone-vue";
import Config from "@/config";
import store from "@/store";
import http from "@/helpers/http";
import Alert from "@/helpers/alert";
import Toolbar from "../../../components/layouts/auth/Toolbar.vue";

export default {
  name: "ProfDocument",
  components: {
    DropZone,
      Toolbar,
  },
  data() {
    return {
      headers: {
        'ubbi-app': Config.app_name,
        'ubbi-app-token': Config.app_token,
        'Authorization': `Bearer ${store.state.auth.token}`,
      },
      profDocuments: [],
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.getProfDocuments();
  },
  methods: {
    date: moment,
    endpointUpload(profDocument) {
      return Config.base_url + `/professionals/${this.userAuth.id_prof}/documents/upload?id_kind=${profDocument.id_kind}&id_doc_type=${profDocument.id_doc_type}`;
    },
    getProfDocuments() {
      http.get(`/professionals/${this.userAuth.id_prof}/documents`).then((response) => {
        this.profDocuments = response.data;
      });
    },
    uploaded() {
      Alert.toastSuccess('Arquivo enviado com sucesso.');
      this.getProfDocuments()
    },
  }
}
</script>

<style scoped>

</style>