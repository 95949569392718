<template>
    <div class="modal fade" tabindex="-1" id="modal_patient_schedules" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-xl">
            <form method="post">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Paciente
                        </h5>
                        <h4>{{patient?.patient_name}}</h4>
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                             @click="$emit('onCloseModal')"
                             data-bs-dismiss="modal" aria-label="Fechar">
                            <i class="bi bi-x-lg"></i>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <PatientScheduleTable
                                :uniqueKey="this.uniqueKey"
                                :patientId="this.patient?.id_patient"
                                :showBtnEdit="true"
                                @onClickBtnEditSchedule="onClickBtnEditSchedule"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import PatientScheduleTable from "./PatientScheduleTable";

export default {
    name: "ModalPatientSchedules",
    props: ['patient', 'openModal', 'uniqueKey'],
    emits: ['onCloseModal', 'openModalEditSchedle'],
    components: {PatientScheduleTable},
    mounted() {
        if (this.patient != null && this.openModal) {
            this.toggleModal();
        }
    },
    watch: {
        openModal(newValue, _) {
            if (newValue) {
                this.toggleModal();
            }
        },
    },
    methods: {
        toggleModal() {
            let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modal_patient_schedules'));
            modal.toggle();
        },
        onClickBtnEditSchedule(schedule) {
            //this.$emit('onCloseModal');
            this.$emit('openModalEditSchedle', schedule);
        }
    }
}
</script>

<style scoped>

</style>