<template>
    <Toolbar />
    <div class="container-xxl">
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <div class="d-flex align-items-center position-relative my-1">
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                </svg>
              </span>
              <input type="text" data-kt-user-table-filter="search" v-model="search"
                     class="form-control form-control-solid w-250px ps-14"
                     placeholder="Pesquisar ...">
            </div>
          </div>
          <div class="card-toolbar">
            <div class="col-md-12">
              <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                      title="Novo convite" data-bs-target="#modal_store_invite" @click="searchInvite='';formStore.emails=[];formStoreErrors=[]">
                <i class="bi bi-plus-lg"></i> Convidar Usuário
              </button>
            </div>
          </div>
        </div>
        <div class="card-body py-4">
          <table class="table table-row-bordered gy-5">
            <thead>
            <tr class="fw-bold fs-6 text-muted">
              <th>Data do Convite</th>
              <th class="text-center">Email</th>
              <th class="text-center">Status</th>
              <th class="text-center">Tipo de Acesso</th>
              <th class="text-center">Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="invite in invitesFiltered">
              <td>{{invite.invite_email}}</td>
              <td class="text-center">{{ date(invite.created_at).format('DD/MM/YYYY') }}</td>
              <td class="text-center">
                <span class="badge"
                      :class="invite.invite_status === 'S' ? 'badge-light-primary'
                             : invite.invite_status === 'A' ? 'badge-light-succes'
                             : invite.invite_status === 'R' ? 'badge-light-warning'
                             : 'badge-light-danger'
                            ">
                  {{ invite.invite_status_name}}
                </span>
              </td>

              <td class="text-center">{{invite.access_type.access_type_name}}</td>
              <td class="text-center">
                <button v-if="invite.invite_status==='S'" class="btn btn-icon btn-bg-light btn-active-color-primary me-1" @click="resendInvite(invite.id_invite)"
                        data-bs-toggle="tooltip" title="Reenviar Convite">
                  <i class="bi bi-send"></i>
                </button>
                <button v-if="invite.invite_status==='S'" class="btn btn-icon btn-bg-light btn-active-color-primary me-1" @click="cancelInvite(invite.id_invite)"
                        data-bs-toggle="tooltip" title="Cancelar Convite">
                  <i class="bi bi-x-square"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div> <!-- main card -->

      <div class="modal fade" tabindex="-1" id="modal_store_invite">
        <div class="modal-dialog modal-lg">
          <form method="post" @submit.prevent="submitFormStore">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Convidar Usuário</h5>
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
                  <i class="bi bi-x-lg"></i>
                </div>
              </div>
              <div class="modal-body">

                <div class="col-md-12">
                  <div class="mb-6">
                    <div class="mt-4">
                      <label for="id_access_type_store" class="required form-label">Selecione o Tipo de Acesso</label>
                      <select class="form-control  form-control-solid" id="id_access_type_store"
                              aria-label="Selecione o convite" v-model="formStore.id_access_type" required>
                        <option :value="accessType.id_access_type" v-for="accessType in accessTypes">
                          {{ accessType.access_type_name }}
                        </option>
                      </select>
                      <small class="text-danger" v-if="formStoreErrors.id_access_type">{{ formStoreErrors.id_access_type[0] }}</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group col-md-6">
                      <label for="add_user_email">E-mail(s) <span class="text-danger">*</span></label>
                      <div class="input-group mb-3">
                        <input type="email" class="form-control" id="add_user_email" v-model="email"
                               maxlength="100">
                        <div class="input-group-append">
                          <button class="btn btn-md btn-primary" type="button"
                                  title="Adicionar e-mail" @click="addEmail">
                            <i class="bi bi-plus"></i>
                          </button>
                        </div>
                      </div>
                      <div v-for="(email, index) in formStore.emails">
                        <span class="badge badge-secondary" style="margin-bottom: 3%">
                          {{ email }}
                          &nbsp &nbsp
                          <i class="bi bi-trash" style="color: red; cursor: pointer"
                             @click="removeEmail(index)"></i>
                        </span>
                        <br>
                      </div>
                    </div>
                    <small class="text-danger" v-if="formStoreErrors.emails">{{ formStoreErrors.emails[0] }}</small>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                <button type="submit" class="btn btn-primary">Cadastrar</button>
              </div>
            </div>
          </form>
        </div>
      </div> <!-- modal_store_invite -->
    </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Invite",
    components: {
        Toolbar,
    },
  data() {
    return {
      search: '',
      invites: [],
      accessTypes: [],
      searchInvite: '',
      formStore: {
        emails: [],
        id_access_type: '',
      },
      formStoreErrors: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    invitesFiltered() {
      return this.invites.filter(invite => {
        return invite.invite_email.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  mounted() {
    this.loadAccessTypes();
    this.loadInvites();
  },
  methods: {
    date: moment,
    loadAccessTypes() {
      http.get(`/places/${this.place.id_place}/access-types/for-invite`).then((response) => {
        this.accessTypes = response.data;
      });
    },
    loadInvites() {
      http.get(`/places/${this.place.id_place}/users/invites`).then((response) => {
        this.invites = response.data;
      });
    },
    addEmail() {
      let email = this.email;

      if (!email || this.searchAddedEmail(email))
        return;

      this.formStore.emails.push(email);
      this.email = null;
    },
    removeEmail(index) {
      this.formStore.emails.splice(index, 1);
    },
    searchAddedEmail(email) {
      for (let i = 0; i < this.formStore.emails.length; i++) {
        if (this.formStore.emails[i] === email) {
          return this.formStore.emails[i];
        }
      }
      return null;
    },
    submitFormStore() {
      http.post(`/places/${this.place.id_place}/users/invites`, this.formStore).then(_ => {
        this.loadInvites();
        Alert.toastSuccess("Convite cadastrado com sucesso.");
        this.formStoreErrors = [];
        this.formStore = {
          emails: [],
          id_access_type: '',
        };
        bootstrap.Modal.getInstance(document.getElementById('modal_store_invite')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    cancelInvite(inviteId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja cancelar o convite?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.patch(`/places/${this.place.id_place}/users/invites/${inviteId}/cancel`).then(_ => {
            this.loadInvites();
            Alert.toastSuccess("Convite cancelado com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data.message??e.response.data);
          })
        }
      });
    },
    resendInvite(inviteId) {
      http.post(`/places/${this.place.id_place}/users/invites/${inviteId}/resend`).then(_ => {
        Alert.toastSuccess("Convite reenviado com sucesso.");
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
      });
    }
  }
}
</script>

<style scoped>

</style>