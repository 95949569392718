<template>
  <div class="card bg-light">
    <div class="card-rounded bg-light d-flex flex-stack flex-wrap p-5">
      <ul class="nav flex-wrap border-transparent fw-bold">
        <li v-for="(healthIndicatorGroup, index) in healthIndicatorGroups" class="nav-item">
          <button
              class="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-4 mx-1"
              :class="index === tabActiveIndex ? 'active' : ''"
              @click="tabActiveIndex = index; getPatientHealthData(healthIndicatorGroup.id_health_indicator_group)">
            {{ healthIndicatorGroup.health_indicator_group_name }}
          </button>
        </li>
      </ul>
      <button v-if="!readonly" href="#" data-bs-toggle="modal" :data-bs-target="'#modal_store_patient_health_data_' + medicalRecord?.id_medrec"
         class="btn btn-primary fw-bold fs-8 fs-lg-base" @click="currentPatientHealthData = null">Novo Registo</button>
    </div>

    <div class="row g-6 g-xl-9 px-5 pb-5">
      <div v-for="healthData in patientHealthData" class="col-md-6 col-xl-4">
        <a class="card border-hover-primary">
          <div v-if="!readonly" class="row d-flex flex-row-reverse">
            <label v-if="healthData.id_sched === schedule.id_sched" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-bs-toggle="tooltip" title="excluir">
              <i class="bi bi-trash-fill fs-7" @click="deleteHealthData(healthData.id_patient_health_data)"></i>
            </label>
            <label v-if="healthData.id_sched === schedule.id_sched"
                   class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                   data-bs-toggle="tooltip" >
              <i class="bi bi-pencil-fill fs-7" data-bs-toggle="modal"
                 :data-bs-target="'#modal_store_patient_health_data_' + medicalRecord?.id_medrec"
                 @click="currentPatientHealthData = healthData"></i>
            </label>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8 fs-6 fw-bold text-dark">{{date(healthData.created_at).format('DD/MM/YYYY HH:mm')}}</div>
            </div>
            <div class="d-flex flex-wrap mt-5">
              <div v-for="healthIndicator in healthData.health_indicators" class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                <div class="fw-semibold text-gray-600">{{healthIndicator.health_indicator_name}}</div>
                <div class="fs-6 text-gray-800 fw-bold">
                    {{healthIndicator.patient_health_data_values.health_data_value}} {{healthIndicator.scale.scale_initials}}
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <HealthDataModal
      :patient-health-data="currentPatientHealthData"
      :patient-id="medicalRecord?.id_patient"
      :professional-id="medicalRecord?.id_prof"
      :healthIndicators="healthIndicators"
      :sched-id="medicalRecord?.id_sched"
      :key="medicalRecord?.id_medrec"
      :id="'modal_store_patient_health_data_' + medicalRecord?.id_medrec"
      @onClose="this.getPatientHealthData(this.currentHealthIndicatorGroupId)">
  </HealthDataModal>

</template>

<script>
import http from "../../../helpers/http";
import Alert from "../../../helpers/alert";
import HealthDataModal from "../shared/HealthDataModal";

export default {
  name: "HealthData",
  components: {
    HealthDataModal,
  },
  props: {
    schedule: Object,
    medicalRecord: Object,
    healthIndicatorGroups: Array,
    readonly: Boolean,
  },
  data() {
    return {
      tabActiveIndex: 0,
      patientHealthData: [],
      healthIndicators: [],
      currentHealthIndicatorGroupId: '',
      currentPatientHealthData: null,
    }
  },
  watch: {
    healthIndicatorGroups(newHealthIndicatorGroups) {
      this.getPatientHealthData(newHealthIndicatorGroups[0]?.id_health_indicator_group);
    }
  },
  mounted() {
    this.getHelthIndicators();
    this.getPatientHealthData(this.healthIndicatorGroups[0]?.id_health_indicator_group);
  },
  methods: {
    date: moment,
    getPatientHealthData(healthIndicatorGroupId) {
      this.currentHealthIndicatorGroupId = healthIndicatorGroupId;
      this.getHelthIndicators();
      if ( (this.schedule == null) || ( this.currentHealthIndicatorGroupId == null) )
        return;

      http.get(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/health-data`, {params:{'id_health_indicator_group':healthIndicatorGroupId}}).then((response) => {
        this.patientHealthData = response.data;
      });
    },
    getHelthIndicators() {
      this.healthIndicators = this.healthIndicatorGroups[this.tabActiveIndex]?.health_indicators ?? [];
    },
    deleteHealthData (patientHealthDataId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o registro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.medicalRecord.id_prof}/patients/${this.medicalRecord.id_patient}/health-data/${patientHealthDataId}`).then(() => {
            Alert.toastSuccess("Registro excluído com sucesso.");
            this.getPatientHealthData(this.currentHealthIndicatorGroupId);
          }).catch(e => {
            Alert.toastError(e.response.data.message??e.response.data);
          });
        }
      });
    },
  },
}
</script>

<style scoped>

</style>