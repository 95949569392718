import Home from "../views/Home";
import Schedules from "../views/calendar/Schedules";
import Patients from "../views/patient/Patient";
import ChangePassword from "../views/auth/ChangePassword";
import Profile from "../views/Profile";
import Insurances from "../views/insurance/Insurances";
import RecordTypes from "../views/record_type/RecordTypes";
import Specialty from "../views/specialty/Specialty";
import Invite from "../views/invite/Invite";
import PaymentMethod from "../views/payment_method/PaymentMethod";
import Place from "../views/place/Place";
import Places from "../views/place/Index";
import ProfessionalAttendance from "../views/attendance/Index";
import Attendance from "../views/attendance/Attendance";
import PrescriptionProtocol from "../views/prescription_protocol/PrescriptionProtocol";
import ExamProtocol from "../views/exam_protocol/ExamProtocol";
import MedicalRecord from "../views/patient/medical_record/MedicalRecord";
import CardAdministrator from "../views/card_administrator/CardAdministrator";
import Users from "../views/user/Index";
import Professionals from "../views/professional/Index";
import ProfDocuments from "../views/professional/document/ProfDocument";
import ReleaseTimes from "../views/calendar/release_times/ReleaseTimes.vue";
import VisitValues from "../views/visit_value/VisitValues";
import Exams from "../views/exams/Exams";
import AccessType from "../views/access_type/AccessType";
import Holidays from "../views/holiday/Index";
import EmailVerify from "../views/EmailVerify";

const routes = [
    {
        path: '/dashboard',
        name: 'Home',
        component: Home,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Painel',
            breadcrumb: [
                {name: 'Home'},
            ]
        }
    },
    {
        path: '/schedules',
        name: 'Schedules',
        component: Schedules,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Agendamentos',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Agenda'},
                {name: 'Gestão de agendamentos'},
            ]
        }
    },
   {
        path: '/release-times',
        name: 'ReleaseTimes',
        component: ReleaseTimes,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Liberar Horário',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Agenda'},
                {name: 'Liberar Horário'},
            ]
        }
    },
    {
        path: '/insurances',
        name: 'Insurances',
        component: Insurances,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Convênios',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Convênios'},
            ]
        }
    },
    {
        path: '/holidays',
        name: 'Holidays',
        component: Holidays,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Feriados',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Feriados'},
            ]
        }
    },
    {
        path: '/specialties',
        name: 'Specialty',
        component: Specialty,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Especialidades',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Especialidades'},
            ]
        }
    },
    {
        path: '/record-types',
        name: 'RecordTypes',
        component: RecordTypes,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Tipos de Prontuário',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Protocolos'},
                {name: 'Tipos de Prontuário'},
            ]
        }
    },
    {
        path: '/payment-methods',
        name: 'PaymentMethod',
        component: PaymentMethod,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Formas de Recebimento',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Formas de Recebimento'},
            ]
        }
    },
    {
        path: '/visit-values',
        name: 'VisitValues',
        component: VisitValues,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Tabela de Valores',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Tabela de Valores'},
            ]
        }
    },
    {
        path: '/exams',
        name: 'Exams',
        component: Exams,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Exames',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Exames'},
            ]
        }
    },
    {
        path: '/invites',
        name: 'Invites',
        component: Invite,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Convites',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Convites'},
            ]
        }
    },
    {
        path: '/place',
        name: 'Place',
        component: Place,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Locais',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Cadastros'},
                {name: 'Locais'},
            ]
        }
    },
    {
        path: '/places',
        name: 'Places',
        component: Places,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Locais',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Locais'},
            ]
        }
    },
    {
        path: '/prescription-protocols',
        name: 'PrescriptionProtocol',
        component: PrescriptionProtocol,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Protocolos de Prescrição',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Protocolos'},
                {name: 'Protocolos de Prescrição'},
            ]
        }
    },
    {
        path: '/exam-protocols',
        name: 'ExamProtocol',
        component: ExamProtocol,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Protocolos de Exame',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Protocolos'},
                {name: 'Protocolos de Exame'},
            ]
        }
    },
    {
        path: '/attendances',
        name: 'ProfessionalAttendance',
        component: ProfessionalAttendance,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Atendimento',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Atendimento'},
            ]
        }
    },
    {
        path: '/attendances/:scheduleId',
        name: 'Attendance',
        component: Attendance,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Atendimento',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Atendimento'},
            ]
        }
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Alterar Senha',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Alterar Senha'},
            ]
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Perfil',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Perfil'},
            ]
        }
    },
    {
        path: '/patients',
        name: 'Patients',
        component: Patients,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Pacientes',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Pacientes'},
            ]
        }
    },
    {
        path: '/patients/:patientId',
        name: 'MedicalRecord',
        component: MedicalRecord,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Prontuário',
            breadcrumb: [
                {name: 'Home', link: '/dashboard'},
                {name: 'Pacientes', link: '/patients'},
                {name: 'Prontuário'},
            ]
        }
    },
    {
        path: '/card-administrator',
        name: 'CardAdministrator',
        component: CardAdministrator,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Administradora de cartão',
            breadcrumb: [
                {name: 'Home', link: '/dashboard'},
                {name: 'Administradora de cartão', link: '/card-administrators'},
                {name: 'Administradora'},
            ]
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Usuário',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Usuários'},
            ]
        }
    },
    {
        path: '/professionals',
        name: 'Professionals',
        component: Professionals,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Profissionais',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Profissionais'},
            ]
        }
    },
    {
        path: '/documents',
        name: 'ProfDocuments',
        component: ProfDocuments,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Documentos do profissional',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Documentos do profissional'},
            ]
        }
    },
    {
        path: '/access-types',
        name: 'AccesType',
        component: AccessType,
        meta: {
            auth: true,
            layout: 'professional-layout',
            title: 'Regras de Acesso',
            breadcrumb: [
                {name: 'Home', link: 'dashboard'},
                {name: 'Regras de Acesso'},
            ]
        }
    },
    {
        path: '/email/verify',
        name: 'EmailVerify',
        component: EmailVerify,
        meta: {
            auth: false,
            layout: 'guest-layout',
            title: 'Verificar e-mail',
        }
    },
];

export default routes
