import { createStore } from 'vuex';
import auth from "./auth";
import professional from "./professional";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: auth,
    professional: professional,
  }
})
