<template>
  <div class="modal fade" tabindex="-1" id="modal_prof_place_insurance" ref="modal_prof_place_insurance">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
              Convênios por profissional<br>
              <span class="text-muted">{{ professional.prof_name }}</span>
          </h5>

          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="fv-row mb-5">
              <div class="row">
                  <div class="col-md-12">
                      <table class="table table-row-bordered gy-5">
                          <thead>
                          <tr class="fw-bold fs-6 text-muted">
                              <th>Código ANS</th>
                              <th>Nome</th>
                              <th>Ação</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="insurance in insurances">
                              <td>{{ insurance.code_ans }}</td>
                              <td>{{ insurance.insurance_name }}</td>
                              <td>
                                  <button class="btn btn-sm btn-danger me-1"
                                          v-if="insurance.enabled_to_prof_place"
                                          title="Excluir" @click="removeProfPaymentMethod(insurance.id_insurance)">
                                      <i class="bi bi-trash"></i> Remover
                                  </button>
                                  <button class="btn btn-sm btn-success me-1"
                                          v-else
                                          title="Adicionar" @click="saveProfPaymentMethod(insurance.id_insurance)">
                                      <i class="bi bi-save"></i> Adicionar
                                  </button>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";

export default {
  name: "ModalProfPlaceInsurance",
  props: ['professional'],
  data() {
    return {
        insurances: [],
    }
  },
  mounted() {
    const modalElement = document.getElementById('modal_prof_place_insurance');
    modalElement.addEventListener('shown.bs.modal', () => {
      this.loadInsurances();
    });
    modalElement.addEventListener('hidden.bs.modal', () => {
      this.insurances = [];
    });
  },
  computed: {
      place() {
          return this.$store.state.auth.place;
      },
  },
  methods: {
      loadInsurances() {
          return http.get(`/places/${this.place.id_place}/professionals/${this.professional.id_prof}/insurances`).then((response) => {
              this.insurances = response.data;
          });
      },
      saveProfPaymentMethod(id) {
          let url = `/places/${this.place.id_place}/professionals/${this.professional.id_prof}/insurances`;
          return http.post(url, {id_insurance: id}).then(() => {
              this.loadInsurances();

              Alert.toastSuccess('Convênio adicionado com sucesso.');
          });
      },
      removeProfPaymentMethod(id) {
          let url = `/places/${this.place.id_place}/professionals/${this.professional.id_prof}/insurances/${id}`;
          return http.delete(url).then(() => {
              this.loadInsurances();

              Alert.toastSuccess('Convênio removido com sucesso.');
          });
      },
  }
}
</script>

<style scoped>

</style>