import axios from "axios";
import db from "./db";
import store from "../store";
import Config from "../config";

let baseUrl = Config.base_url;
let api = axios.create({
    baseURL: baseUrl,
});

api.defaults.headers.common['ubbi-app'] = Config.app_name;
api.defaults.headers.common['ubbi-app-token'] = Config.app_token;
api.defaults.headers.common['ubbi-timezone'] = Config.timezone;

api.interceptors.request.use(function (config) {
    let token = store.state.auth.token;
    config.headers['Authorization'] =  token ? `Bearer ${token}` : '';
    config.headers['Current-Page-URL'] = window.location.href;

    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response?.status === 401 && error.response.config.url === '/auth/login') {
        return Promise.reject(error);
    }

    if (error.response?.status === 401) {
        db.storeUser(null);
        db.storeToken(null);
        db.storePlace(null);

        window.location.href = '/';
        return;
    }

    return Promise.reject(error);
});


export default api;
