<template>
  <div class="modal fade" tabindex="-1" id="modal_store_exam_prot">
    <div class="modal-dialog modal-xl">
      <form method="post" @submit.prevent="submitForm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isInserting?'Acicionar':'Editar' }} Protocolo</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-10">
                <label for="id_exam_prot_store" class="required form-label">Descrição do protocolo</label>
                <input class="form-control form-control-solid"
                       v-model="formStore.exam_prot_name" id="id_exam_prot_store" autofocus required>
                <small class="text-danger" v-if="formStoreErrors.exam_prot_name">{{ formStoreErrors.exam_prot_name[0] }}</small>
              </div>
              <div class="col-md-2 mt-12">
                <label for="presc_prot_active_store" class="required form-label">Ativo?</label>
                <input class="form-check-input" type="checkbox" v-model="formStore.active" id="presc_prot_active_store" checked>
                <small class="text-danger" v-if="formStoreErrors.active">{{ formStoreErrors.active[0] }}</small>
              </div>
            </div>
          </div>

          <div class="card ms-4">
            <hr>
            <h5 class="card-title mt-6">Exames</h5>
            <div class="card-body">
              <div class="col-md-12">
                <div class="col-md-12  mt-4">
                  <div class="row">
                    <div class="col-md-10">
                      <InputSearchExam v-model="searchExam" @onSetSearchExam="this.setExamSearched"></InputSearchExam>
                    </div>
                    <div class="col-md-2">
                      <button type="button" class="btn btn-primary"
                              v-if="hasPermission('Store Exam Protocol Item')"
                              @click="addStoreExam()">Incluir</button>
                    </div>

                  </div>
                </div>
                <div class="col-md-12 mb-6 mt-4">
                  <table class="table table-row-bordered table-hover table-light gy-3">
                    <thead>
                    <tr class="fw-bold fs-6 text-muted">
                      <th style="text-align: center">#</th>
                      <th style="text-align: center">TUSS</th>
                      <th>Exame</th>
                      <th style="text-align: center">Açoes</th>
                    </tr>
                    </thead>
                    <tr v-for="(exam, index) in formStore.exams" :key="exam.id_exam" >
                      <td style="text-align: center">{{ index + 1 }}</td>
                      <td style="text-align: center">{{ exam.exam_tuss_code}}</td>
                      <td>{{ exam.exam_name }}</td>
                      <td style="text-align: center">
                        <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Delete Exam Protocol Item')"
                                data-bs-toggle="tooltip" title="Excluir Exame" @click="delStoreExam(index);">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">{{ isInserting?'Cadastrar':'Alterar'}}</button>
          </div>
        </div>
      </form>
    </div>
  </div> <!-- modal_store_exam_prot -->
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import InputSearchExam from "../../components/InputSearchExam";
import Permission from "../../helpers/permission";

export default {
  name: "PrescriptionProtocolDrugs",
  components: {
    InputSearchExam,
  },
  props: {
    examProt: Object,
    currentProfessionalId: Number,
  },
  data() {
    return {
      formStore: {
        id_exam_prot: '',
        id_prof: '',
        exam_prot_name: '',
        active: true,
        exams: [],
        examSearched: '',
      },
      formStoreErrors: [],
      searchExam: '',
    }
  },
  computed: {
    isInserting() {
      return this.examProt?.id_exam_prot == null;
    }
  },
  watch: {
    examProt(newExamProt, oldExamProt) {
      if (newExamProt)
        this.formStore = newExamProt
      else
        this.clearFormStore();
     this.clearStoreExam();
    }
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    clearFormStore() {
      this.formStore = {
        id_exam_prot: '',
        id_prof: '',
        exam_prot_name: '',
        active: true,
        exams: [],
      }      
    },
    clearStoreExam() {
      this.examSearched = '';
      this.searchExam = '';
    },
    setExamSearched(exam) {
      this.examSearched = exam;
    },
    submitForm() {
      if (this.isInserting)
        this.submitFormStore();
      else
        this.submitFormEdit();
    },
    submitFormStore() {
      this.formStore.id_prof = this.currentProfessionalId;
      this.formStoreErrors = [];
      http.post(`/professionals/${this.currentProfessionalId}/exam-protocols`, this.formStore).then(_ => {
        Alert.toastSuccess("Protocolo cadastrado com sucesso.");
        this.clearFormStore();
        bootstrap.Modal.getInstance(document.getElementById('modal_store_exam_prot')).toggle();
        this.$emit('onClose');
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    submitFormEdit() {
      this.formEditErrors = [];
      http.put(`/professionals/${this.currentProfessionalId}/exam-protocols/${this.formStore.id_exam_prot}`, this.formStore).then(() => {
        Alert.toastSuccess("Protocolo atualizado com sucesso.");
        bootstrap.Modal.getInstance(document.getElementById('modal_store_exam_prot')).toggle();
        this.$emit('onClose');
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formEditErrors = e.response.data.errors;
        }
      });
    },
    addStoreExam() {
      if (this.examSearched) {
        this.formStore.exams.push(this.examSearched);
        this.clearStoreExam();
      }
    },
    delStoreExam (index) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o exame?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.formStore.exams.splice(index,1);
          this.clearStoreExam();
        }
      });
    },
  },
}
</script>

<style scoped>

</style>