<template>
    <Toolbar />
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-flush">

            <div class="card-body pt-5">
              <h5 class="card-title">Pacientes em espera.</h5>
              <p class="text-muted">Pacientes que estão aguardando atendimento hoje</p>

              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800">
                    <th>Nome</th>
                    <th>Agendamento</th>
                    <th>Chegada</th>
                    <th>Forma de pagamento</th>
                    <th>Especialidade</th>
                    <th>Ações</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center" v-if="schedulesFiltered.length <= 0">
                    <td colspan="12">
                      Nenhum paciente em espera.
                    </td>
                  </tr>
                  <tr v-else v-for="schedule in schedulesFiltered" class="odd">
                    <td class="d-flex align-items-center">
                      <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                        <div class="symbol-label">
                          <img class="w-50" :src="url(schedule?.patient?.patient_photo)" v-if="schedule?.patient?.patient_photo" alt="Foto do patiente">
                          <img class="w-50" :src="require('@/assets/images/no-photo.png')" alt="image" v-else/>
                        </div>
                      </div>
                      <div class="d-flex flex-column">
                        <span class="text-gray-800 text-hover-primary mb-1">
                          {{schedule?.patient?.patient_name}}
                        </span>
                        <span>{{schedule?.patient?.patient_email}}</span>
                        <div v-if="schedule?.sched_start" class="badge badge-light-success fw-bolder">
                          Em Atendimento
                        </div>
                      </div>
                    </td>
                    <td>{{schedule?.sched_time}}</td>
                    <td>{{date(schedule?.sched_arrive_timezone).format('HH:mm:00')}}</td>
                    <td>{{schedule?.payment_method?.paymeth_name}}</td>
                    <td>
                      {{schedule?.availability?.specialty.spec_name}}
                      <div v-if="schedule?.sched_online" class="badge badge-light-success fw-bolder">
                        Online
                      </div>
                    </td>
                    <td>
                      <button type="button" @click="startAttendance(schedule)" class="btn btn-sm btn-light" title="Iniciar atendimento">
                        <span class="svg-icon svg-icon-muted">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path opacity="0.3" d="M12.9 10.7L3 5V19L12.9 13.3C13.9 12.7 13.9 11.3 12.9 10.7Z" fill="black"/>
                          <path d="M21 10.7L11.1 5V19L21 13.3C22 12.7 22 11.3 21 10.7Z" fill="black"/>
                          </svg>
                        </span>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import str from "../../helpers/str";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Attendance",
    components: {
        Toolbar,
    },
  data() {
    return {
      search: '',
      infos: {
        countToday: null,
        countAwaitAttendance: null,
        countFinished: null,
        countAwaitConfirm: null,
        countMissed: null,
      },
      schedules: [],
    }
  },
  beforeUnmount() {
      Echo.leave(`professionals.${this.userAuth.id_prof}.queues`);
  },
  created() {
    this.getInfos();
    this.getSchedules();
    this.wsConnect();
    this.initialize();
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
    schedulesFiltered() {
      return this.schedules.filter(schedule => {
        return JSON.stringify(schedule).toLowerCase().includes(this.search.toLowerCase())
      });
    },
  },
  methods: {
    date: moment,
      url(path) {
          return str.url(path)
      },
    initialize() {
      http.post(`/places/${this.place.id_place}/professionals/${this.userAuth.id_prof}/attendance/initialize`);
    },
    wsConnect() {
        Echo.private(`professionals.${this.userAuth.id_prof}.queues`)
            .listen('ScheduleArrived', this.refreshData)
            .listen('ScheduleNotArrived', this.refreshData)
            .listen('ScheduleStarted', this.refreshData)
            .listen('ScheduleFinished', this.refreshData);
    },
      refreshData() {
          this.getInfos();
          this.getSchedules();
      },
    getInfos() {
      http.get(`/places/${this.place.id_place}/professionals/${this.userAuth.id_prof}/attendance/infos`).then((response) => {
        this.infos = response.data;
      });
    },
    getSchedules() {
      http.get(`/places/${this.place.id_place}/professionals/${this.userAuth.id_prof}/attendance/schedules`).then((response) => {
        this.schedules = response.data;
      });
    },
    startAttendance(schedule) {
      if (schedule.sched_start) {
        http.patch(`/places/${this.place.id_place}/professionals/${this.userAuth.id_prof}/attendance/schedules/${schedule.id_sched}/start`).then(() => {
          this.$router.push({ path: `/attendances/${schedule.id_sched}` });
        });
        return;
      }

      Swal.fire({
        title: 'Tem certeza que deseja iniciar esse atendimento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Iniciar atendimento.',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.patch(`/places/${this.place.id_place}/professionals/${this.userAuth.id_prof}/attendance/schedules/${schedule.id_sched}/start`).then(() => {
            this.$router.push({ path: `/attendances/${schedule.id_sched}` });
          });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>