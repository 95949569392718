<template>
    <button
        v-if="!scheduleEdit.sched_end && date(scheduleEdit?.schedule_date_time).isSame(date(), 'day') && scheduleEdit?.sched_arrive"
        @click.stop="startAttendance(scheduleEdit)"
        type="button" :class="classes"
        class="btn btn-primary ms-2"
        :title="!scheduleEdit.sched_start ? 'Iniciar atendimento' : 'Continuar atendimento'">
        {{btnText ?? ''}}
    </button>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";

export default {
    name: "BtnStartAttendanceSchedule",
    props: ['scheduleEdit', 'classes', 'btnText'],
    computed: {
        place() {
            return this.$store.state.auth.place;
        },
        userAuth() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        date: moment,
        startAttendance(schedule) {
            if ((this.userAuth?.id_prof !== schedule?.id_prof)) {
                this.sendStartAttendance(schedule).then(() => {
                    Alert.toastSuccess('Atendimento iniciado com sucesso!')
                });
            } else {
                if (schedule.sched_start) {
                    this.$router.push({path: `/attendances/${schedule.id_sched}`});
                    return;
                }

                Swal.fire({
                    title: 'Tem certeza que deseja iniciar esse atendimento?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim, Iniciar atendimento.',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: 'btn btn-secondary'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.sendStartAttendance(schedule).then(() => {
                            this.$router.push({path: `/attendances/${schedule.id_sched}`});
                        });
                    }
                });
            }
        },
        sendStartAttendance(schedule) {
            return http.patch(`/places/${this.place.id_place}/professionals/${schedule.id_prof}/attendance/schedules/${schedule.id_sched}/start`).then(() => {
                this.$emit('onStartAttendance', schedule.id_sched);
            }).catch((e) => {
                Alert.toastError(e.response.data);
            });
        },
    }
}
</script>

<style scoped>

</style>