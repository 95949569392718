<template>
    <Dropdown :options="statusList"
              :filter="true"
              v-model="value"
              optionLabel="name"
              optionValue="code"
              showClear
              id="status_code"
              class="form-control form-control-solid form-control-sm"
              placeholder="Status"
              style="display: flex;">
        <template #option="slotProps">
            <div class="flex align-items-center">
                <div class="text-white font-weight-bold px-5 rounded"
                     :style="{backgroundColor: slotProps.option.color}">
                    {{ slotProps.option.name }}
                </div>
            </div>
        </template>
    </Dropdown>
</template>

<script>
import http from "../../helpers/http";
import Dropdown from "primevue/dropdown";

export default {
    name: "CalendarInputStatus",
    props: ['modelValue'],
    emits: ['change', 'update:modelValue'],
    components: {
        Dropdown,
    },
    data() {
        return {
            statusList: [],
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('change', value);
                this.$emit('update:modelValue', value);
            }
        }
    },
    mounted() {
        http.get(`/status`).then((response) => {
            this.statusList = response.data;
        });
    },
}
</script>

<style>
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0.75rem !important;
}
.p-dropdown-trigger {
    margin-right: -14px;
}
</style>