<template>
    <Dropdown :options="specialties"
              :filter="true"
              showClear
              v-model="value"
              optionLabel="spec_name"
              optionValue="id_spec"
              empty-message="Nenhuma opção"
              empty-filter-message="Nenhuma opção encontrada"
              id="id_spec"
              class="form-control form-control-solid w-full md:w-14rem form-control-sm"
              placeholder="Especialidades"
              style="display: flex;"
    />
</template>

<script>
import http from "../../helpers/http";
import Dropdown from "primevue/dropdown";

export default {
    name: "CalendarInputSpecialties",
    props: ['modelValue', 'idProf'],
    emits: ['change', 'update:modelValue'],
    components: {
        Dropdown,
    },
    data() {
        return {
            specialties: [],
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('change', value);
                this.$emit('update:modelValue', value);
            }
        },
        place() {
            return this.$store.state.auth.place;
        },
    },
    mounted() {
        this.loadSpecialties();
    },
    watch: {
        idProf() {
            this.loadSpecialties();
        },
    },
    methods: {
        loadSpecialties() {
            if (this.idProf) {
                http.get(`/places/${this.place.id_place}/professionals/${this.idProf}/specialties`).then((response) => {
                    this.specialties = response.data;
                });
            } else {
                http.get(`/places/${this.place.id_place}/specialties`).then((response) => {
                    this.specialties = response.data;
                });
            }
        }
    }
}
</script>

<style>
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0.75rem !important;
}

.p-dropdown-trigger {
    margin-right: -14px;
}
</style>