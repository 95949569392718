<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <div class="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bg-ubbi" style="background-color: #780f5a;">
        <div class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
          <div class="d-flex flex-row-fluid flex-column text-center p-10" style="padding-top: 0!important">
            <a v-on:click.prevent="" href="#" class="py-9 mb-5" style="padding-top: 0!important">
              <img alt="Logo" :src="require('@/assets/images/logo_ubbi_white.png')" class="h-200px">
            </a>
            <h1 class="fw-bolder fs-2qx pb-5 pb-md-10 text-light">
              O software médico <br>feito para você
            </h1>
            <p class="fw-bold fs-2 text-light">
              Todas as ferramentas que o seu consultório precisa para crescer, em um único lugar.
            </p>
          </div>
          <div class="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
               :style="{backgroundImage:`url(`+require('@/assets/images/1.svg')+`)`}"></div>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row-fluid py-10">
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <div class="card">
            <div class="card-body">
              <div class="w-lg-500px p-10 p-lg-5 mx-auto">
                <div class="alert alert-danger" role="alert" v-if="messageError">
                  {{ messageError }}
                </div>
                <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                      method="post" @submit.prevent="login"
                      novalidate="novalidate" id="kt_sign_in_form">
                  <div class="text-center mb-10">
                    <h1 class="text-dark mb-3">Acesse sua conta</h1>
                    <div class="text-gray-400 fw-bold fs-4">Não é cadastrado?
                      <router-link to="/register" class="link-primary fw-bolder">
                        Crie uma conta grátis
                      </router-link>
                    </div>
                  </div>
                  <div class="fv-row mb-10 fv-plugins-icon-container">
                    <label class="form-label fs-6 fw-bolder text-dark">CPF ou E-mail</label>
                    <input class="form-control form-control-lg form-control-solid" required v-model="auth.username"
                           :class="errors.user_email ? 'is-invalid' : ''"
                           type="text" name="email" autocomplete="off" placeholder="Seu CPF ou e-mail">

                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.user_email">
                      {{ errors.user_email.join('\n') }}
                    </div>
                  </div>

                  <div class="fv-row mb-10 fv-plugins-icon-container">
                    <div class="d-flex flex-stack mb-2">
                      <label class="form-label fw-bolder text-dark fs-6 mb-0">
                        Senha <span class="text-danger">*</span>
                      </label>
                    </div>
                    <input class="form-control form-control-lg form-control-solid" v-model="auth.password"
                           :class="errors.user_password ? 'is-invalid' : ''" required
                           type="password" name="password" autocomplete="off" placeholder="Digite sua senha">

                    <div class="fv-plugins-message-container invalid-feedback" v-if="errors.user_password">
                      {{ errors.user_password.join('\n') }}
                    </div>

                    <button type="button" class="link-primary fs-6 fw-bolder btn btn-link"
                            data-bs-toggle="modal" data-bs-target="#ModalRecoverPassword">
                      Esqueceu a senha?
                    </button>
                  </div>
                  <div class="text-center">
                    <button type="submit" id="kt_sign_in_submit" :disabled="submitted"
                            class="btn btn-lg btn-primary w-100 mb-5">
                      <span  v-show="!submitted">Login</span>
                      <span v-show="submitted">Por favor aguarde...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                    <div class="text-center text-muted text-uppercase fw-bolder mb-5">OU</div>

                    <a href="#" class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
                      <BtnLoginGoogle v-on:onSuccess="onLoginGoogle" />
                    </a>
                    <a href="#" class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">
                      <BtnLoginFacebook v-on:onSuccess="onLoginFacebook" />
                    </a>
                  </div>
                  <div></div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <div class="d-flex flex-center fw-bold fs-6">
            <a href="https://ubbimed.com.br" class="text-muted text-hover-primary px-2">Site</a>
            <a href="https://ubbimed.com.br/#us" class="text-muted text-hover-primary px-2">Sobre</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <RecoverPasswordModal/>

</template>

<script>
import BtnLoginGoogle from '../../components/BtnLoginGoogle';
import BtnLoginFacebook from '../../components/BtnLoginFacebook';
import http from "../../helpers/http";
import RecoverPasswordModal from "./RecoverPasswordModal";

export default {
  name: "Login",
  components: {
    BtnLoginGoogle,
    BtnLoginFacebook,
    RecoverPasswordModal,
  },
  data() {
    return {
      auth: {
        username: '',
        password: '',
      },
      submitted: false,
      errors: [],
      messageError: '',
      messageRecoverPassword: '',
      messageRecoverPasswordError: '',
    }
  },
  created() {
    if (this.$store.getters['auth/isAuth'])
      this.$router.push('/dashboard');
  },
  mounted() {

  },
  methods: {
    onLoginFacebook(responseFacebook) {
      this.loginSocial('facebook', responseFacebook.accessToken);
    },
    onLoginGoogle(googleUser) {
        let accessToken = googleUser.credential;
        this.loginSocial('google', accessToken);
    },
    loginSocial(driver, accessToken) {
      this.submitted = true;
      this.errors = {};
      this.messageError = null;
      http.post(`/auth/social/${driver}`, {access_token: accessToken}).then((response) => {
        this.$store.commit('auth/setUser', response.data.user);
        this.$store.commit('auth/setToken', response.data.token);
        this.$router.push('/dashboard');
      }).catch((error) => {
        let response = error.response;
        if (response?.status === 422) {
          this.errors = response.data.errors;
        } else if (response?.status === 401) {
          this.messageError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
    login() {
      this.submitted = true;
      this.errors = {};
      this.messageError = null;
      this.$store.dispatch('auth/login', this.auth).then(() => {
        this.$router.push('/dashboard');
      }).catch((error) => {
        let response = error.response;
        if (response?.status === 422) {
          this.errors = response.data.errors;
        } else if (response?.status === 401) {
          this.messageError = response.data;
        }
      }).finally(() => {
        this.submitted = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
