import http from "../helpers/http";
import db from "../helpers/db";

export default {
    namespaced: true,
    state: {
        user: db.user,
        token: db.token,
        place: db.place,
        professionals: db.professionals,
        certifier: db.certifier,
        permissions: db.permissions,
    },
    mutations: {
        setUser(state, user) {
            state.user = db.storeUser(user);
        },
        setToken(state, token) {
            state.token = db.storeToken(token);
        },
        setPlace(state, place) {
            state.place = db.storePlace(place);
        },
        setProfessionals(state, professionals) {
            state.professionals = db.storeProfessionals(professionals);
        },
        setCertifier(state, certifier) {
            state.certifier = db.storeCertifier(certifier);
        },
        setPermissions(state, permissions) {
            state.permissions = db.storePermissions(permissions);
        },
    },
    actions: {
        loginUsingToken(context, token) {
            if (!context.state.token)
                context.commit('setToken', token);

            return http.get('/users/me').then(response => {
                context.commit('setUser', response.data);
                return response;
            });
        },
        login(context, auth) {
            return http.post('/auth/login', {
                user_email: auth.username,
                user_password: auth.password
            }).then(async response => {
                let user = response.data.user;
                let token = response.data.token;

                context.commit('setUser', user);
                context.commit('setToken', token);
                return context.dispatch('loadPlaces', user);
            });
        },
        loadPlaces(context, user) {
            return http.get(`/users/${user.id_user}/places`).then((res) => {
                if (res.data.length !== 1) {
                    window.location.href = '/places';
                } else {
                    context.commit('setPlace', res.data[0]);
                    let responseProf = context.dispatch('loadProfessionals');
                    let responsePermissions = context.dispatch('loadPermissions');

                    return Promise.all([responseProf, responsePermissions]);
                }

                return res;
            });
        },
        loadProfessionals(context) {
            if (!context.state.place)
                return;

            return http.get(`/places/${context.state.place.id_place}/professionals`).then((response) => {
                context.commit('setProfessionals', response.data);
                return response;
            });
        },
        loadCertifiers(context) {
            if (!context.state.certifiers)
                return;

            return http.get(`/certifiers`).then((response) => {
                context.commit('setCertifiers', response.data);
                return response;
            });
        },
        loadPermissions(context) {
            if (!context.state.place)
                return;

            return http.get(`/places/${context.state.place.id_place}/users/${context.state.user.id_user}/permissions`).then((response) => {
                context.commit('setPermissions', response.data);
                return response;
            });
        },
        recoverPassword(context, username) {
            return http.post('/auth/password/link', {cpf: username});
        },
        recoverPasswordCode(context, username) {
            return http.post('/auth/password/code', {cpf: username});
        },
        validateCode(context, data) {
            return http.post(`/auth/password/validate-code`, {user_cpf: data.user_cpf, code: data.code});
        },
        logout(context) {
            return http.post('/auth/logout').then(() => {
                context.commit('setUser', null);
                context.commit('setToken', null);
                context.commit('setPlace', null);
                context.commit('setProfessionals', null);
                context.commit('setCertifier', null);
                context.commit('setPermissions', null);
            });
        },
    },
    getters: {
        isAuth(state) {
            return state.user != null &&
                state.token != null;
        }
    },
}