<template>
  <div class="modal fade" id="ModalRecoverPassword" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="static"
       aria-labelledby="ModalRecoverPassword" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded shadow border-0">
        <div class="modal-header border-bottom">
          <h5 class="modal-title">Recuperar senha</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div class="alert alert-success" role="alert" v-if="messageRecoverPassword">
              {{ messageRecoverPassword }}
            </div>
            <div class="alert alert-danger" role="alert" v-if="messageRecoverPasswordError">
              {{ messageRecoverPasswordError }}
            </div>

            <div v-if="!waitingCode">
              <div class="mb-3">
                <label class="form-label" for="email_recover">Seu CPF<span class="text-danger">*</span></label>
                <div class="form-icon position-relative">
                  <input name="cpf" id="cpf_recover" type="tel" v-model="auth.username"
                         :class="errors.username ? 'is-invalid' : ''" v-mask="'###.###.###-##'"
                         class="form-control ps-5" placeholder="Ex.  000.000.000-00" ref="cpf_recover">
                </div>
              </div>
              <div class="d-grid gap-2 mt-10">
                <h6>Informe o método de recuperação</h6>
                <button class="btn btn-primary" @click="resetPasswordByCode()">
                  WhatsApp
                </button>
                <button class="btn btn-primary" @click="recoverPasswordByLink()">
                  Email
                </button>
              </div>
            </div>
            <div v-else>
              <RecoveryCodeInput
                  @code-valid="handleCodeValid"
                  :obfuscated_phone="auth.obfuscated_phone"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import RecoveryCodeInput from '../../components/RecoveryCodeInput.vue';

export default {
  name: "RecoverPasswordModal",
  directives: {
    mask,
  },
  components: {
    RecoveryCodeInput,
  },
  data() {
    return {
      auth: {
        username: '',
        password: '',
        obfuscated_phone: '',
      },
      errors: [],
      messageError: '',
      messageRecoverPassword: '',
      messageRecoverPasswordError: '',
      waitingCode: false,
    }
  },
  mounted() {
    const modalElement = document.getElementById('ModalRecoverPassword');
    modalElement.addEventListener('shown.bs.modal', this.setFocus);
    modalElement.addEventListener('hidden.bs.modal', this.resetData);
  },
  beforeDestroy() {
    const modalElement = document.getElementById('ModalRecoverPassword');
    modalElement.removeEventListener('shown.bs.modal', this.setFocus);
    modalElement.removeEventListener('hidden.bs.modal', this.resetData);
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        if (this.$refs.cpf_recover) {
          this.$refs.cpf_recover.focus();
        }
      });
    },
    resetPasswordByCode() {
      if (!this.auth.username) {
        this.messageRecoverPasswordError = 'Informe o CPF';
        return;
      }

      this.sendRecoverPasswordCode().then((success) => {
        if (success) {
          this.messageRecoverPassword = '';
          this.messageRecoverPasswordError = '';
          this.waitingCode = true;
        }
      });
    },
    async sendRecoverPasswordCode() {
      try {
        const response = await this.$store.dispatch('auth/recoverPasswordCode', this.auth.username);
        this.auth.obfuscated_phone = response?.data?.obfuscated_phone;
        return true;
      } catch (e) {
        if (e.response.data.errors)
          this.messageRecoverPasswordError = e.response.data.errors.cpf.join('\n');
        else
          this.messageRecoverPasswordError = e.response.data;
        return false;
      }
    },
    handleCodeValid(code) {
      this.messageRecoverPasswordError = '';
      this.messageRecoverPassword = '';
      this.$store.dispatch('auth/validateCode', {code, user_cpf: this.auth.username}).then((response) => {
        this.closeModal();
        this.$router.push({path: '/reset-password', query: {token: response.data.token, user_cpf: this.auth.username}});
      }).catch((e) => {
        if (e.response.data.errors)
          this.messageRecoverPasswordError = e.response.data.errors.user_cpf.join('\n');
        else
          this.messageRecoverPasswordError = e.response.data;
      });
    },
    recoverPasswordByLink() {
      if (!this.auth.username) {
        this.messageRecoverPasswordError = 'Informe o CPF';
        return;
      }

      this.messageRecoverPasswordError = '';
      this.messageRecoverPassword = '';
      this.$store.dispatch('auth/recoverPassword', this.auth.username).then((response) => {
        this.messageRecoverPassword = response?.data;
      }).catch((e) => {
        if (e.response.data.errors)
          this.messageRecoverPasswordError = e.response.data.errors.user_email.join('\n');
        else
          this.messageRecoverPasswordError = e.response.data;
      });
    },
    resetData() {
      this.auth = {
        username: '',
        password: '',
        obfuscated_phone: '',
      };
      this.errors = [];
      this.messageError = '';
      this.messageRecoverPassword = '';
      this.messageRecoverPasswordError = '';
      this.waitingCode = false;
    },
    closeModal() {
      const modalElement = document.getElementById('ModalRecoverPassword');
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();
    }
  },
}
</script>

<style scoped>
.custom-title {
  font-size: 16px; /* Adjust the font size as needed */
}
</style>