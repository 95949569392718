import Login from "../views/auth/Login";
import Register from "../views/auth/Register";
import ResetPassword from "../views/auth/ResetPassword";
import InviteUserAction from "../views/user/InviteUserAction";
import InviteProfessionalAction from "../views/professional/InviteProfessionalAction";

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Login,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            auth: false,
            layout: 'guest-layout',
            title: 'Alterar Senha',
        }
    },
    {
        path: '/places/:placeId/user-invite/:inviteId/:action',
        name: 'InviteUserAction',
        component: InviteUserAction,
        meta: {
            auth: false,
            layout: 'guest-layout',
            title: 'Convite de Usuário',
        }
    },
    {
        path: '/places/:placeId/professional-invite/:inviteId/:action',
        name: 'InviteProfessionalAction',
        component: InviteProfessionalAction,
        meta: {
            auth: false,
            layout: 'guest-layout',
            title: 'Convite de Profissional',
        }
    },
];

export default routes
