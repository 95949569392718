<template>

  <div v-if="!hasPermission('List User')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"></path>
                </svg>
              </span>
                <input type="text" data-kt-user-table-filter="search" v-model="search"
                       class="form-control form-control-solid w-250px ps-14"
                       placeholder="Pesquisar ...">
              </div>
            </div>
            <div class="card-toolbar">
              <div class="col-md-6">
                <button class="btn btn-flex btn-success m-1"
                        v-if="hasPermission('Store User')"
                        @click="onClickBtnInvite">
                  <i class="bi bi-plus-lg"></i> Convidar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body py-4">

            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#"
                   data-bs-toggle="tab" data-bs-target="#nav-users">Usuários</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" id="tab-nav-invites"
                   data-bs-toggle="tab" data-bs-target="#nav-invites">Convites</a>
              </li>
            </ul>

            <hr>

            <div class="tab-content">
              <div class="tab-pane fade show active" id="nav-users" role="tabpanel" aria-labelledby="nav-users-tab">
                <div class="table-responsive">
                  <table class="table table-row-bordered gy-5">
                    <thead>
                    <tr class="fw-bold fs-6 text-muted">
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Regra de acesso</th>
                      <th>Data nascimento</th>
                      <th>Ativo</th>
                      <th>Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in usersFiltered">
                      <td>{{ user.user_name }}</td>
                      <td>{{ user.user_email }}</td>
                      <td>{{ user.access_type_name }}</td>
                      <td>{{ user.user_birthdate ? date(user.user_birthdate).format('DD/MM/YYYY') : '-' }}</td>
                      <td>
                        <span class="badge badge-success" v-if="user.user_active">Sim</span>
                        <span class="badge badge-danger" v-else>Não</span>
                      </td>
                      <td>
                        <div class="btn-group">
                          <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                  v-if="hasPermission('Update User')"
                                  @click="onClickBtnEdit(user)" data-bs-toggle="tooltip" title="Editar usuário">
                            <i class="bi bi-pen"></i>
                          </button>
                          <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                  v-if="userAuth.id_user !== user.id_user && hasPermission('Delete User')"
                                  @click="remove(user.id_user)" data-bs-toggle="tooltip" title="Excluir usuário">
                            <i class="bi bi-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="tab-pane fade" id="nav-invites" role="tabpanel" aria-labelledby="nav-invites-tab">
                <InviteUser :searchParam="search" @onSubmit="loadUsers"/>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" tabindex="-1" id="modal_edit">
          <div class="modal-dialog modal-lg">
            <form method="post" @submit.prevent="submitFormEdit">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Editar usuário</h5>
                  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                       aria-label="Fechar">
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="row">

                    <div class="col-md-12 mb-6 mt-4">
                      <label for="user_name" class="required form-label required">Nome</label>
                      <input class="form-control form-control-solid"
                             v-model="formEdit.user_name" id="user_name" required>
                      <small class="text-danger" v-if="formEditErrors.user_name">
                        {{ formEditErrors.user_name.join('\n') }}
                      </small>
                    </div>

                    <div class="col-md-12 mb-6 mt-4">
                      <label for="user_email" class="required form-label required">E-mail</label>
                      <input class="form-control form-control-solid"
                             v-model="formEdit.user_email" id="user_email" required disabled>
                      <small class="text-danger" v-if="formEditErrors.user_email">
                        {{ formEditErrors.user_email.join('\n') }}
                      </small>
                    </div>

                    <div class="col-md-12 mb-6 mt-4">
                      <label for="user_cpf" class="required form-label required">
                        CPF
                      </label>
                      <input class="form-control form-control-solid"
                             v-mask="'###.###.###-##'" placeholder="999.999.999-99"
                             v-model="formEdit.user_cpf" id="user_cpf" required>
                      <small class="text-danger" v-if="formEditErrors.user_cpf">
                        {{ formEditErrors.user_cpf.join('\n') }}
                      </small>
                    </div>

                    <div class="col-md-12 mb-6 mt-4">
                      <label for="user_birthdate" class="required form-label required">
                        Data Nascimento
                      </label>
                      <input class="form-control form-control-solid"
                             v-mask="'##/##/####'" placeholder="DD/MM/YYYY"
                             v-model="formEdit.user_birthdate" id="user_birthdate" required>
                      <small class="text-danger" v-if="formEditErrors.user_birthdate">
                        {{ formEditErrors.user_birthdate.join('\n') }}
                      </small>
                    </div>

                    <div class="col-md-12 mb-6 mt-4">
                      <label for="user_phone" class="required form-label required">
                        Telefone
                      </label>
                      <input class="form-control form-control-solid"
                             v-mask="['(##) ####-####', '(##) #####-####']"
                             placeholder="(99) 9 9999-9999"
                             v-model="formEdit.user_phone" id="user_phone" required>
                      <small class="text-danger" v-if="formEditErrors.user_phone">
                        {{ formEditErrors.user_phone.join('\n') }}
                      </small>
                    </div>

                    <div class="col-md-12 mb-6 mt-4">
                      <label class="form-label  text-dark fs-6">
                        Regra de acesso<span class="text-danger">*</span>
                      </label>
                      <Dropdown :options="accessTypes"
                                :filter="true"
                                v-model="formEdit.id_access_type"
                                optionLabel="access_type_name"
                                optionValue="id_access_type"
                                empty-message="Nenhuma opção"
                                empty-filter-message="Nenhuma opção encontrada"
                                id="id_access_type"
                                class="form-control form-control-solid w-full md:w-14rem"
                                placeholder="Regra de Acesso"
                                style="display: flex;"
                                :disabled="formEdit.id_user === userAuth.id_user"
                      />
                      <small class="text-danger" v-if="formEditErrors.id_access_type">
                        {{ formEditErrors.id_access_type.join('\n') }}
                      </small>
                    </div>
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                  <button type="submit" class="btn btn-primary">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import InviteUser from "./InviteUser";
import {mask} from "vue-the-mask";
import Permission from "../../helpers/permission";
import Dropdown from "primevue/dropdown";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Users",
  directives: {
    mask,
  },
  components: {
    InviteUser,
    Dropdown,
      Toolbar,
  },
  data() {
    return {
      search: '',
      users: [],
      accessTypes: [],
      formEdit: {
        id_user: '',
        user_name: '',
        user_email: '',
        user_phone: '',
        user_birthdate: '',
        id_access_type: '',
      },
      formEditErrors: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
    usersFiltered() {
      return this.users.filter(user => {
        return user.user_name.toLowerCase().includes(this.search.toLowerCase())
      });
    }
  },
  mounted() {
    this.loadUsers();
    this.loadAccessType();
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    onClickBtnInvite() {
      document.getElementById('tab-nav-invites').click();
      setTimeout(() => {
        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_store')).toggle();
      }, 100);
    },
    onClickBtnEdit(user) {
      this.formEdit = {
        id_user: user.id_user,
        user_name: user.user_name,
        user_email: user.user_email,
        user_phone: user.user_phone,
        user_birthdate: this.date(user.user_birthdate).format('DD/MM/YYYY'),
        user_cpf: user.user_cpf,
        id_access_type: user.id_access_type,
      };

      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_edit')).toggle();
    },
    loadUsers() {
      http.get(`/places/${this.place.id_place}/users`).then((response) => {
        this.users = response.data;
        console.log(this.users);
      });
    },
    loadAccessType() {
      http.get(`/places/${this.place.id_place}/access-types`).then((response) => {
        this.accessTypes = response.data;
      });
    },
    submitFormEdit() {
      http.put(`/places/${this.place.id_place}/users/${this.formEdit.id_user}`, this.formEdit).then(_ => {
        this.loadUsers();

        Alert.toastSuccess("Usuário atualizado com sucesso.");
        this.formEditErrors = [];
        this.formEdit = {
          id_user: '',
          user_name: '',
          user_email: '',
          user_phone: '',
          user_birthdate: '',
          user_cpf: '',
        };

        bootstrap.Modal.getInstance(document.getElementById('modal_edit')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formEditErrors = e.response.data.errors;
        }
      });
    },
    remove(id) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secondary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/users/${id}`).then(_ => {
            this.loadUsers();

            Alert.toastSuccess("Usuário removido com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
  }
}
</script>

<style scoped>

</style>