<template>
  <div class="modal-body scroll-y mx-5 my-7">
    <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_role_scroll"
         data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}"
         data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_role_header"
         data-kt-scroll-wrappers="#kt_modal_update_role_scroll" data-kt-scroll-offset="300px"
         style="max-height: 262px;">
      <div class="fv-row mb-10 fv-plugins-icon-container">
        <label class="fs-5 fw-bold form-label mb-2">
          <span class="required">Regra de acesso</span>
        </label>

        <div class="row" v-if="accessType">
          <div class="col-md-10">
            <input class="form-control form-control-solid" placeholder="Nome da regra de acesso" name="role_name"
                   v-model="accessType.access_type_name" :disabled="accessType.access_type_level !== 'CU'">
            <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
            </div>
          </div>
          <div class="col-md-2 mt-4">
            <label class="form-check form-switch">
              <input class="form-check-input" type="checkbox" v-model="accessType.access_type_active" :disabled="accessType.access_type_level !== 'CU'">
              <span class="form-check-label fw-bold text-gray-700">Ativo</span>
            </label>
          </div>
        </div>
      </div>
      <div class="fv-row">
        <label class="fs-5 fw-bold form-label mb-2">Permissões</label>
        <div class="table-responsive">
          <table class="table align-middle table-row-dashed fs-6 gy-5">
            <tbody class="text-gray-600 fw-semibold">
            <tr v-for="groupPermission in groupPermissions">
              <td class="text-gray-800">{{ groupPermission.group_permission_name }}</td>
              <td>
                <div class="d-flex">
                  <label
                      class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20"
                      v-for="permission in groupPermission.permissions">
                    <input class="form-check-input" type="checkbox" v-model="permission.allowed"
                           name="user_management_read">
                    <span class="form-check-label">{{ permission.display_name }}</span>
                  </label>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center pt-15">
      <button class="btn btn-light me-3" @click="this.$emit('onCancel');">
        Cancelar
      </button>
      <button class="btn btn-primary" @click="save">
                <span class="indicator-label">
                  Salvar
                </span>
        <span class="indicator-progress">
                  Aguarde... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "../attendance/shared/Input";
import Alert from "../../helpers/alert";
export default {
  name: "ModalBodyAccessType",
  components: {Input},
  props: {
    groupPermissions: null,
    accessType: null,
  },
  emits: ["onSave", 'onCancel'],
  methods: {
    save() {
      if (this.accessType.access_type_name === '') {
        Alert.toastError('Informe o nome da regra de acesso');
      } else {
        this.$emit('onSave');
      }
    }
  }
}
</script>

<style scoped>

</style>