<template>
    <Toolbar />
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title fw-bolder">Alterar senha</h2>
            </div>
            <div class="card-body">
              <div class="col-md-7 offset-md-2">
                <form class="form" method="post" @submit.prevent="changePassword">
                  <div class="fv-row mb-10 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                    <label class="required form-label fs-6 mb-2">Senha atual</label>
                    <input class="form-control form-control-lg form-control-solid" type="password" placeholder="Senha atual"
                           name="current_password" autocomplete="off" required
                           v-model="formChangePassword.current_user_password">
                    <div class="fv-plugins-message-container invalid-feedback"></div>
                  </div>
                  <div class="mb-10 fv-row fv-plugins-icon-container" data-kt-password-meter="true">
                    <div class="mb-1">
                      <label class="form-label fw-bold fs-6 mb-2">Nova senha</label>
                      <div class="position-relative mb-3">
                        <input class="form-control form-control-lg form-control-solid" type="password"
                               placeholder="Nova senha" required
                               name="new_password" autocomplete="off" aria-autocomplete="list" v-model="formChangePassword.user_password">
                        <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                              data-kt-password-meter-control="visibility">
                          <i class="bi bi-eye-slash fs-2"></i>
                          <i class="bi bi-eye fs-2 d-none"></i>
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-3 d-none" data-kt-password-meter-control="highlight">
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                      </div>
                    </div>
                    <div class="fv-plugins-message-container invalid-feedback"></div>
                  </div>
                  <div class="fv-row mb-10 fv-plugins-icon-container">
                    <label class="form-label fw-bold fs-6 mb-2">Confirme a nova senha</label>
                    <input class="form-control form-control-lg form-control-solid" type="password" required
                           placeholder="Confirme a nova senha"
                           name="confirm_password" autocomplete="off" v-model="formChangePassword.user_password_confirmation">
                    <div class="fv-plugins-message-container invalid-feedback"></div>
                  </div>
                  <div class="text-center pt-15">
                    <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                      <span class="indicator-label">Alterar senha</span>
                      <span class="indicator-progress" v-show="submitted">
                        Por favor aguarde...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                  <div></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "ChangePassword",
    components: {
        Toolbar,
    },
  data() {
    return {
      errors: [],
      submitted: false,
      formChangePassword: {
        current_user_password: '',
        user_password: '',
        user_password_confirmation: '',
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    changePassword() {
      this.submitted = true;
      http.patch(`/users/${this.user.id_user}/change-password`, this.formChangePassword).then((_) => {
        this.submitted = false;
        Alert.toastSuccess("Senha alterada com sucesso.");

        this.formChangePassword = {
          current_user_password: '',
          user_password: '',
          user_password_confirmation: '',
        }
      }).catch((error) => {
        this.submitted = false;
        if (error.response.data?.errors) {
          this.errors = error.response.data.errors;
        } else {
          Alert.toastError(error.response.data)
        }
      });
    }
  }
}
</script>

<style scoped>

</style>