<template>
  <div>
    <div>
      <video ref="video" class="img-fluid" autoplay playsinline style="width: 100%;"></video>
      <button class="btn btn-primary mt-2" @click="takePhoto">Tirar Foto</button>
      <canvas ref="canvas" style="display: none;"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CameraCapture',
  emits: ['photo-captured'],
  data() {
    return {
      videoStream: null,
    };
  },
  methods: {
    async startCamera() {
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = this.videoStream;
      } catch (error) {
        console.error('Erro ao acessar câmera:', error);
      }
    },
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
      }
    },
    takePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      const maxWidth = 320;
      const maxHeight = 240;

      let width = video.videoWidth;
      let height = video.videoHeight;
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.photo = canvas.toDataURL('image/png');
      this.$emit('photo-captured', this.photo);
      this.stopCamera();
    },
  },
  mounted() {
    this.startCamera();
  },
  beforeUnmount() {
    this.stopCamera();
  },
};
</script>

<style scoped>
</style>
