<template>
  <div class="card mt-2 mb-2" v-for="(medicalRecord, index) in medicalRecords" :key="medicalRecord.id_medrec">

    <div class="card-header collapsible cursor-pointer rotate pt-2" :class="index > 0 ? 'collapsed' : 'active'"
         data-bs-toggle="collapse" :data-bs-target="'#kt_docs_card_collapsible_' + medicalRecord.id_medrec">
      <div class="card-title">
        <h2>#{{medicalRecord.id_medrec}} {{ medicalRecord.record_type.rectype_name }}</h2>
      </div>
      <div class="card-toolbar rotate-180">
        <span class="svg-icon svg-icon-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"></path>
          </svg>
        </span>
      </div>
    </div>
    <div :id="'kt_docs_card_collapsible_' + medicalRecord.id_medrec" class="collapse" :class="index > 0 ? '' : 'show'">
      <div class="card-body">
        <span class="text-gray-400 fw-bold fs-7">Todas as informações são salvas automaticamente.</span>
        <br>
        <br>

          <ul v-if="medicalRecord.record_type.allow_prescription || medicalRecord.record_type.allow_exam_request"
              class="nav nav-custom  nav-tabs nav-line-tabs nav-line-tabs-2x bg-light-primary border-0 fs-4 fw-bold mb-8">
            <li class="nav-item">
              <a class="nav-link text-dark text-active-primary pb-2 active" data-bs-toggle="tab"
                 :href="'#tab-register-' + medicalRecord.id_medrec">
                Registro
              </a>
            </li>
            <li v-if="medicalRecord.record_type.allow_cid" class="nav-item">
              <a class="nav-link text-dark text-active-primary pb-2" data-bs-toggle="tab"
                 :href="'#tab-cid-' + medicalRecord.id_medrec">
                CID
              </a>
            </li>
            <li v-if="medicalRecord.record_type.allow_prescription" class="nav-item">
              <a class="nav-link text-dark text-active-primary pb-2" data-bs-toggle="tab"
                 :href="'#tab-prescription-' + medicalRecord.id_medrec">
                Prescrição
              </a>
            </li>
            <li v-if="medicalRecord.record_type.allow_exam_request" class="nav-item">
              <a class="nav-link text-dark text-active-primary pb-2" data-bs-toggle="tab"
                 :href="'#tab-exam-' + medicalRecord.id_medrec">
                Exame
              </a>
            </li>
            <li v-if="medicalRecord.record_type.allow_document" class="nav-item">
              <a class="nav-link text-dark text-active-primary pb-2" data-bs-toggle="tab"
                 :href="'#tab-document-' + medicalRecord.id_medrec">
                Documentos
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark text-active-primary pb-2" data-bs-toggle="tab"
                 :href="'#tab-health-data-' + medicalRecord.id_medrec">
                Dados de Saúde
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link text-dark text-active-primary pb-2" data-bs-toggle="tab"
                 :href="'#tab-attachment-' + medicalRecord.id_medrec">
                Anexos
              </a>
            </li>

          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" :id="'tab-register-' + medicalRecord.id_medrec" role="tabpanel">
              <div class="row">
                <InputAttendance v-for="attribute in medicalRecord.attributes"
                                 :key="'attendance-' + attribute.id_medrec_attr"
                                 @onChange="saveAttribute"
                                 :attribute="attribute"
                                 :disabled="false" />
              </div>
            </div>
            <div class="tab-pane fade" :id="'tab-cid-' + medicalRecord.id_medrec" role="tabpanel">
              <Cid :medical-record="medicalRecord" :schedule="schedule" :key="medicalRecord?.id_medrec"/>
            </div>
            <div class="tab-pane fade" :id="'tab-prescription-' + medicalRecord.id_medrec" role="tabpanel">
              <Prescription :medical-record="medicalRecord" :schedule="schedule" :presc-types="prescTypes" :prescription-protocols="prescriptionProtocols" :drug-admins="drugsAdmins" :certifiers="certifiers" :key="medicalRecord?.id_medrec"/>
            </div>
            <div class="tab-pane fade" :id="'tab-exam-' + medicalRecord.id_medrec" role="tabpanel">
              <ExamRequest :medical-record="medicalRecord" :schedule="schedule" :exam-protocols="examProtocols" :certifiers="certifiers" :key="medicalRecord?.id_medrec"/>
            </div>
            <div class="tab-pane fade" :id="'tab-document-' + medicalRecord.id_medrec" role="tabpanel">
              <Document :medical-record="medicalRecord" :schedule="schedule" :document-templates="documentTemplates" :certifiers="certifiers" :key="medicalRecord?.id_medrec"/>
            </div>
            <div class="tab-pane fade" :id="'tab-health-data-' + medicalRecord.id_medrec" role="tabpanel">
              <HealthData :medical-record="medicalRecord" :schedule="schedule" :health-indicator-groups="healthIndicatorGroups" :key="medicalRecord?.id_medrec"/>
            </div>
            <div class="tab-pane fade" :id="'tab-attachment-' + medicalRecord.id_medrec" role="tabpanel">
              <Attachment :medical-record="medicalRecord" :schedule="schedule" :key="medicalRecord?.id_medrec"/>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../../helpers/http";
import InputAttendance from '../shared/Input';
import Alert from "../../../helpers/alert";
import Prescription from "./Prescription";
import ExamRequest from "./ExamRequest";
import Document from "./Document";
import Cid from "./Cid";
import HealthData from "./HealthData";
import Attachment from "./Attachment";

export default {
  name: "AttendanceTab",
  components: {
    Document,
    InputAttendance,
    Prescription,
    ExamRequest,
    Cid,
    HealthData,
    Attachment,
  },
  props: {
    medicalRecords: Object,
    schedule: Object,
    examProtocols: Array,
    prescriptionProtocols: Array,
    prescTypes: Array,
    drugsAdmins: Array,
    documentTemplates: Array,
    healthIndicatorGroups: Array,
    certifiers: Array,
  },
  methods: {
    saveAttribute(attribute) {
      let url = `/professionals/${this.schedule?.id_prof}/patients/${this.schedule?.id_patient}/medical-records/${attribute.id_medrec}/attributes/${attribute.id_medrec_attr}`;
      http.put(url, {'text': attribute.text, 'text_compl': attribute.text_compl}).then((_) => {
      }).catch((_) => {
        Alert.toastError("Erro ao salvar informação.");
      });
    },
  }
}
</script>

<style scoped>

</style>