<template>
  <div class="card">
    <div class="card-body">
      <div class="col-md-12">
        <div class="col-md-12  mt-4">
          <div class="row">
            <div class="col-md-10">
              <InputSearchCid v-model="searchCid" @onSetSearchCid="this.setCidSearched"></InputSearchCid>
            </div>
            <div class="col-md-2">
              <button type="button" class="btn btn-primary" @click="addCid()">Incluir</button>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-12 mb-6 mt-4">
        <table class="table table-bordered table-hover table-light gy-3">
          <thead>
          <tr class="fw-bold fs-6 text-muted">
            <th style="text-align: center">#</th>
            <th class="text-center">CID</th>
            <th>Descrição</th>
            <th v-if="!readonly" class="text-center">Açoes</th>
          </tr>
          </thead>
          <tr v-for="(cid, index) in cids" :key="cid.id_cid" >
            <td style="text-align: center">{{ index + 1 }}</td>
            <td class="text-center">{{ cid.cid_code}}</td>
            <td>{{ cid.cid_name }}</td>
            <td v-if="!readonly" class="text-center">
              <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      @click="delCid(cid.id_cid)"
                      data-bs-toggle="tooltip" title="Excluir CID">
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../../helpers/http";
import Alert from "../../../helpers/alert";
import InputSearchCid from "../../../components/InputSearchCid";

export default {
  name: "CidRequestItems",
  components: {
    InputSearchCid
  },
  props: {
    medicalRecord: Object,
    schedule: Object,
    readonly: false,
  },
  data() {
    return {
      cids: [],
      searchCid: '',
      cidSearched: '',
    }
  },
  mounted() {
    this.cids = this.medicalRecord.cids;
  },
  methods: {
    clearCid() {
      this.cidSearched = '';
      this.searchCid = '';
    },
    setCidSearched(cid) {
      this.cidSearched = cid;
    },
    getCidRequestItems() {
      http.get(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/cids`).then((response) => {
        this.cids = response.data;
      });
    },
    delCid(cidId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o CID?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/cids/${cidId}`).then(_ => {
            this.getCidRequestItems();
            Alert.toastSuccess("CID excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    addCid() {
      if (this.cidSearched?.id_cid) {
        http.post(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/cids`, { id_cid: this.cidSearched.id_cid }).then(_ => {
          this.getCidRequestItems();
          Alert.toastSuccess("CID cadastrado com sucesso.");
          this.clearCid();
        }).catch(e => {
          Alert.toastError(e.response.data.message??e.response.data);
        });
      }
    }
  }
}
</script>

<style scoped>

ul li:hover {
  cursor: pointer;
}

</style>