<template>
    <Dropdown v-model="value"
              :options="professionals"
              optionLabel="prof_name"
              optionValue="id_prof"
              showClear
              empty-message="Nenhuma opção"
              empty-filter-message="Nenhuma opção encontrada"
              id="id_prof_filter"
              class="form-control form-control-solid form-control-sm"
              :filter="true"
              @change="onChange($event.value)"
              placeholder="Profissional"
              style="display: flex;"
    />
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
    name: "CalendarInputProfessionals",
    props: ['modelValue'],
    emits: ['change', 'update:modelValue'],
    components: {
        Dropdown,
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        },
        professionals() {
            return this.$store.state.auth.professionals;
        },
        userAuth() {
            return this.$store.state.auth.user;
        },
    },
    mounted() {
        this.value = this.userAuth.id_prof;
        setTimeout(() => this.onChange(this.value), 300);
    },
    methods: {
        onChange(value) {
            let professional = this.professionals.find((professional) => professional.id_prof === value);
            this.$emit('change', professional);
        }
    }
}
</script>

<style>
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0.75rem !important;
}
.p-dropdown-trigger {
    margin-right: -14px;
}
</style>