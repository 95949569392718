<template>
  <Toolbar/>
  <div class="container-xxl">
    <div class="row page-titles mx-0">

      <div class="row mb-4">
        <div class="col-sm-6 p-md-0 justify-content-sm-start mt-2 mt-sm-0 d-flex">
          <form class="form-inline">
            <label>Selecione o período:</label>
            <select class="form-control default-select" v-model="this.indicatorsInterval"
                    @change="this.refreshIndicators();">
              <option value="C">Dia Atual</option>
              <option value="W">Última Semana</option>
              <option value="Q">Última Quinzena</option>
              <option value="M">Último Mês</option>
            </select>
          </form>
        </div>

        <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
          <a href="javascript:void(0);" class="btn btn-primary mt-6" @click="refreshAll();" title="Atualizar Painel">
            <i :class="refreshingIndicators  ? 'fa-spin' : '' " class="bi bi-arrow-clockwise mr-3"
               style="font-size: 1.5rem"></i>{{
              refreshingIndicators || refreshingSchedBySpecChart ? 'Atualizando' : 'Atualizar'
            }}
          </a>
        </div>
      </div>
    </div> <!-- Header -->

    <div class="row">
      <div class="col-xl-2 col-sm-4 m-t35 mb-4">
        <div class="card card-coin">
          <div class="card-body text-center">
            <svg class="mb-3 currency-icon" width="80" height="80" viewBox="0 0 80 80" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#FFAB2D "/>
              <path
                  d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z"
                  fill="#FFAB2D"/>
              <g
                  transform="matrix(0.01000625,0,0,-0.00938873,14.348969,64.046944)"
                  id="g4"
                  style="fill:#000000;stroke:none">
                <path
                    d="m 1112,5109 c -142,-28 -267,-140 -315,-283 -21,-62 -22,-184 -2,-250 111,-365 622,-399 781,-52 142,311 -128,652 -464,585 z"
                    fill="white"/>
                <path
                    d="m 2664,5025 c -191,-60 -308,-247 -274,-438 61,-342 498,-442 700,-159 53,74 73,146 68,247 -7,125 -63,226 -165,296 -90,62 -230,85 -329,54 z"
                    fill="white"/>
                <path
                    d="m 4007,4940 c -223,-57 -338,-306 -233,-507 32,-60 50,-81 109,-126 62,-47 143,-70 228,-65 253,18 404,282 291,511 -56,114 -162,185 -289,192 -37,2 -85,0 -106,-5 z"
                    fill="white"/>
                <path
                    d="M 515,4121 C 352,4074 222,3938 185,3775 172,3720 169,3581 167,2906 l -2,-804 30,-44 c 43,-62 103,-90 181,-86 94,6 164,64 185,155 5,21 9,375 9,786 0,496 3,755 10,768 12,21 40,25 58,7 9,-9 12,-407 12,-1730 C 650,755 653,226 661,190 685,79 781,5 900,5 c 102,1 176,50 219,145 20,44 21,61 21,1043 l 0,997 55,0 55,0 0,-1002 c 0,-950 1,-1005 19,-1043 40,-89 121,-140 221,-140 100,0 181,51 221,140 18,38 19,117 19,1786 0,1344 3,1748 12,1757 16,16 55,15 65,0 4,-7 10,-364 13,-794 l 5,-781 30,-48 c 42,-68 100,-98 181,-93 85,5 137,40 171,115 l 25,55 -7,782 c -4,429 -11,804 -16,831 -23,126 -119,259 -234,324 -107,61 -112,61 -786,60 -551,0 -620,-2 -674,-18 z"
                    fill="white"/>
                <path
                    d="m 2270,4135 c 0,-4 16,-28 35,-54 50,-69 102,-183 122,-270 15,-69 17,-154 18,-911 l 0,-835 -32,-68 c -19,-39 -55,-92 -88,-126 l -55,-58 0,-664 c 0,-400 4,-678 10,-699 13,-48 63,-106 113,-132 117,-61 282,7 316,129 7,25 11,348 11,961 l 0,922 50,0 50,0 0,-927 c 0,-912 1,-929 21,-973 35,-78 93,-122 178,-136 87,-14 194,46 232,130 18,39 19,109 19,1662 0,1649 0,1644 35,1644 41,-1 40,17 45,-747 5,-660 7,-731 22,-759 77,-141 277,-139 340,4 20,44 20,63 16,791 l -5,746 -25,68 c -54,143 -148,234 -297,285 -39,13 -127,16 -588,19 -299,3 -543,1 -543,-2 z"
                    fill="white"/>
                <path
                    d="m 3790,4125 c 0,-3 20,-34 44,-69 24,-35 54,-90 66,-123 49,-129 51,-165 48,-979 l -3,-759 -27,-58 c -48,-103 -154,-193 -270,-231 -17,-5 -18,-41 -18,-564 l 0,-559 23,-44 c 13,-24 43,-57 66,-74 38,-27 51,-30 116,-30 65,0 78,3 116,30 23,17 53,50 66,74 l 23,44 0,849 0,848 45,0 45,0 0,-843 0,-843 26,-53 c 36,-71 86,-104 168,-109 78,-4 138,24 181,86 l 30,44 5,1490 c 5,1377 6,1492 22,1501 12,8 21,7 32,-2 14,-12 16,-66 17,-470 1,-251 4,-554 8,-673 5,-197 8,-219 27,-244 36,-49 70,-67 131,-71 75,-5 126,23 161,87 l 24,45 -5,680 c -6,734 -6,725 -61,819 -34,59 -117,133 -180,162 -29,13 -86,29 -127,34 -74,9 -799,14 -799,5 z"
                    fill="white"/>
              </g>
            </svg>
            <h2 class="text-black mb-2 font-w800">
              <span v-if="adminIndicatorsMain.busy_rate">{{ adminIndicatorsMain.busy_rate }}%</span>
              <i v-else-if="!adminIndicatorsMain.busy_rate && refreshingIndicators" class="spinner-border"></i>
              <span v-else>0%</span>
            </h2>
            <p class="text-black">Ocupação</p>
          </div>
        </div>
      </div>

      <!-- <div class="col-xl-2 col-sm-4 m-t35 mb-4">
        <div class="card card-coin">
          <div class="card-body text-center">
            <svg class="mb-3 currency-icon" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#00ADA3"/>
              <path d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z" fill="#00ADA3"/>
              <g transform="matrix(0.01071583,0,0,-0.01071583,12.57114,64)" style="fill:#000000;stroke:none">
                <path d="M 669,4462 C 548,4430 453,4358 386,4247 317,4131 320,4215 320,2560 320,905 317,989 386,873 431,798 476,753 547,710 653,646 689,641 1121,641 l 386,-1 7,38 c 28,176 199,538 337,713 16,20 29,42 29,47 0,6 -27,48 -60,94 -143,200 -272,499 -320,743 -10,52 -17,64 -52,90 -89,67 -156,196 -165,321 -18,231 127,438 346,496 60,15 146,17 924,17 955,1 942,2 1054,-65 111,-67 183,-162 215,-283 44,-170 2,-336 -119,-462 -50,-53 -56,-64 -78,-151 -60,-236 -179,-499 -322,-714 l -54,-82 71,-108 c 120,-185 219,-396 285,-606 l 27,-88 377,1 c 421,0 459,5 564,69 71,43 116,88 161,163 69,117 66,31 65,1694 0,1670 4,1566 -69,1686 -43,71 -88,116 -163,161 -118,70 4,66 -2014,65 -1685,0 -1823,-1 -1884,-17 z m 536,-490 c 94,-65 99,-190 10,-258 -70,-53 -167,-39 -222,33 -105,137 70,323 212,225 z m 490,-6 c 95,-73 79,-219 -31,-271 -100,-47 -224,34 -224,147 0,46 50,124 92,143 49,22 120,14 163,-19 z m 480,0 c 68,-52 83,-134 36,-207 -51,-82 -133,-101 -214,-49 -50,31 -77,79 -77,134 0,43 49,118 91,139 48,25 118,18 164,-17 z" fill="white" />
                <path d="m 1682,2860 c -33,-15 -47,-29 -62,-62 -40,-88 -12,-184 62,-218 24,-11 55,-20 69,-20 25,0 26,-3 32,-62 30,-322 197,-685 442,-961 l 87,-97 -58,-66 c -194,-218 -310,-416 -393,-670 -38,-114 -81,-307 -81,-360 0,-20 -5,-24 -27,-24 -16,0 -47,-9 -71,-20 -108,-49 -108,-231 0,-280 41,-19 72,-20 878,-20 806,0 837,1 878,20 108,49 108,231 0,280 -24,11 -51,20 -60,20 -12,0 -18,12 -23,48 -53,377 -212,712 -470,990 l -77,82 58,66 c 194,218 310,416 393,670 38,114 81,307 81,360 0,20 5,24 28,24 15,0 46,9 70,20 33,15 47,29 62,62 40,88 12,184 -62,218 -41,19 -72,20 -878,20 -806,0 -837,-1 -878,-20 z" fill="white" />
              </g>
            </svg>
            <h2 class="text-black mb-2 font-w800">
              <span v-if="adminIndicatorsMain.lack_rate">{{ adminIndicatorsMain.lack_rate}}%</span>
              <i v-else-if="!adminIndicatorsMain.lack_rate && refreshingIndicators" class="spinner-border"></i>
              <span v-else>0%</span>
            </h2>
            <p class="text-black">Ociosidade</p>
          </div>
        </div>
      </div> -->

      <div class="col-xl-2 col-sm-4 m-t35 mb-4">
        <div class="card card-coin">
          <div class="card-body text-center">
            <svg class="mb-3 currency-icon" width="80" height="80" viewBox="0 0 80 80" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#00ADA3"/>
              <path
                  d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z"
                  fill="#00ADA3"/>
              <g transform="matrix(0.01071583,0,0,-0.01071583,12.57114,64)" style="fill:#000000;stroke:none">
                <path
                    d="M 669,4462 C 548,4430 453,4358 386,4247 317,4131 320,4215 320,2560 320,905 317,989 386,873 431,798 476,753 547,710 653,646 689,641 1121,641 l 386,-1 7,38 c 28,176 199,538 337,713 16,20 29,42 29,47 0,6 -27,48 -60,94 -143,200 -272,499 -320,743 -10,52 -17,64 -52,90 -89,67 -156,196 -165,321 -18,231 127,438 346,496 60,15 146,17 924,17 955,1 942,2 1054,-65 111,-67 183,-162 215,-283 44,-170 2,-336 -119,-462 -50,-53 -56,-64 -78,-151 -60,-236 -179,-499 -322,-714 l -54,-82 71,-108 c 120,-185 219,-396 285,-606 l 27,-88 377,1 c 421,0 459,5 564,69 71,43 116,88 161,163 69,117 66,31 65,1694 0,1670 4,1566 -69,1686 -43,71 -88,116 -163,161 -118,70 4,66 -2014,65 -1685,0 -1823,-1 -1884,-17 z m 536,-490 c 94,-65 99,-190 10,-258 -70,-53 -167,-39 -222,33 -105,137 70,323 212,225 z m 490,-6 c 95,-73 79,-219 -31,-271 -100,-47 -224,34 -224,147 0,46 50,124 92,143 49,22 120,14 163,-19 z m 480,0 c 68,-52 83,-134 36,-207 -51,-82 -133,-101 -214,-49 -50,31 -77,79 -77,134 0,43 49,118 91,139 48,25 118,18 164,-17 z"
                    fill="white"/>
                <path
                    d="m 1682,2860 c -33,-15 -47,-29 -62,-62 -40,-88 -12,-184 62,-218 24,-11 55,-20 69,-20 25,0 26,-3 32,-62 30,-322 197,-685 442,-961 l 87,-97 -58,-66 c -194,-218 -310,-416 -393,-670 -38,-114 -81,-307 -81,-360 0,-20 -5,-24 -27,-24 -16,0 -47,-9 -71,-20 -108,-49 -108,-231 0,-280 41,-19 72,-20 878,-20 806,0 837,1 878,20 108,49 108,231 0,280 -24,11 -51,20 -60,20 -12,0 -18,12 -23,48 -53,377 -212,712 -470,990 l -77,82 58,66 c 194,218 310,416 393,670 38,114 81,307 81,360 0,20 5,24 28,24 15,0 46,9 70,20 33,15 47,29 62,62 40,88 12,184 -62,218 -41,19 -72,20 -878,20 -806,0 -837,-1 -878,-20 z"
                    fill="white"/>
              </g>
            </svg>
            <h2 class="text-black mb-2 font-w800">
              <span v-if="adminIndicatorsMain.sched_by_user_rate">{{ adminIndicatorsMain.sched_by_user_rate }}%</span>
              <i v-else-if="!adminIndicatorsMain.sched_by_user_rate && refreshingIndicators" class="spinner-border"></i>
              <span v-else>0%</span>
            </h2>
            <p class="text-black">Agendado pelo Paciente</p>
          </div>
        </div>
      </div>

      <div class="col-xl-2 col-sm-4 m-t35 mb-4">
        <div class="card card-coin">
          <div class="card-body text-center">
            <svg class="mb-3 currency-icon" width="80" height="80" viewBox="0 0 80 80" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#51A6F5"/>
              <path
                  d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z"
                  fill="#51A6F5"/>
              <g transform="matrix(0.009375,0,0,-0.009375,16,64.009375)" id="g4" style="fill:#000000;stroke:none">
                <path
                    d="M 1051,4634 C 940,4604 830,4522 776,4429 751,4386 59,2763 19,2655 3,2611 1,2571 0,2320 c 0,-302 5,-342 50,-436 29,-59 120,-157 177,-191 24,-14 71,-34 104,-44 57,-18 105,-19 830,-19 423,0 769,-2 769,-5 0,-2 -52,-242 -115,-533 -76,-349 -115,-549 -115,-589 0,-69 28,-169 65,-228 13,-22 82,-93 152,-157 L 2045,1 2843,798 c 760,759 800,801 835,871 l 37,75 3,1255 c 2,1231 2,1257 -18,1321 -25,82 -53,128 -119,195 -56,56 -146,107 -221,124 -30,7 -422,10 -1150,10 -933,0 -1113,-3 -1159,-15 z"
                    fill="white"/>
                <path d="m 4190,3255 0,-1395 465,0 465,0 0,1395 0,1395 -465,0 -465,0 0,-1395 z" fill="white"/>
              </g>
            </svg>
            <h2 class="text-black mb-2 font-w800">
              <span v-if="adminIndicatorsMain.miss_rate">{{ adminIndicatorsMain.miss_rate }}%</span>
              <i v-else-if="!adminIndicatorsMain.miss_rate && refreshingIndicators" class="spinner-border"></i>
              <span v-else>0%</span>
            </h2>
            <p class="text-black">Absenteísmo</p>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-4 m-t35 mb-4">
        <div class="card card-coin">
          <div class="card-body text-center">
            <svg class="mb-3 currency-icon" width="80" height="80" viewBox="0 0 80 80" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#FFAB2D"/>
              <path
                  d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z"
                  fill="#FFAB2D"/>
              <g style="fill:#000000;stroke:none">

                <path
                    d="m 15,60 h 9.090909 V 33 H 15 V 60 z M 65,35.25 c 0,-2.48625 -2.034091,-4.5 -4.545454,-4.5 H 46.102273 l 2.170455,-10.2825 c 0.04546,-0.225 0.07954,-0.46125 0.07954,-0.70875 0,-0.93375 -0.386362,-1.7775 -1,-2.385 L 44.931819,15 29.965909,29.81625 C 29.147728,30.6375 28.636364,31.7625 28.636364,33 v 22.5 c 0,2.48625 2.03409,4.5 4.545455,4.5 h 20.454545 c 1.886363,0 3.5,-1.13625 4.181817,-2.745 L 64.670454,41.3925 C 64.875,40.875 65,40.32375 65,39.75 V 35.44125 L 64.977272,35.41875 65,35.25 z"
                    fill="white"/>
              </g>
            </svg>
            <h2 class="text-black mb-2 font-w800">
              <span v-if="adminIndicatorsMain.assiduity_rate">{{ adminIndicatorsMain.assiduity_rate }}%</span>
              <i v-else-if="!adminIndicatorsMain.assiduity_rate && refreshingIndicators" class="spinner-border"></i>
              <span v-else>0%</span>
            </h2>
            <p class="text-black">Assiduidade</p>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-4 m-t35 mb-4">
        <div class="card card-coin">
          <div class="card-body text-center">
            <svg class="mb-3 currency-icon" width="80" height="80" viewBox="0 0 80 80" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#00ADA3"/>
              <path
                  d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z"
                  fill="#00ADA3"/>
              <g id="g4" style="fill:#000000;stroke:none">
                <path
                    d="m 33.674738,32.161652 c 0,-2.530629 3.701254,-2.962752 6.911777,-2.962752 3.025952,0 7.160641,1.418176 10.617841,3.210523 l 0.677488,-6.974977 c -1.728928,-0.988459 -5.73984,-2.161488 -9.813953,-2.406635 l 0.987365,-7.098753 h -6.604523 l 0.988677,7.098753 c -8.517147,0.801264 -11.295329,5.738748 -11.295329,9.87453 0,10.430646 19.440712,8.210987 19.440712,14.627441 0,2.409259 -2.281766,3.271318 -6.294865,3.271318 -5.430401,0 -9.505607,-1.911312 -11.602583,-3.888011 l -1.050347,7.839441 c 1.976918,1.173029 5.986518,2.222064 10.802412,2.469835 l -0.988677,6.851201 h 6.604523 L 42.067891,57.159384 C 52.066634,56.294701 53.856356,50.985889 53.856356,47.469206 53.856575,35.063829 33.674738,38.14817 33.674738,32.161652 z"
                    fill="white"/>
              </g>
            </svg>
            <h2 class="text-black mb-2 font-w800">
              <span v-if="adminIndicatorsMain.billing">R$ {{ adminIndicatorsMain.billing }}</span>
              <i v-else-if="!adminIndicatorsMain.billing && refreshingIndicators" class="spinner-border"></i>
              <span v-else>R$ 0.00</span>
            </h2>
            <p class="text-black">Faturamento</p>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-4 m-t35 mb-4">
        <div class="card card-coin">
          <div class="card-body text-center">
            <svg class="mb-3 currency-icon" width="80" height="80" viewBox="0 0 80 80" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#51A6F5"/>
              <path
                  d="M40.725 0.00669178C18.6241 -0.393325 0.406678 17.1907 0.00666126 39.275C-0.393355 61.3592 17.1907 79.5933 39.2749 79.9933C61.3592 80.3933 79.5933 62.8093 79.9933 40.7084C80.3933 18.6241 62.8092 0.390041 40.725 0.00669178ZM39.4083 72.493C21.4909 72.1597 7.17362 57.3257 7.50697 39.4083C7.82365 21.4909 22.6576 7.17365 40.575 7.49033C58.5091 7.82368 72.8096 22.6576 72.493 40.575C72.1763 58.4924 57.3257 72.8097 39.4083 72.493Z"
                  fill="#51A6F5 "/>
              <g
                  transform="matrix(0.01061947,0,0,-0.01061943,12.814159,64.053097)"
                  id="g4"
                  style="fill:#000000;stroke:none">
                <path d="m 2400,4810 0,-310 160,0 160,0 0,310 0,310 -160,0 -160,0 0,-310 z" fill="white"/>
                <path
                    d="m 945,4470 -110,-110 225,-225 225,-225 112,112 113,113 -222,222 c -123,123 -225,223 -228,223 -3,0 -55,-50 -115,-110 z"
                    fill="white"/>
                <path
                    d="m 3832,4357 -222,-222 113,-113 112,-112 225,225 225,225 -110,110 c -60,60 -112,110 -115,110 -3,0 -105,-100 -228,-223 z"
                    fill="white"/>
                <path
                    d="m 2390,4274 c -14,-2 -52,-9 -85,-15 -169,-29 -381,-117 -533,-221 -101,-70 -249,-212 -327,-317 -117,-155 -208,-361 -252,-571 -25,-118 -25,-390 0,-513 44,-218 144,-438 275,-603 22,-29 95,-108 161,-178 108,-113 125,-136 170,-228 52,-109 81,-214 81,-297 l 0,-51 679,0 678,0 6,83 c 13,182 119,391 257,508 56,47 159,168 217,254 92,137 166,314 205,493 32,144 32,406 -1,551 -65,295 -192,524 -402,726 -191,183 -409,299 -679,361 -63,15 -395,28 -450,18 z"
                    fill="white"/>
                <path d="m 300,2860 0,-160 310,0 310,0 0,160 0,160 -310,0 -310,0 0,-160 z" fill="white"/>
                <path d="m 4200,2860 0,-160 310,0 310,0 0,160 0,160 -310,0 -310,0 0,-160 z" fill="white"/>
                <path
                    d="m 1060,1585 -225,-225 113,-112 112,-113 227,227 228,228 -110,110 c -60,60 -112,110 -115,110 -3,0 -106,-101 -230,-225 z"
                    fill="white"/>
                <path
                    d="m 3715,1700 -110,-110 228,-228 227,-227 112,113 113,112 -225,225 c -124,124 -227,225 -230,225 -3,0 -55,-50 -115,-110 z"
                    fill="white"/>
                <path
                    d="m 1880,902 c 0,-312 13,-395 84,-542 41,-85 175,-223 266,-273 110,-60 198,-82 330,-82 92,0 130,5 190,23 196,61 357,207 433,392 44,107 49,144 54,428 l 5,272 -681,0 -681,0 0,-218 z"
                    fill="white"/>
              </g>
            </svg>
            <h2 class="text-black mb-2 font-w800">
              <span v-if="adminIndicatorsMain.attendance">{{ adminIndicatorsMain.attendance }}</span>
              <i v-else-if="!adminIndicatorsMain.attendance && refreshingIndicators" class="spinner-border"></i>
              <span v-else>0</span>
            </h2>
            <p class="text-black">Atendimentos</p>
          </div>
        </div>
      </div>
    </div> <!-- Indidicador Geral -->

    <div class="row">

      <div class="col-xl-4 col-xxl-4 mb-4">
        <div class="card" style="height: 350px">
          <div class="card-header border-0 flex-wrap pb-0">
            <div class="mt-7 mb-1mr-1">
              <h5 class="fs-20 text-black">Especialidades</h5>
              <p class="mb-0 fs-12 text-black">Número de Atendimentos</p>
            </div>
            <form class="float-right form-inline">
              <select class="form-control mt-3 mx-sm-2 smb-1 default-select" v-model="this.schedBySpecChartInterval"
                      @change="refreshingSchedBySpecChart = true; getSchedBySpecChart();">
                <option value="C">Dia Atual</option>
                <option value="W">Última Semana</option>
                <option value="Q">Última Quinzena</option>
                <option value="M">Último Mês</option>
              </select>
            </form>
          </div>
          <div v-if="this.refreshingSchedBySpecChart" class="text-center">
            <i class="spinner-border fa-3x"></i>
          </div>
          <div class="card-body pb-0">
            <apexchart
                ref="schedBySpecChart"
                width="100%"
                height="250"
                type="donut"
                :options="this.schedBySpecChartOptions"
                :series="this.schedBySpecChartSeries"
            />
          </div>
        </div>
      </div>  <!-- Donuts de Atendimentos por Especialidade -->

      <div class="col-xl-4 col-xxl-4 mb-4">
        <div class="card" style="height: 350px">
          <div class="card-header border-0 flex-wrap pb-0">
            <div class="mt-7 mb-1mr-1">
              <h5 class="fs-20 text-black">Profissionais</h5>
              <p class="mb-0 fs-12 text-black">Número de Atendimentos</p>
            </div>
            <form class="float-right form-inline">
              <select class="form-control mt-3 mx-sm-2 smb-1 default-select" v-model="this.schedByProfChartInterval"
                      @change="refreshingSchedByProfChart = true; getSchedByProfChart();">
                <option value="C">Dia Atual</option>
                <option value="W">Última Semana</option>
                <option value="Q">Última Quinzena</option>
                <option value="M">Último Mês</option>
              </select>
            </form>
          </div>
          <div v-if="this.refreshingSchedByProfChart" class="text-center">
            <i class="spinner-border fa-3x"></i>
          </div>
          <div class="card-body pb-0">
            <apexchart
                ref="schedByProfChart"
                width="100%"
                height="250"
                type="donut"
                :options="this.schedByProfChartOptions"
                :series="this.schedByProfChartSeries"
            />
          </div>
        </div>
      </div>  <!-- Donuts de Atendimentos por Profissional -->

      <div class="col-xl-4 col-xxl-4 mb-4">
        <div class="card" style="height: 350px">
          <div class="card-header border-0 flex-wrap pb-0">
            <div class="mt-7 mb-1mr-1">
              <h5 class="fs-20 text-black">Formas de Pagamento</h5>
              <p class="mb-0 fs-12 text-black">Número de Atendimentos</p>
            </div>
            <form class="float-right form-inline">
              <select class="form-control mt-3 mx-sm-2 smb-1 default-select" v-model="this.schedByPaymethsChartInterval"
                      @change="refreshingSchedByPaymethsChart = true; getSchedByPaymethsChart();">
                <option value="C">Dia Atual</option>
                <option value="W">Última Semana</option>
                <option value="Q">Última Quinzena</option>
                <option value="M">Último Mês</option>
              </select>
            </form>
          </div>
          <div v-if="this.refreshingSchedByPaymethsChart" class="text-center">
            <i class="spinner-border fa-3x"></i>
          </div>
          <div class="card-body pb-0">
            <apexchart
                ref="schedByPaymethsChart"
                width="100%"
                height="250"
                type="donut"
                :options="this.schedByPaymethsChartOptions"
                :series="this.schedByPaymethsChartSeries"
            />
          </div>
        </div>
      </div>  <!-- Donuts de Atendimentos por Forma de Pagamento -->

      <div class="col-xl-4 col-xxl-4 mb-4">
        <div class="card" style="height: 350px">
          <div class="card-header border-0 flex-wrap pb-0">
            <div class="mt-7 mb-1mr-1">
              <h5 class="fs-20 text-black">Convênios</h5>
              <p class="mb-0 fs-12 text-black">Número de Atendimentos</p>
            </div>
            <form class="float-right form-inline">
              <select class="form-control mt-3 mx-sm-2 smb-1 default-select"
                      v-model="this.schedByInsurancesChartInterval"
                      @change="refreshingSchedByInsurancesChart = true; getSchedByInsurancesChart();">
                <option value="C">Dia Atual</option>
                <option value="W">Última Semana</option>
                <option value="Q">Última Quinzena</option>
                <option value="M">Último Mês</option>
              </select>
            </form>
          </div>
          <div v-if="this.refreshingSchedByInsurancesChart" class="text-center">
            <i class="spinner-border fa-3x"></i>
          </div>
          <div class="card-body pb-0">
            <apexchart
                ref="schedByInsurancesChart"
                width="100%"
                height="250"
                type="donut"
                :options="this.schedByInsurancesChartOptions"
                :series="this.schedByInsurancesChartSeries"
            />
          </div>
        </div>
      </div>  <!-- Donuts de Atendimentos por Convênio -->

      <div class="col-xl-8 col-xxl-8">
        <div class="card" style="height: 350px">
          <div class="card-header border-0 flex-wrap pb-0">
            <div class="mt-7 mb-3 mr-3">
              <h4 class="fs-20 text-black">Taxa de Ocupação da Agenda</h4>
              <p class="mb-0 fs-12 text-black">% de ocupação</p>
            </div>
            <form class="float-right form-inline mt-3">
              <select v-model="this.selectedProfChartBusyRate" class="form-control mx-sm-3 mb-2 default-select"
                      @change="updateChartBusyRate();">
                <option v-for="prof in this.busyRateChartData.profs" :value="prof.id_prof">{{ prof.prof_name }}</option>
              </select>
            </form>
          </div>
          <div v-if="this.refreshingBusyRateChart" class="text-center">
            <i class="spinner-border fa-3x"></i>
          </div>
          <div class="card-body pb-0">
            <apexchart
                ref="busyRateChart"
                width="100%"
                height="250"
                type="area"
                :options="this.busyRateChartOptions"
                :series="this.busyRateChartSeries"
            />
          </div>
        </div>
      </div> <!-- Taxa de Ocupação da Agenda -->

    </div> <!-- Gráficos -->

    <div class="row">
      <div class="col-xl-12 col-lg12 col-xxl-12">
        <div class="card">
          <div class="card-header border-0 flex-wrap pb-0">
            <div class="mb-3 mr-3 mt-3">
              <h4 class="fs-20 text-black">Resumo por Profissional</h4>
              <form class="float-right form-inline">
                <select class="form-control mx-sm-3 mb-2 default-select" v-model="profIndicatorsInterval"
                        @change="this.refreshingProfIndicators= true; getProfessionals();">
                  <option value="C">Dia atual</option>
                  <option value="W">Última Semana</option>
                  <option value="Q">Última Quinzena</option>
                  <option value="M">Último Mês</option>
                </select>
                <input class="form-control mx-sm-3 mb-2" type="search"
                       v-model="this.profIndicatorsSearchTerm"
                       placeholder="Pesquisar ...">
              </form>
            </div>
          </div>
          <div class="card-body">
            <div v-if="refreshingProfessionals" class="text-center">
              <i class="spinner-border fa-3x"></i>
            </div>
            <div v-if="!this.refreshingProfessionals" class="table-responsive recentOrderTable">
              <table class="table shadow-hover verticle-middle table-responsive-md">
                <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Profissional</th>
                  <th scope="col" class="text-center">Ocupação</th>
                  <th scope="col" class="text-center">Ociosidade</th>
                  <th scope="col" class="text-center">Absenteísmo</th>
                  <th scope="col" class="text-center">Assiduidade</th>
                  <th scope="col" class="text-center">Faturamento</th>
                  <th scope="col" class="text-center">Atendimentos</th>
                </tr>
                </thead>
                <tbody v-for="item in this.profIndicators">
                <tr v-if="this.profIndicatorsSearchTerm==''||item.prof_name.toUpperCase().search('.*'+profIndicatorsSearchTerm.toUpperCase()+'.*')!=-1">
                  <td class="text-center"><img class="rounded-circle"
                                               :src="item.prof_photo ?? 'img/user_photo_default.png'" width="50"
                                               alt=""/></td>
                  <td>{{ item.prof_name }}</td>
                  <td class="text-center">{{ item.busy_rate }}%</td>
                  <td class="text-center">{{ item.lack_rate }}%</td>
                  <td class="text-center">{{ item.miss_rate }}%</td>
                  <td class="text-center">{{ item.assiduity_rate }}%</td>
                  <td class="text-center">R$ {{ item.billing }}</td>
                  <td class="text-center">{{ item.attendance }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Tabela de Indicadores por Profissioal -->

    <ModalWelcome v-if="!place?.place_welcome" :showModal="!place?.place_welcome"/>
  </div>
</template>

<script>
import http from "../helpers/http";
import ModalWelcome from "./ModalWelcome";
import VueApexCharts from 'vue3-apexcharts/src/vue3-apexcharts';
import Toolbar from "../components/layouts/auth/Toolbar.vue";

export default {
  name: 'Home',
  components: {
    apexchart: VueApexCharts,
    ModalWelcome,
    Toolbar,
  },
  data() {
    return {
      refreshingIndicators: false,
      adminIndicatorsMain: {},
      indicatorsInterval: 'C',

      profIndicatorsSearchTerm: '',
      refreshingProfessionals: false,
      profIndicatorsInterval: 'C',
      profIndicators: [],

      refreshingSchedBySpecChart: false,
      schedBySpecChartData: {},
      schedBySpecChartInterval: 'C',
      schedBySpecChartSeries: [],
      schedBySpecChartOptions: {
        labels: [],
        legend: {
          width: 120,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total'
                }
              }
            }
          }
        }
      },

      refreshingSchedByProfChart: false,
      schedByProfChartData: {},
      schedByProfChartInterval: 'C',
      schedByProfChartSeries: [],
      schedByProfChartOptions: {
        labels: [],
        legend: {
          width: 120,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total'
                }
              }
            }
          }
        }
      },

      refreshingSchedByPaymethsChart: false,
      schedByPaymethsChartData: {},
      schedByPaymethsChartInterval: 'C',
      schedByPaymethsChartSeries: [],
      schedByPaymethsChartOptions: {
        labels: [],
        legend: {
          width: 120,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total'
                }
              }
            }
          }
        }
      },

      refreshingSchedByInsurancesChart: false,
      schedByInsurancesChartData: {},
      schedByInsurancesChartInterval: 'C',
      schedByInsurancesChartSeries: [],
      schedByInsurancesChartOptions: {
        labels: [],
        legend: {
          width: 120,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total'
                }
              }
            }
          }
        }
      },

      refreshingBusyRateChart: false,
      selectedProfChartBusyRate: null,
      busyRateChartData: {},
      busyRateChartSeries: [
        {
          name: 'Geral',
          data: [],
        },
        {
          name: '',
          data: [],
        },
      ],
      busyRateChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        colors: ["#FFAB2D", "#00ADA3"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
        },
        legend: {
          show: true
        },
        grid: {
          borderColor: '#EEEEEE',
        },
        yaxis: {
          labels: {
            style: {
              colors: '#787878',
              fontSize: '13px',
              fontFamily: 'Poppins',
              fontWeight: 400

            },
            formatter: function (value) {
              return value;
            }
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#787878',
              fontSize: '13px',
              fontFamily: 'Poppins',
              fontWeight: 400

            },
          }
        },
        tooltip: {
          x: {
            format: '9999'
          },
        },
      },

    }
  },

  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },

  mounted() {
    this.refreshAll();
  },

  methods: {
    refreshAll(showSpinner = true) {
      if (showSpinner) {
        this.refreshingIndicators = true;
        this.refreshingSchedBySpecChart = true;
        this.refreshingSchedByProfChart = true;
        this.refreshingSchedByPaymethsChart = true;
        this.refreshingSchedByInsurancesChart = true;
        this.refreshingBusyRateChart = true;
        this.refreshingProfIndicators = true;
        this.getAdminIndicators();
        this.getSchedBySpecChart();
        this.getSchedByProfChart();
        this.getSchedByPaymethsChart();
        this.getSchedByInsurancesChart();
        this.getChartBusyRate();
        this.getProfessionals();
      }
    },

    refreshIndicators() {
      this.schedBySpecChartInterval = this.indicatorsInterval;
      this.schedByProfChartInterval = this.indicatorsInterval;
      this.schedByPaymethsChartInterval = this.indicatorsInterval;
      this.schedByInsurancesChartInterval = this.indicatorsInterval;
      this.profIndicatorsInterval = this.indicatorsInterval;
      this.refreshAll();
    },

    getAdminIndicators() {
      http.get(`/places/${this.place.id_place}/dashboard/place-indicators`, {params: {'interval': this.indicatorsInterval}}).then((response) => {
        this.adminIndicatorsMain = response.data;
        this.refreshingIndicators = false;
      });
    },

    getProfessionals() {
      http.get(`/places/${this.place.id_place}/dashboard/professional-indicators`, {params: {'interval': this.profIndicatorsInterval}}).then((response) => {
        this.profIndicators = response.data;
        this.refreshingProfIndicators = false;
      });

      this.profIndicators
    },

    getSchedBySpecChart() {
      http.get(`/places/${this.place.id_place}/dashboard/schedules-spec`, {params: {'interval': this.schedBySpecChartInterval}}).then((response) => {
        this.schedBySpecChartData = response.data;
        this.refreshingSchedBySpecChart = false;
        this.updateSchedBySpecChart();
      });
    },

    updateSchedBySpecChart() {
      this.schedBySpecChartOptions.labels = this.schedBySpecChartData.labels;
      this.schedBySpecChartSeries = this.schedBySpecChartData.series;
      this.$refs.schedBySpecChart.updateOptions(this.schedBySpecChartOptions);
      this.$refs.schedBySpecChart.updateSeries(this.schedBySpecChartSeries);
    },

    getSchedByProfChart() {
      http.get(`/places/${this.place.id_place}/dashboard/schedules-prof`, {params: {'interval': this.schedByProfChartInterval}}).then((response) => {
        this.schedByProfChartData = response.data;
        this.refreshingSchedByProfChart = false;
        this.updateSchedByProfChart();
      });
    },

    updateSchedByProfChart() {
      this.schedByProfChartOptions.labels = this.schedByProfChartData.labels;
      this.schedByProfChartSeries = this.schedByProfChartData.series;
      this.$refs.schedByProfChart.updateOptions(this.schedByProfChartOptions);
      this.$refs.schedByProfChart.updateSeries(this.schedByProfChartSeries);
    },

    getSchedByPaymethsChart() {
      http.get(`/places/${this.place.id_place}/dashboard/schedules-paymeths`, {params: {'interval': this.schedByPaymethsChartInterval}}).then((response) => {
        this.schedByPaymethsChartData = response.data;
        this.refreshingSchedByPaymethsChart = false;
        this.updateSchedByPaymethsChart();
      });
    },

    updateSchedByPaymethsChart() {
      this.schedByPaymethsChartOptions.labels = this.schedByPaymethsChartData.labels;
      this.schedByPaymethsChartSeries = this.schedByPaymethsChartData.series;
      this.$refs.schedByPaymethsChart.updateOptions(this.schedByPaymethsChartOptions);
      this.$refs.schedByPaymethsChart.updateSeries(this.schedByPaymethsChartSeries);
    },

    getSchedByInsurancesChart() {
      http.get(`/places/${this.place.id_place}/dashboard/schedules-insurances`, {params: {'interval': this.schedByInsurancesChartInterval}}).then((response) => {
        this.schedByInsurancesChartData = response.data;
        this.refreshingSchedByInsurancesChart = false;
        this.updateSchedByInsurancesChart();
      });
    },

    updateSchedByInsurancesChart() {
      this.schedByInsurancesChartOptions.labels = this.schedByInsurancesChartData.labels;
      this.schedByInsurancesChartSeries = this.schedByInsurancesChartData.series;
      this.$refs.schedByInsurancesChart.updateOptions(this.schedByInsurancesChartOptions);
      this.$refs.schedByInsurancesChart.updateSeries(this.schedByInsurancesChartSeries);
    },

    getChartBusyRate() {
      http.get(`/places/${this.place.id_place}/dashboard/busy-rate`).then((response) => {
        this.busyRateChartData = response.data;
        this.selectedProfChartBusyRate = this.busyRateChartData.profs[0]?.id_prof;
        this.refreshingBusyRateChart = false;
        this.updateChartBusyRate();
      });
    },

    updateChartBusyRate() {
      if (!this.busyRateChartOptions) return;

      this.busyRateChartOptions.xaxis.categories = this.busyRateChartData.categories;
      this.$refs.busyRateChart?.updateOptions(this.busyRateChartOptions);
      this.$refs.busyRateChart?.updateSeries([
        {
          name: 'Geral',
          data: this.busyRateChartData.series.find((prof) => prof.id_prof == null)?.data,
        },
        {
          name: this.busyRateChartData.series.find((prof) => prof.id_prof == this.selectedProfChartBusyRate)?.name,
          data: this.busyRateChartData.series.find((prof) => prof.id_prof == this.selectedProfChartBusyRate)?.data,
        },
      ]);
    },

  }

}
</script>

<style>
.card-coin:hover {
  transform: translateY(-10px);
}

.card-coin:hover .currency-icon {
  box-shadow: none;
}

.card-coin h2 {
  font-size: 30px;
}

@media only screen and (max-width: 1600px) {
  .card-coin h2 {
    font-size: 22px;
  }
}
</style>