<template>

  <div v-if="!hasPermission('List Prescription Protocol')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"></path>
                </svg>
              </span>
                <input type="text" data-kt-user-table-filter="search" v-model="search"
                       class="form-control form-control-solid w-250px ps-14"
                       placeholder="Pesquisar ...">
              </div>
            </div>
            <div class="card-toolbar">
              <div class="col-md-6" v-show="professionals.length > 1">
                <select class="form-select form-control-solid" aria-label="Selecionar Profissional"
                        v-model="currentProfessionalId" @change="loadPrescProts($event.target.value)">
                  <option value="" disabled selected v-if="professionals.length > 1">--Selecione--</option>
                  <option :value="professional.id_prof" v-for="professional in professionals">
                    {{ professional.prof_name }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                        title="Novo Protocolo" data-bs-target="#modal_store_presc_prot"
                        v-if="hasPermission('Store Prescription Protocol')"
                        @click="searchPrescProt=''; currentPrescProt='';">
                  <i class="bi bi-plus-lg"></i>Adicionar
                </button>
              </div>
            </div>
          </div> <!-- card header -->
          <div class="card-body py-4">
            <table class="table table-row-bordered gy-5">
              <thead>
              <tr class="fw-bold fs-6 text-muted">
                <th>Protocolo</th>
                <th style="text-align: center">Ativo</th>
                <th style="text-align: center">Ações</th>
              </tr>
              </thead>
              <tbody v-for="prescProt in prescProtsFiltered" :key="prescProt.id_presc_prot">
              <tr>
                <td>{{ prescProt.presc_prot_name }}</td>
                <td style="text-align: center">
                  <span class="badge badge-light-success" v-if="prescProt.active">Sim</span>
                  <span class="badge badge-light-danger" v-else>Não</span>
                </td>
                <td style="text-align: center">
                  <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          v-if="hasPermission('Update Prescription Protocol')"
                          @click="onClickBtnEdit(prescProt)"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_store_presc_prot"
                          title="Editar">
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          title="Ver detalhes"
                          rel="tooltip"
                          v-if="hasPermission('List Prescription Protocol Drug')"
                          @click="getDrugs(prescProt.id_presc_prot);">
                    <i class="bi bi-eye"></i>
                  </button>
                  <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          v-if="hasPermission('Delete Prescription Protocol')"
                          @click="deletePrescProt(prescProt.id_presc_prot)"
                          data-bs-toggle="tooltip" title="Excluir Protocolo">
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>

              <tr v-if="!drugsHide  && currentPrescProtId === prescProt.id_presc_prot && !loading">
                <td colspan="3">
                  <div class="d-flex justify-content-end">
                    <button class="btn btn-light-primary me-3" data-bs-toggle="modal"
                            v-if="hasPermission('Store Prescription Protocol Drug')"
                            title="Novo Medicamento" data-bs-target="#modal_store_drug" @click="formStoreDrug.active=true;">
                      <i class="bi bi-plus-lg"></i> Novo Medicamento
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="!drugsHide  && (drugs.length) && currentPrescProtId === prescProt.id_presc_prot && !loading">
                <td colspan="3">
                  <table class="table table-row-bordered table-hover table-light gy-3">
                    <thead>
                    <tr class="fw-bold fs-6 text-muted">
                      <th style="text-align: center">#</th>
                      <th>Medicamento</th>
                      <th>Posologia</th>
                      <th style="text-align: center">Adminsitração</th>
                      <th>Tipo de Prescrição</th>
                      <th style="text-align: center">Açoes</th>
                    </tr>
                    </thead>
                    <tr v-for="(drug, index) in drugs" :key="drug.id_presc_prot_drug">
                      <td style="text-align: center">{{ index + 1 }}</td>
                      <td style="white-space:pre-wrap; word-wrap:break-word">{{ drug.drug_name }}</td>
                      <td>{{ drug.dosage_desc }}</td>
                      <td style="text-align: center">{{ drug.drug_administration?.drug_admin_name }}</td>
                      <td>{{ drug.prescription_type?.presc_type_name }}</td>
                      <td style="text-align: center">
                        <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                data-bs-toggle="modal"
                                data-bs-target="#modal_edit_drug"
                                v-if="hasPermission('Update Prescription Protocol Drug')"
                                @click="onClickBtnEditDrug(drug)"
                                title="Editar">
                          <i class="bi bi-pencil-square"></i>
                        </button>
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Delete Prescription Protocol Drug')"
                                @click="deleteDrug(drug.id_presc_prot_drug)"
                                data-bs-toggle="tooltip" title="Excluir Medicamento">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr> <!-- drug table -->
              </tbody>
            </table>
          </div> <!-- card body -->
        </div> <!-- card -->

        <prescriptionProtocolDrugs
            :presc-prot="currentPrescProt"
            :drug-admins="drugAdmins"
            :presc-types="prescTypes"
            :current-professional-id="currentProfessionalId"
            @onClose="this.loadPrescProts(this.currentProfessionalId); drugsHide = true;">
        </prescriptionProtocolDrugs>

        <div class="modal fade" tabindex="-1" id="modal_store_drug">
          <div class="modal-dialog modal-lg">
            <form method="post" @submit.prevent="submitFormStoreDrug">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Adicionar medicamento</h5>
                  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                       aria-label="Fechar">
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="drug_name_drug_store" class="required form-label">Medicamento</label>
                    <textarea type="text" class="form-control form-control-solid"
                              v-model="formStoreDrug.drug_name" id="drug_name_drug_store" autofocus required rows="3">
                  </textarea>
                    <small class="text-danger" v-if="formStoreDrugErrors.drug_name">{{
                        formStoreDrugErrors.drug_name[0]
                      }}</small>
                  </div>
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="dosage_desc_drug_store" class="required form-label">Posologia</label>
                    <input class="form-control form-control-solid"
                           v-model="formStoreDrug.dosage_desc" id="dosage_desc_drug_store" required>
                    <small class="text-danger" v-if="formStoreDrugErrors.dosage_desc">{{
                        formStoreDrugErrors.dosage_desc[0]
                      }}</small>
                  </div>
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="presc_type_store" class="required form-label required">Tipo de Prescrição</label>
                    <select class="form-control form-select" id="presc_type_store"
                            aria-label="Selecione o tipo de prescrição" v-model="formStoreDrug.id_presc_type" required>
                      <option value="" disabled selected>--Selecione--</option>
                      <option :value="prescType.id_presc_type" v-for="prescType in prescTypes">
                        {{ prescType.presc_type_name }}
                      </option>
                    </select>
                    <small class="text-danger"
                           v-if="formStoreDrugErrors.id_presc_type">{{ formStoreDrugErrors.id_presc_type[0] }}</small>
                  </div>
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="drug_admin_store" class="required form-label">Via de Administração</label>
                    <select class="form-control form-select" id="drug_admin_store"
                            aria-label="Selecione a via de adminstração" v-model="formStoreDrug.id_drug_admin" required>
                      <option value="" disabled selected>--Selecione--</option>
                      <option :value="drugAdmin.id_drug_admin" v-for="drugAdmin in drugAdmins">
                        {{ drugAdmin.drug_admin_name }}
                      </option>
                    </select>
                    <small class="text-danger"
                           v-if="formStoreDrugErrors.id_drug_admin">{{ formStoreDrugErrors.id_drug_admin[0] }}</small>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                  <button type="submit" class="btn btn-primary">Cadastrar</button>
                </div>
              </div>
            </form>
          </div>
        </div> <!-- modal_store_drug -->

        <div class="modal fade" tabindex="-1" id="modal_edit_drug">
          <div class="modal-dialog modal-lg">
            <form method="post" @submit.prevent="submitFormEditDrug">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Editar medicamento</h5>
                  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                       aria-label="Fechar">
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="drug_name_drug_edit" class="required form-label required">Medicamento</label>
                    <textarea class="form-control form-control-solid"
                              v-model="formEditDrug.drug_name" id="drug_name_drug_edit" autofocus="true" required rows="3">
                  </textarea>
                    <small class="text-danger" v-if="formEditDrugErrors.drug_name">{{
                        formEditDrugErrors.drug_name[0]
                      }}</small>
                  </div>
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="dosage_desc_drug_edit" class="required form-label required">Posologia</label>
                    <input class="form-control form-control-solid"
                           v-model="formEditDrug.dosage_desc" id="dosage_desc_drug_edit" required>
                    <small class="text-danger" v-if="formEditDrugErrors.dosage_desc">{{
                        formEditDrugErrors.dosage_desc[0]
                      }}</small>
                  </div>
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="presc_type_edit" class="required form-label required">Tipo de Prescrição</label>
                    <select class="form-control form-select" id="presc_type_edit"
                            aria-label="Selecione o tipo de prescrição" v-model="formEditDrug.id_presc_type" required>
                      <option value="" disabled selected>--Selecione--</option>
                      <option :value="prescType.id_presc_type" v-for="prescType in prescTypes">
                        {{ prescType.presc_type_name }}
                      </option>
                    </select>
                    <small class="text-danger"
                           v-if="formEditDrugErrors.id_presc_type">{{ formEditDrugErrors.id_presc_type[0] }}</small>
                  </div>
                  <div class="col-md-12 mb-6 mt-4">
                    <label for="drug_admin_edit" class="required form-label required">Via de Administração</label>
                    <select class="form-control form-select" id="drug_admin_edit"
                            aria-label="Selecione a via de adminstração" v-model="formEditDrug.id_drug_admin" required>
                      <option value="" disabled selected>--Selecione--</option>
                      <option :value="drugAdmin.id_drug_admin" v-for="drugAdmin in drugAdmins">
                        {{ drugAdmin.drug_admin_name }}
                      </option>
                    </select>
                    <small class="text-danger"
                           v-if="formEditDrugErrors.id_drug_admin">{{ formEditDrugErrors.id_drug_admin[0] }}</small>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                  <button type="submit" class="btn btn-primary">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div> <!-- modal_edit_drug -->

      </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
//import draggable from 'vuedraggable'
import prescriptionProtocolDrugs from "./PrescriptionProtocolDrugs";
import Permission from "../../helpers/permission";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "PrescriptionProtocol",
  components: {
    //draggable,
    prescriptionProtocolDrugs,
      Toolbar,
  },
  data() {
    return {
      search: '',
      drugsHide: true,
      loading: false,
      draging: false,
      updatingDrug: false,
      currentProfessionalId: '',
      currentPrescProtId: '',
      currentPrescProt: '',
      prescProts: [],
      allPrescProts: [],
      drugs: [],
      searchPrescProt: '',
      prescTypes: [],
      drugAdmins: [],
      formStoreDrug: {
        id_presc_prot: '',
        id_presc_type: '',
        id_drug_admin: '',
        drug_name: '',
        dosage_desc: '',
      },
      formStoreDrugErrors: [],
      formEditDrug: {
        id_presc_prot: '',
        id_presc_type: '',
        id_drug_admin: '',
        drug_name: '',
        dosage_desc: '',
      },
      formEditDrugErrors: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professionals() {
      return this.$store.state.auth.professionals;
    },
    prescProtsFiltered() {
      return this.prescProts.filter(prescProt => {
        return prescProt.presc_prot_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  created() {
    this.loadPrescTypes();
    this.loadAdminDrugs();
  },
  mounted() {
    if (this.professionals.length > 0) {
      this.currentProfessionalId = this.$store.state.auth.user.id_prof ?? this.professionals[0].id_prof;
      this.loadPrescProts(this.currentProfessionalId);
    }
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadPrescProts(professionalId) {
      this.prescProts = [];
      this.drugsHide = true;
      this.currentProfessionalId = professionalId;
      http.get(`/professionals/${professionalId}/prescription-protocols`).then((response) => {
        this.prescProts = response.data;
      });
    },
    loadPrescTypes() {
      this.prescTypes = [];
      http.get(`/prescription-types`).then((response) => {
        this.prescTypes = response.data;
      });
    },
    loadAdminDrugs() {
      this.drugAdmins = [];
      http.get(`/drug-administrations`).then((response) => {
        this.drugAdmins = response.data;
      });
    },
    async onClickBtnEdit(prescProt) {
      this.loadDrugs(prescProt.id_presc_prot).then((response) => {
        this.currentPrescProt = prescProt;
        this.currentPrescProt.drugs = response.data;
      });
    },
    onClickBtnEditDrug(drug) {
      this.formEditDrug.id_presc_prot_drug = drug.id_presc_prot_drug;
      this.formEditDrug.id_presc_prot = drug.id_presc_prot;
      this.formEditDrug.id_presc_type = drug.id_presc_type;
      this.formEditDrug.id_drug_admin = drug.id_drug_admin;
      this.formEditDrug.drug_name = drug.drug_name;
      this.formEditDrug.dosage_desc = drug.dosage_desc;
    },
    submitFormStoreDrug() {
      this.formStoreDrug.id_prof = this.currentProfessionalId;
      this.formStoreDrug.id_presc_prot = this.currentPrescProtId;
      this.formStoreDrugErrors = [];
      http.post(`/professionals/${this.currentProfessionalId}/prescription-protocols/${this.currentPrescProtId}/drugs`, this.formStoreDrug).then(_ => {
        this.getDrugs(this.currentPrescProtId, false);
        Alert.toastSuccess("Medicamento cadastrado com sucesso.");
        this.formStoreDrug = {
          id_presc_prot: '',
          id_presc_type: '',
          id_drug_admin: '',
          drug_name: '',
          dosage_desc: '',
        };
        bootstrap.Modal.getInstance(document.getElementById('modal_store_drug')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formStoreDrugErrors = e.response.data.errors;
        }
      });
    },
    submitFormEditDrug() {
      this.formEditDrugErrors = [];
      http.put(`/professionals/${this.currentProfessionalId}/prescription-protocols/${this.currentPrescProtId}/drugs/${this.formEditDrug.id_presc_prot_drug}`, this.formEditDrug).then(() => {
        this.getDrugs(this.currentPrescProtId, false);
        Alert.toastSuccess("Medicamento atualizado com sucesso.");
        bootstrap.Modal.getInstance(document.getElementById('modal_edit_drug')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formEditDrugErrors = e.response.data.errors;
        }
      });
    },
    deletePrescProt(prescProtId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o protocolo de medicamento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.currentProfessionalId}/prescription-protocols/${prescProtId}`).then(_ => {
            this.loadPrescProts(this.currentProfessionalId);
            Alert.toastSuccess("Protocolo excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    deleteDrug(drugId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o medicamento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.currentProfessionalId}/prescription-protocols/${this.currentPrescProtId}/drugs/${drugId}`).then(_ => {
            this.getDrugs(this.currentPrescProtId, false);
            Alert.toastSuccess("Medicamento excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    async getDrugs(prescProtId, switchDrugsHide = true) {
      if (!this.drugsHide && switchDrugsHide && this.currentPrescProtId === prescProtId) {
        this.drugsHide = true;
        return;
      }
      this.loadDrugs(prescProtId).then((response) => {
        this.drugs = response.data;
        this.loading = false;
        this.drugsHide = false;
      });
      this.loading = switchDrugsHide ? true : this.loading;
      this.currentPrescProtId = prescProtId;
    },
    loadDrugs(prescProtId) {
      return http.get(`/professionals/${this.currentProfessionalId}/prescription-protocols/${prescProtId}/drugs`).then((response) => {
        return response;
      }).catch(e => {
        Alert.toastError(e.response.data);
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
</style>