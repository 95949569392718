<template>
  <div class="card" v-if="medicalRecords.length <= 0">
    <div class="card-body text-center">
      <div class="mt-10">
        <img class="w-50" :src="require('@/assets/images/undraw_collaborating_re_l43g.svg')" alt="image"/>
        <br><br>
        <p class="h3 mt-10 text-gray-600">
          Nenhum Histórico encontrado para o paciente <br> {{schedule?.patient?.patient_name}}
        </p>
      </div>
    </div>
  </div>

  <div class="card" v-else>
    <div class="card-header">
      <ul class="nav mt-5">
        <li class="nav-item" @click="search = ''">
          <a class="nav-link border-gray-300 border-dashed btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-bold fs-7 px-4 me-1 active"
             data-bs-toggle="tab" href="#" style="border: 1px">
            Todos
          </a>
        </li>
        <li class="nav-item" v-for="recordType in recordTypes" @click="search = recordType.id_rectype">
          <a class="nav-link border-gray-300 border-dashed btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-bold fs-7 px-4 me-1"
             data-bs-toggle="tab" href="#" style="border: 1px">
            {{recordType.rectype_name}}
          </a>
        </li>
      </ul>
      <div v-if="schedule?.id_sched === null" class="d-flex justify-content-end mt-4 mb-4">
        <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                title="Novo Registro" :data-bs-target="'#modal_store_medrec'">
          <i class="bi bi-plus-lg"></i> Novo Registro
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="timeline">
        <div :id="'timeline-item-' + medicalRecord.id_medrec" class="timeline-item" v-for="medicalRecord in medicalRecordFiltered">
          <div class="timeline-line w-40px"></div>
          <div class="timeline-icon symbol symbol-circle symbol-40px me-4">
            <div class="symbol-label bg-light">
          <span class="svg-icon svg-icon-2 svg-icon-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path opacity="0.3"
                    d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                    fill="currentColor"></path>
              <path
                  d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                  fill="currentColor"></path>
            </svg>
          </span>
            </div>
          </div>
          <div class="timeline-content mb-10 mt-n1">
            <div class="row">
              <div class="col-md-10 pe-3 mb-5">
                <div class="fs-5 fw-bold mb-2">
                  {{date(medicalRecord.medrec_date_time).format('DD/MM/YYYY')}} - {{medicalRecord.record_type.rectype_name}}
                  <span class="badge badge-light-success" v-if="medicalRecord.allowUpdate">Liberado para Alteração</span>
                  <span class="badge badge-light-danger" v-else>Alteração bloqueada</span>
                </div>
                <div class="d-flex align-items-center mt-1 fs-6">
                  <div v-if="medicalRecord.id_prof != medicalRecord.user.id_prof" class="text-muted me-2 fs-7">Adicionado em {{date(medicalRecord.created_at).format('DD/MM/YYYY HH:mm')}} em nome de {{medicalRecord.professional.prof_name}} por {{medicalRecord.user.user_name}}</div>
                  <div v-else class="text-muted me-2 fs-7">Adicionado em {{date(medicalRecord.created_at).format('DD/MM/YYYY HH:mm')}} por {{medicalRecord.professional.prof_name}}</div>
                  <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window"
                       data-bs-placement="top" :title="medicalRecord.professional.prof_name" :data-bs-original-title="medicalRecord.professional.prof_name">

                    <img :src="medicalRecord.professional.prof_photo" v-if="medicalRecord.professional.prof_photo" alt="Foto do profissional">
                    <img :src="require('@/assets/images/no-photo.png')" alt="image" v-else/>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div v-if="medicalRecord.allowDelete" class="d-flex justify-content-end mt-4 mb-4">
                  <button class="btn btn-light-primary me-3"
                          title="Excluir" @click="deleteMedrec(medicalRecord.id_medrec);">
                    <i class="bi bi-trash-fill"></i> Excluir
                  </button>
                </div>
              </div>

            </div>
            <ul v-if="medicalRecord.record_type.allow_prescription || medicalRecord.record_type.allow_exam_request" class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
              <li class="nav-item">
                <a class="nav-link text-active-primary pb-2 active" data-bs-toggle="tab"
                   :href="'#tab-register-' + medicalRecord.id_medrec">
                  Registro
                </a>
              </li>
              <li v-if="medicalRecord.record_type.allow_cid && medicalRecord?.id_sched" class="nav-item">
                <a class="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                   :href="'#tab-cid-' + medicalRecord.id_medrec">
                  CID
                </a>
              </li>
              <li v-if="medicalRecord.record_type.allow_prescription" class="nav-item">
                <a class="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                   :href="'#tab-prescription-' + medicalRecord.id_medrec">
                  Prescrição
                </a>
              </li>
              <li v-if="medicalRecord.record_type.allow_exam_request" class="nav-item">
                <a class="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                   :href="'#tab-exam-' + medicalRecord.id_medrec">
                  Exame
                </a>
              </li>
              <li v-if="medicalRecord.record_type.allow_document" class="nav-item">
                <a class="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                   :href="'#tab-document-' + medicalRecord.id_medrec">
                  Documentos
                </a>
              </li>
              <li  class="nav-item">
                <a class="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                   :href="'#tab-health-data-' + medicalRecord.id_medrec">
                  Dados de Saúde
                </a>
              </li>
              <li  class="nav-item">
                <a class="nav-link text-active-primary pb-2" data-bs-toggle="tab"
                   :href="'#tab-attachment-' + medicalRecord.id_medrec">
                  Anexos
                </a>
              </li>
            </ul>
            <div class="overflow-auto pb-5">
              <div class="d-flex align-items-center border border-dashed border-gray-300 rounded px-7 py-3 mb-5">
                <div class="tab-content w-100">
                  <div class="tab-pane fade show active" :id="'tab-register-' + medicalRecord.id_medrec" role="tabpanel">
                    <div class="row">
                      <InputAttendance v-for="attribute in medicalRecord.attributes"
                                       :key="'history-' + attribute.id_medrec_attr"
                                       :disabled="!medicalRecord.allowUpdate"
                                       @onChange="saveAttribute" :attribute="attribute"/>
                    </div>
                  </div>
                  <div class="tab-pane fade" :id="'tab-cid-' + medicalRecord.id_medrec" role="tabpanel">
                    <Cid :readonly="!medicalRecord.allowUpdate" :medical-record="medicalRecord" :schedule="schedule" :key="medicalRecord?.id_medrec"/>
                  </div>
                  <div class="tab-pane fade" :id="'tab-prescription-' + medicalRecord.id_medrec" role="tabpanel">
                    <Prescription :readonly="!medicalRecord.allowUpdate" :medical-record="medicalRecord" :schedule="schedule" :presc-types="prescTypes" :prescription-protocols="prescriptionProtocols" :drug-admins="drugsAdmins" :certifiers="certifiers" :key="medicalRecord?.id_medrec"/>
                  </div>
                  <div class="tab-pane fade" :id="'tab-exam-' + medicalRecord.id_medrec" role="tabpanel">
                    <ExamRequest :readonly="!medicalRecord.allowUpdate"  :medical-record="medicalRecord" :schedule="schedule" :certifiers="certifiers" :key="medicalRecord?.id_medrec"/>
                  </div>
                  <div class="tab-pane fade" :id="'tab-document-' + medicalRecord.id_medrec" role="tabpanel">
                    <Document :readonly="!medicalRecord.allowUpdate"  :medical-record="medicalRecord" :schedule="schedule" :document-templates="documentTemplates" :certifiers="certifiers" :key="medicalRecord?.id_medrec"/>
                  </div>
                  <div class="tab-pane fade" :id="'tab-health-data-' + medicalRecord.id_medrec" role="tabpanel">
                    <HealthData :readonly="!medicalRecord.allowUpdate"  :medical-record="medicalRecord" :schedule="schedule" :health-indicator-groups="healthIndicatorGroups" :key="medicalRecord?.id_medrec"/>
                  </div>
                  <div class="tab-pane fade" :id="'tab-attachment-' + medicalRecord.id_medrec" role="tabpanel">
                    <Attachment :readonly="!medicalRecord.allowUpdate"  :medical-record="medicalRecord" :schedule="schedule" :key="medicalRecord?.id_medrec"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

   <div class="modal fade" tabindex="-1" id="modal_store_medrec">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submitFormStore">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Novo Registro</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-6 mt-4">
                <label for="rectype_store" class="form-label required">Tipo de Registro</label>
                <select class="form-control form-select required" id="rectype_store"
                        aria-label="Selecione o tipo de registro" v-model="formStore.id_rectype">
                  <option value="" selected>--Nenhum--</option>
                  <option :value="recordType.id_rectype" v-for="recordType in recordTypes">
                    {{ recordType.rectype_name }}
                  </option>
                </select>
                <small class="text-danger" v-if="formStoreErrors.id_rectype">{{ formStoreErrors.id_rectype[0] }}</small>
              </div>
              <div class="col-md-12 mb-6 mt-4">
                <label for="medrec_date_time_store" class="required form-label required">Data para Registro</label>
                <input class="form-control form-control-solid"
                       placeholder="Data para Registro"
                       type="text"
                       :class="formStoreErrors.medrec_date_time ? 'is-invalid' : ''" v-mask="'##/##/####'"
                       v-model="formStore.medrec_date_time_fmt" id="medrec_date_time_store" required>
                <small class="text-danger" v-if="formStoreErrors.medrec_date_time">{{ formStoreErrors.medrec_date_time[0] }}</small>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Cadastrar</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import http from "../../../helpers/http";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import InputAttendance from '../shared/Input';
import QuillEditor from 'vue-quill-editor/src/editor'
import Prescription from "./Prescription";
import ExamRequest from "./ExamRequest";
import {mask} from 'vue-the-mask';
import Alert from "../../../helpers/alert";
import Document from "./Document";
import Cid from "./Cid";
import HealthData from "./HealthData";
import Attachment from "./Attachment";

export default {
  name: "History",
  components: {
    Document,
    ExamRequest,
    Prescription,
    QuillEditor,
    InputAttendance,
    Cid,
    HealthData,
    Attachment,
  },
  directives: {
    mask,
  },
  props: {
    schedule: Object,
    recordTypes: Object,
    examProtocols: Array,
    prescriptionProtocols: Array,
    prescTypes: Array,
    drugsAdmins: Array,
    documentTemplates: Array,
    healthIndicatorGroups: Array,
    certifiers: Array,
  },
  data() {
    return {
      search: '',
      medicalRecords: [],
      formStore: {
        id_rectype: '',
        id_prof: '',
        id_patient: '',
        id_place: '',
        medrec_date_time: '',
        medrec_date_time_fmt: this.date().format('DD/MM/YYYY'),
      },
      formStoreErrors: [],
    }
  },
  watch: {
    schedule(newSchedule, oldSchedule) {
      if (newSchedule?.availability == null)
        return;

      this.getHistoryMedicalRecord();
    }
  },
  computed: {
    medicalRecordFiltered() {
      if(this.search === '')
        return this.medicalRecords;

      return this.medicalRecords.filter(medicalRecord => {
        return medicalRecord.id_rectype === this.search;
      });
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
    place() {
      return this.$store.state.auth.place;
    }
  },
  mounted() {
    this.getHistoryMedicalRecord();
  },
  methods: {
    date: moment,
    getHistoryMedicalRecord() {
      if (this.schedule === null)
        return;

      return http.get(`/professionals/${this.schedule?.id_prof}/patients/${this.schedule.id_patient}/medical-records`, {params:{ current_schedule_id: this.schedule.id_sched}}).then((response) => {
        this.medicalRecords = response.data;
        return response;
      });
    },
    getRecordTypes() {
      if (!this.professionalId)
        return;

      http.get(`/professionals/${this.professionalId}/record-types/actives`).then((response) => {
        this.recordTypes = response.data;
      });
    },
    submitFormStore() {
      this.formStoreErrors = [];
      this.formStore.id_patient = this.schedule.id_patient;
      this.formStore.id_prof = this.schedule.id_prof;
      this.formStore.id_place = this.place.id_place;
      this.formStore.medrec_date_time = moment(this.formStore.medrec_date_time_fmt, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss');

      http.post(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records`, this.formStore).then((response) => {
        bootstrap.Modal.getInstance(document.getElementById('modal_store_medrec')).toggle();
        Alert.toastSuccess("Registro cadastrado com sucesso.");
        this.formStore = {
          id_rectype: '',
          id_prof: '',
          id_patient: '',
          medrec_date_time: '',
        };
        this.getHistoryMedicalRecord().then( () => {
          setTimeout( () => {
            const element = document.getElementById(`timeline-item-${response.data.id_medrec}`);
            if (element!=null)
              element.scrollIntoView();
          }, 500);
        });
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    saveAttribute(attribute) {
      let url = `/professionals/${this.schedule?.id_prof}/patients/${this.schedule?.id_patient}/medical-records/${attribute.id_medrec}/attributes/${attribute.id_medrec_attr}`;
      http.put(url, {'text': attribute.text, 'text_compl': attribute.text_compl}).then((_) => {
        console.log("Attributo salvo.");
      }).catch((_) => {
        Alert.toastError("Erro ao salvar informação.");
      });
    },
    deleteMedrec(medrecId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o registro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${medrecId}`).then(_ => {
            this.getHistoryMedicalRecord();
            Alert.toastSuccess("Registro excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
  }
}
</script>

<style scoped>

</style>