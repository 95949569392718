<template>
    <Toolbar />
    <div class="container-xxl">
        <div class="row">
        <div class="col-md-12" v-for="place in places">
          <div class="card mt-2">
            <div class="card-header">
              <div class="card-title">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <img class="mw-65px mw-lg-65px me-4" :src="place?.place_logo ?? require('@/assets/images/no-photo.png')" alt="image">
                    <span>{{ place.place_name }}</span>
                  </div>
                  <div class="mt-2">
                    <i class="bi bi-geo-alt-fill me-2" ></i>
                    <span class="badge badge-success mb-0 text-truncate" style="max-width: 220px;">
                      {{ place.place_address }},
                      {{ place.place_addr_numer }} -
                      {{ place.place_neighborhood }} -
                      {{ place.city_name }}/{{ place.state_initials }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-toolbar">
                <button class="btn btn-sm btn-light-primary" @click="selectPlace(place)">
                  Selecionar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Places",
    components: {
        Toolbar,
    },
  data() {
    return {
      places: [],
    }
  },
  created() {
    this.getPlaces();
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async selectPlace(place) {
      this.$store.commit('auth/setPlace', place);
      let responseProfessionals = this.$store.dispatch('auth/loadProfessionals');
      let responsePermissions = this.$store.dispatch('auth/loadPermissions');
      await Promise.all([responseProfessionals, responsePermissions]);

      this.$router.push('/dashboard');
    },
    getPlaces() {
      http.get(`/users/${this.userAuth.id_user}/places`).then((res) => {
        this.places = res.data;
      });
    }
  }
}
</script>

<style scoped>

</style>