import http from "../helpers/http";
import db from "../helpers/db";

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {

    },
    getters: {

    },
}
