<template>
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">

      <div id="kt_aside" class="aside pb-5 pt-5 pt-lg-0" data-kt-drawer="true" data-kt-drawer-name="aside"
           data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
           data-kt-drawer-width="{default:'80px', '300px': '100px'}" data-kt-drawer-direction="start"
           data-kt-drawer-toggle="#kt_aside_mobile_toggle">
        <div class="aside-logo py-8" id="kt_aside_logo">
          <a href="#" class="d-flex align-items-center">
            <img alt="Logo" :src="require('@/assets/images/logo_ubbi_white.png')" class="h-60px logo"/>
          </a>
        </div>
        <div class="aside-menu flex-column-fluid" id="kt_aside_menu">

          <div class="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1" id="kt_aside_menu_wrapper" data-kt-scroll="true"
               data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
               data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu" data-kt-scroll-offset="5px">

            <Menu />
          </div>

        </div>
      </div>

      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

        <Header />

        <div class="content d-flex flex-column flex-column-fluid" id="kt_content" style="padding: 0">
          <div id="kt_content_container">
            <router-view />
          </div>
        </div>
        <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
          <div class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div class="text-dark order-2 order-md-1">
              <span class="text-muted fw-bold me-1">{{ new Date().getFullYear() }}©</span>
              <a href="#" target="_blank" class="text-gray-800 text-hover-primary">Ubbimed</a>
            </div>
            <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
              <li class="menu-item">
                <a href="#" target="_blank" class="menu-link px-2">Início</a>
              </li>
              <li class="menu-item">
                <a href="#" target="_blank" class="menu-link px-2">Sobre</a>
              </li>
              <li class="menu-item">
                <a href="#" target="_blank" class="menu-link px-2">Suporte</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import Menu from "./Menu";

export default {
  name: "Professional",
  components: {
    Header,
    Menu,
  },
}
</script>

<style>

</style>
