<template>
  <table class="table table-bordered table-hover table-light gy-3">
    <thead>
    <tr class="fw-bold fs-6 text-muted">
      <th style="text-align: center">#</th>
      <th class="text-center">TUSS</th>
      <th>Exame</th>
      <th v-if="!readonly" class="text-center">Açoes</th>
    </tr>
    </thead>
    <tr v-for="(examRequestItem, index) in examRequestItems" :key="examRequestItem.id_exam" >
      <td style="text-align: center">{{ index + 1 }}</td>
      <td class="text-center">{{ examRequestItem.exam_tuss_code}}</td>
      <td>{{ examRequestItem.exam_name }}</td>
      <td v-if="!readonly && !examRequest.exam_request_generated" class="text-center">
        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                @click="deleteExamRequestItem(examRequestItem.id_exam)"
                data-bs-toggle="tooltip" title="Excluir Exame">
          <i class="bi bi-trash"></i>
        </button>
      </td>
    </tr>
  </table>

  <SearchExam :id-modal="examRequest.id_exam_request" @onSubmitFormStoreExam="submitFormStoreExam"></SearchExam>
</template>

<script>
import http from "../../../helpers/http";
import Alert from "../../../helpers/alert";
import SearchExam from "../../../components/SearchExam";

export default {
  name: "ExamRequestItems",
  components: {SearchExam},
  props: {
    examRequest: Object,
    schedule: Object,
    readonly: false,
  },
  data() {
    return {
      examRequestItems: [],
    }
  },
  mounted() {
    this.examRequestItems = this.examRequest.exams;
  },
  methods: {
    getExamRequestItems() {
      http.get(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.examRequest.id_medrec}/exam-requests/${this.examRequest.id_exam_request}/exams`).then((response) => {
        this.examRequestItems = response.data;
      });
    },
    deleteExamRequestItem(examId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o exame?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.examRequest.id_medrec}/exam-requests/${this.examRequest.id_exam_request}/exams/${examId}`).then(_ => {
            this.getExamRequestItems();
            Alert.toastSuccess("Exame excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    submitFormStoreExam(formStoreExam, formStoreExamErrors) {
      formStoreExam.id_exam_request = this.examRequest.id_exam_request;
      http.post(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.examRequest.id_medrec}/exam-requests/${this.examRequest.id_exam_request}/exams`, formStoreExam).then(_ => {
        this.getExamRequestItems();
        Alert.toastSuccess("Exame cadastrado com sucesso.");
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
      });
    }
  }
}
</script>

<style scoped>

  ul li:hover {
    cursor: pointer;
  }

</style>