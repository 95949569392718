import { createRouter, createWebHistory } from 'vue-router';
import db from "../helpers/db";
import store from "../store";
import auth from './auth';
import professional from './professional';

const routes = [
  ...auth,
  ...professional,
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {

  if (to.path === '/dashboard' && store.state.auth.place == null) {
    next({path: '/places'});
    return;
  }

  if (to.meta.auth) {
    if (db.token == null || db.token === 'null') {
      next({path: '/login', params: {nextUrl: to.fullPath}});
      return;
    }
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
    document.title = to.meta.title ?? 'Ubbimed';
  }, 100);

  next();
});

export default router
