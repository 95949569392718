<template>
    <button
        v-if="!scheduleEdit?.sched_online && (scheduleEdit?.sched_arrive || scheduleEdit?.is_sched_finished) && date(scheduleEdit?.schedule_date_time).isSame(date(), 'day')"
        @click.stop="scheduleEdit?.is_sched_finished ? reopenAttendance(scheduleEdit) : finishAttendance(scheduleEdit);"
        type="button"
        class="btn ms-2"
        :title="scheduleEdit?.is_sched_finished ? 'Reabrir atendimento' : 'Finalizar atendimento'"
        :class="classes">
        {{btnText ?? ''}}
    </button>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";

export default {
    name: "BtnFinishOrReOpenSchedule",
    props: ['scheduleEdit', 'classes', 'btnText'],
    computed: {
        place() {
            return this.$store.state.auth.place;
        },
        userAuth() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        date: moment,
        reopenAttendance(schedule) {
            http.patch(`/places/${this.place.id_place}/professionals/${this.userAuth.id_prof}/attendance/schedules/${schedule.id_sched}/reopen`).then(() => {
                this.$emit('onReopenSchedule', schedule.id_sched);
                Alert.toastSuccess('Atendimento reaberto com sucesso!')
            }).catch((e) => {
                Alert.toastError(e.response.data);
            });
        },
        finishAttendance(schedule) {
            http.patch(`/places/${this.place.id_place}/professionals/${this.userAuth.id_prof}/attendance/schedules/${schedule.id_sched}/finish`).then(() => {
                this.$emit('onFinishSchedule', schedule.id_sched);
                Alert.toastSuccess('Atendimento finalizado com sucesso!')
            }).catch((e) => {
                Alert.toastError(e.response.data);
            });
        },
    }
}
</script>

<style scoped>

</style>