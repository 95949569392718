export default class OS {
    static getOS() {
        let Name = "Unknown OS";
        if (navigator.userAgent.indexOf("Win") != -1) Name = "Windows";
        if (navigator.userAgent.indexOf("Mac") != -1) Name = "Mac";
        if (navigator.userAgent.indexOf("Linux") != -1) Name = "Linux";
        if (navigator.userAgent.indexOf("Android") != -1) Name = "Android";
        if (navigator.userAgent.indexOf("like Mac") != -1) Name = "iOS";

        return Name;
    }
}