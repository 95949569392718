<template>
  <div class="d-flex flex-column align-items-center justify-content-center text-center" style="min-height: 100px;">
    <h5>Informe o código de recuperação recebido em {{ obfuscated_phone }}</h5>
  </div>
  <div class="d-flex justify-content-center">
    <div v-for="(code, index) in recoveryCodes" :key="index" class="recovery-code-item mx-1">
      <input
        type="tel"
        class="form-control text-center"
        v-model="recoveryCodes[index]"
        maxlength="1"
        @keydown="handleKeydown($event, index)"
        @paste="handlePaste($event)"
        :ref="'code-' + index"
      />
    </div>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <button class="btn btn-primary" @click="validateCode">Validar</button>
  </div>
</template>

<script>
export default {
  emits: ['code-valid'],
  data() {
    return {
      recoveryCodes: Array(6).fill(""),
    };
  },
  props: {
    obfuscated_phone: {
      type: String,
      required: true,
    },
  },
  methods: {
    focusNext(index) {
      if (index === 5)
        return this.validateCode();

      let ref = this.$refs[`code-${index + 1}`];
      if (Array.isArray(ref) && ref.length > 0) {
        ref[0].focus();
      }
    },
    handleKeydown(event, index) {
      setTimeout(() => {
        if (event.key === 'Backspace') {
          if (this.recoveryCodes[index] !== "") {
            return;
          }

          let ref = this.$refs[`code-${index-1}`];
          if (Array.isArray(ref) && ref.length > 0) {
            ref[0].focus();
          } else if (ref) {
            ref.focus();
          }
        } else {
          if (this.recoveryCodes[index] !== "") {
            this.focusNext(index)
          }
        }
      }, 200);
    },
    handlePaste(event) {
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      if (/^\d{6}$/.test(paste)) {
        for (let i = 0; i < this.recoveryCodes.length; i++) {
          this.recoveryCodes[i] = paste[i];
        }

        let ref = this.$refs[`code-5`];
        if (Array.isArray(ref) && ref.length > 0) {
          ref[0].focus();
        } else if (ref) {
          ref.focus();
        }
        return this.validateCode();
      }
    },
    validateCode() {
      const code = this.recoveryCodes.join("");
      if (code.length === 6) {
        this.$emit('code-valid', code);
      }
    },
  },
};
</script>

<style scoped>
.recovery-code-item input {
  width: 50px;
  height: 50px;
  font-size: 24px;
}
</style>