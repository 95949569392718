<template>
  <div :id="componentId"
       class="d-flex align-items-center"
       data-kt-search-keypress="true"
       data-kt-search-min-length="3"
       data-kt-search-enter="enter"
       data-kt-search-layout="menu"
       data-kt-search-responsive="null"
       data-kt-menu-trigger="auto"
       data-kt-menu-permanent="true"
       data-kt-menu-placement="bottom-start">

    <form data-kt-search-element="form" class="d-block w-100 position-relative mb-5 mb-lg-0" autocomplete="off">

      <input type="hidden"/>

      <input type="text" class="form-control form-control-solid"
             v-model="search" @input="updateValue"
             :disabled="disabled"
             placeholder="Pesquisar Paciente ..."
             data-kt-search-element="input"/>

      <span class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
          <span class="spinner-border h-15px w-15px align-middle text-gray-400"></span>
      </span>

      <span class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none"
            data-kt-search-element="clear">
      </span>
    </form>

    <div data-kt-search-element="content" class="menu menu-sub menu-sub-dropdown w-300px w-md-400px py-7 px-7 overflow-hidden">
      <div data-kt-search-element="wrapper">
        <div data-kt-search-element="suggestions"></div>

        <div data-kt-search-element="results" class="d-none">
          <div class="mh-300px scroll-y me-n5 pe-5">

            <div class="d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1"
                 data-kt-search-element="customer" v-for="patient in patients" @click="onClickPatient(patient)">

              <div class="symbol symbol-35px symbol-circle me-5">
                <img :src="url(patient?.patient_photo)" v-if="patient?.patient_photo" :alt="patient?.patient_name">
                <img :src="require('@/assets/images/no-photo.png')" :alt="patient?.patient_name" v-else/>
              </div>

              <div class="fw-bold">
                <span class="fs-6 text-gray-800 me-2">{{patient?.patient_name}}</span>
                <span class="badge badge-light">{{patient?.patient_cpf}}</span>
              </div>
            </div>

          </div>
        </div>

        <div data-kt-search-element="empty" class="text-center d-none">
          <div class="fw-bold py-0 mb-10">
            <div class="text-gray-600 fs-3 mb-2">Nenhum paciente encontrado</div>
            <div class="text-gray-400 fs-6">Caso seja a primeira consulta informe todos os dados do paciente.</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import http from "../helpers/http";
import str from "../helpers/str";
import debounce from "../helpers/debounce";

export default {
  name: "SearchPatient",
  props: ['professionalId', 'value', 'disabled'],
  data() {
    return {
      patients: [],
      search: this.value,
      componentId: 'kt_docs_search_handler_basic' + Date.now(),
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    setTimeout(() => {
      this.startInput();
    }, 200)
  },
  methods: {
      url(path) {
          return str.url(path)
      },
    updateValue() {
      this.$emit('onInput', {patient_name: this.search})
    },
    onClickPatient(patient) {
      this.search = patient.patient_name;
      this.$emit('onClick', patient)
    },
    searchPatients() {
      this.patients = [];
      return http.get(`/places/${this.place.id_place}/patients`, {params: {search: this.search}}).then((response) => {
        this.patients = response.data.data;
        return response;
      });
    },
    startInput() {
      let processs = (search) => {
        this.searchPatients().then(() => {
          if (this.patients.length <= 0) {
            resultsElement.classList.add("d-none");
            emptyElement.classList.remove("d-none");
          } else {
            resultsElement.classList.remove("d-none");
            emptyElement.classList.add("d-none");
          }

          search.complete();
        });
      }

      let clear = function(search) {
        suggestionsElement.classList.remove("d-none");
        resultsElement.classList.add("d-none");
        emptyElement.classList.add("d-none");
      }

      const handleInput = () => {
        const inputField = element.querySelector("[data-kt-search-element='input']");
        inputField.addEventListener("keydown", e => {
          if(e.key === "Enter"){
            e.preventDefault();
          }
        });
      }

      this.search = this.value;
      if (this.disabled)
          return;

      let element = document.querySelector('#'+this.componentId);
      if (!element)
        return;

      let suggestionsElement = element.querySelector("[data-kt-search-element='suggestions']");
      let resultsElement = element.querySelector("[data-kt-search-element='results']");
      let emptyElement = element.querySelector("[data-kt-search-element='empty']");
      let searchObject = new KTSearch(element);

      const debouncedProcesss = debounce(processs, 500);
      searchObject.on("kt.search.process", debouncedProcesss);
      searchObject.on("kt.search.clear", clear);

      KTUtil.on(element, "[data-kt-search-element='customer']", "click", () => {
        searchObject.hide();
      });

      KTUtil.on(element, "[data-kt-search-element='input']", "blur", () => {
        searchObject.hide();
      });

      handleInput();
    }
  }
}
</script>

<style scoped>

</style>