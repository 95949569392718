<template>
    <div class="modal fade" id="kt_modal_cancel" tabindex="-1" aria-hidden="true"
         data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form class="form" @submit.prevent="cancelBatch">
                    <div class="modal-header">
                        <h2 class="fw-bolder" data-kt-calendar="title">
                            Cancelamento em lote
                        </h2>
                        <div @click="$emit('onCloseModal')"
                             class="btn btn-icon btn-sm btn-active-icon-primary"
                             data-bs-dismiss="modal" aria-label="Fechar">
                          <span class="svg-icon svg-icon-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                                      fill="black"/>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"/>
                              </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body py-10 px-lg-17">
                        <div class="row py-5">
                            <div class="col-md-12">
                                <label class="fs-6 fw-bold mb-2 required" for="cancel_schedules_id_prof">Profissional</label>
                                <DropdownUbbi
                                    :options="professionals"
                                    :filter="true"
                                    appendTo="self"
                                    v-model="currentProfessionalId"
                                    optionLabel="prof_name"
                                    optionValue="id_prof"
                                    id="cancel_schedules_id_prof"
                                    placeholder="Selecione um profissional"
                                />
                                <br>
                            </div>
                            <div class="col-md-12">
                                <div class="flex flex-wrap gap-3 p-fluid">
                                    <div class="flex-auto">
                                        <label class="fs-6 fw-bold mb-2 required">Intervalos</label>
                                        <Calendar v-model="form.date_range" inline selectionMode="range"
                                                  :manualInput="false"
                                                  dateFormat="yy/mm/dd" :minDate="new Date()" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="">
                                Atenção: Será enviado uma mensagem via WhatsApp para todos os pacientes informando sobre o cancelamento
                                e um link para que o mesmo possa realizar o reagendamento da consulta.
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer flex-center">
                        <button type="submit" class="btn btn-primary">
                            <span class="indicator-label">Cancelar</span>
                            <span class="indicator-progress">Por favor aguarde...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import {mask} from "vue-the-mask";
import Calendar from 'primevue/calendar';
import DropdownUbbi from "../../components/DropdownUbbi";

export default {
    name: "ModalCancelSchedules",
    props: ['idProf', 'openModal'],
    components: {
        DropdownUbbi,
        Calendar,
    },
    directives: {
        mask
    },
    data: () => ({
        form: {
            date_range: '',
        },
        currentProfessionalId: null,
    }),
    computed: {
        place() {
            return this.$store.state.auth.place;
        },
        professionals() {
            return this.$store.state.auth.professionals;
        },
    },
    mounted() {
        this.currentProfessionalId = this.idProf;
    },
    watch: {
        openModal(newValue) {
            if (newValue) {
                this.resetForm();
                this.toggleModal();
            }
        },
        idProf(newValue) {
            this.currentProfessionalId = newValue;
        }
    },
    methods: {
        resetForm() {
          this.form = {
              date_range: '',
          };
        },
        cancelBatch() {
          Swal.fire({
            title: 'Heeyy',
            text: "Tem certeza que deseja cancelar os agendamentos?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, cancelar!',
            cancelButtonText: 'Fechar',
            customClass: {
              confirmButton: "btn btn-danger",
              cancelButton: 'btn btn-secundary'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              let form = {
                date_start: this.form.date_range[0],
                date_end: this.form.date_range[1] ?? this.form.date_range[0] ,
              };
              http.patch(`/places/${this.place.id_place}/professionals/${this.currentProfessionalId}/schedules/cancel-batch`, form).then(() => {
                Alert.toastSuccess("Agendamentos cancelados com sucesso.");
                this.$emit('onCancel');

                this.$emit('onCloseModal');
                this.toggleModal();
              }).catch((e) => {
                Alert.toastError(e.response.data);
              });
            }
          });
        },
        toggleModal() {
            let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#kt_modal_cancel'));
            modal.toggle();
        }
    }
}
</script>

<style>
.p-calendar .p-inputtext {
    width: 100% !important;
}
</style>