<template>

  <div class="card">
    <div v-if="!readonly" class="card-toolbar">
      <div class="d-flex justify-content-start">
        <button v-if="!readonly" class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                title="Novo Protocolo" :data-bs-target="'#modal_store_exam_request_' + medicalRecord.id_medrec">
          <i class="bi bi-plus-lg"></i> Novo Pedido
        </button>
      </div>
    </div>

    <div class="card mt-2 mb-2" v-for="examRequest in examRequests" :key="examRequest.id_exam_request">
      <div class="card-header collapsible cursor-pointer rotate pt-2 border-dark"
           data-bs-toggle="collapse" :data-bs-target="'#kt_exam_request_card_collapsible_' + examRequest.id_exam_request">
        <div class="card-title">
          <h2>#{{examRequest.id_exam_request}} {{examRequest?.exam_request_name}}</h2>
          &nbsp&nbsp
          <i :title="examRequest.exam_request_generated?'Alteração bloqueada - PDF já gerado':'Liberado para alteração'" :class="examRequest.exam_request_generated?'bi bi-lock-fill fa-lg fa-border':'bi bi-unlock-fill fa-lg fa-border'"></i>
          <i v-if="examRequest.exam_request_generated" title="PDF gerado" class="bi bi-file-pdf-fill fa-lg fa-border"></i>
          <i v-if="examRequest.exam_request_signed" title="Assinado" class="bi bi-pen-fill fa-lg fa-border" ></i>
        </div>
        <div class="card-toolbar rotate-180">
        <span class="svg-icon svg-icon-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect>
            <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="currentColor"></path>
          </svg>
        </span>
        </div>
      </div>
      <div :id="'kt_exam_request_card_collapsible_' + examRequest.id_exam_request" class="collapse show">
        <div class="card-toolbar">
          <div class="d-flex justify-content-start mt-2">
            <button v-if="!readonly && examRequest.exam_request_generated"  class="btn btn-light-primary me-3"
                    title="Reabrir para Alteração" @click="reopenExamRequest(examRequest.id_exam_request)">
              <i class="bi bi-unlock-fill"></i> Reabrir
            </button>
            <button v-if="!readonly && !examRequest.exam_request_generated" class="btn btn-light-primary me-3" data-bs-toggle="modal"
                    title="Adicionar Exame"
                    :data-bs-target="'#modal_store_exam_' + examRequest.id_exam_request"
                    @click="currentExamRequestId = examRequest.id_exam_request">
              <i class="bi bi-plus-lg"></i> Adicionar Exame
            </button>
            <button class="btn btn-light-primary me-3"
                    title="Imprimir" @click="showSelectCertifier(examRequest)">
              <i class="bi bi-printer"></i> Imprimir
            </button>
            <button v-if="!readonly" class="btn btn-light-primary me-3"
                    title="Excluir Pedido" @click="deleteExamRequest(examRequest.id_exam_request)">
              <i class="bi bi-trash"></i> Excluir Pedido
            </button>
          </div>
        </div>
        <div class="card-body">
          <ExamRequestItems :schedule="schedule" :examRequest="examRequest" :key="examRequest.id_exam_request" :readonly="readonly"></ExamRequestItems>
        </div>
      </div>

      <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
           :id="'modal_print_exam_' + examRequest.id_exam_request" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" v-if="messagesGenerateExam.showBtnClose">
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                   data-bs-dismiss="modal" aria-label="Fechar">
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mb-6 mt-4">
                <div class="text-center px-5" :key="messagesGenerateExam.image">
                  <video width="200" height="200" :loop="messagesGenerateExam.loop"  autoplay playsinline muted v-if="messagesGenerateExam.image.includes('.mp4')">
                    <source :src="messagesGenerateExam.image" type="video/mp4" />
                  </video>
                  <img v-else-if="messagesGenerateExam.image" :src="messagesGenerateExam.image" alt="Imagem" class="mw-100 h-200px h-sm-325px">
                  <p v-html="messagesGenerateExam.message"></p>
                </div>
              </div>
            </div>
            <div class="modal-footer" v-if="messagesGenerateExam.showBtnClose">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" :id="'modal_store_exam_request_' + medicalRecord.id_medrec">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submitFormStore">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Novo Pedido de Exame</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="col-md-12 mb-6 mt-4">
              <label for="type_store" class="form-label">Protocolo</label>
              <select class="form-control form-select" id="type_store"
                      aria-label="Selecione o protocolo" v-model="formStore.id_exam_prot" @change="formStore.exam_request_name=$event.target.selectedIndex===0?'':examProtocols[$event.target.selectedIndex-1].exam_prot_name">
                <option value="" selected>--Não usar protocolo--</option>
                <option :value="examProtocol.id_exam_prot" v-for="examProtocol in examProtocols">
                  {{ examProtocol.exam_prot_name }}
                </option>
              </select>
              <small class="text-danger" v-if="formStoreErrors.id_exam_prot">{{ formStoreErrors.id_exam_prot[0] }}</small>
            </div>
            <div class="col-md-12 mb-6 mt-4">
              <label for="exam_request_name_store" class="required form-label">Descrição</label>
              <input class="form-control form-control-solid"
                     v-model="formStore.exam_request_name" id="exam_request_name_store" autofocus required>
              <small class="text-danger" v-if="formStoreErrors.exam_request_name">{{ formStoreErrors.exam_request_name[0] }}</small>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Cadastrar</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <PdfViewer :id="'modal_pdf_viewer_exam_' + this.medicalRecord.id_medrec"
             :pdf-source="this.pdfSource"
             :file-name="this.fileName"
             :signed="this.signed"
             :key="'Exam:'+medicalRecord.id_medrec"/>

  <SelectCertifier :id="'modal_certifier_exam'  + this.medicalRecord.id_medrec"
                   :certifiers="this.certifiers"
                   :key="'ExamCertifier:'+medicalRecord.id_medrec"
                   @onSelect="printExams"/>

</template>

<script>
import http from "../../../helpers/http";
import Alert from "../../../helpers/alert";
import OS from "../../../helpers/os";
import Base64 from "../../../helpers/base64";
import ExamRequestItems from "./ExamRequestItems";
import PdfViewer from "../shared/PdfViewer";
import Config from "../../../config";
import SelectCertifier from "../../../components/SelectCertificator";

export default {
  name: "ExamRequest",
  components: {
    ExamRequestItems,
    PdfViewer,
    SelectCertifier,
  },
  props: {
    schedule: Object,
    medicalRecord: Object,
    examProtocols: Array,
    readonly: Boolean,
    certifiers: Array,
  },
  data() {
    return {
      currentExamRequestId: '',
      examRequests: [],
      formStore: {
        id_medrec: '',
        exam_request_name: '',
        id_exam_prot: '',
      },
      formStoreErrors: [],
      messagesGenerateExam: {
        message: '',
        image: '',
        showBtnClose: false,
      },
      pdfSource: '',
      fileName: '',
      signed: false,
      selectedExamRequest: '',
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    this.examRequests = this.medicalRecord.exam_requests;
  },
  methods: {
    showSelectCertifier(examRequest) {

      this.selectedExamRequest = examRequest;

      if (!examRequest.exam_request_signed) {
        let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_certifier_exam'  + this.medicalRecord.id_medrec));
        modal.toggle();
      } else {
        this.printExams(null);
      }
    },
    printExams(certifier) {

      let examRequest = this.selectedExamRequest;

      let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_print_exam_' + examRequest.id_exam_request));
      modal.toggle();

      this.messagesGenerateExam = {
        message: 'Gerando exame, por favor, aguarde...',
        image: require('@/assets/mp4/8558-floating-document.mp4'),
        showBtnClose: false,
      }

      http.post(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/exam-requests/${examRequest.id_exam_request}/print`).then(async (response) => {
        await new Promise(r => setTimeout(r, 1000));

        this.getExamRequests();

        this.messagesGenerateExam = {
          message: 'Baixando pedido de exame ...',
          image: require('@/assets/mp4/97795-download-green.mp4'),
          showBtnClose: false,
        }

        let data = response.data;

        await new Promise(r => setTimeout(r, 1200));

        if (data.exam_request.exam_request_generated && (data.exam_request.exam_request_signed || certifier?.id ===  null || certifier?.id === "none")) {
          return this.showPdfViewer( `data:application/pdf;base64,${data.file_base64}`, data.authenticator.file.file_name, data.exam_request.exam_request_signed, modal);
        }

        if (certifier.id === "vidaas") {
          await this.signCloud(examRequest, certifier, modal,  data.authenticator.file.file_name);
        }

        if (certifier.id === "token") {
          modal.toggle();
          await this.signToken(data, examRequest, modal);
        }
      }).catch((e) => {
        this.messagesGenerateExam = {
          message: e.response.data,
          image: require('@/assets/mp4/4386-connection-error.mp4'),
          showBtnClose: true,
        }
      });
    },
    async signCloud(examRequest, certifier, modal, filename) {

      this.messagesGenerateExam = {
        message: 'Assinando digitalmente - VERIFIQUE SEU CEULAR PARA LIBERAR...',
        image: require('@/assets/mp4/signing.mp4'),
        showBtnClose: false,
        loop: true,
      };

      await http.post(`${certifier.url}/exam/${examRequest.id_exam_request}`).then(async (response) => {
        let data = response.data;
        this.getExamRequests();
        this.showPdfViewer(`data:application/pdf;base64,${data.base64_signed}`, filename, true, modal);
      }).catch((e) => {
        modal.toggle();
        Alert.toastError('Erro ao assinar documento');
        this.messagesGenerateExam = {
          message: e.response.data,
          image: require('@/assets/mp4/4386-connection-error.mp4'),
          showBtnClose: true,
        }
      });
    },
    async signToken(fileToBeSign, examRequest, modal) {
      if (!window.WebSocket) {
        Alert.toastError('Seu navegador não tem suporte a conexão websocket.');
        return;
      }

      let certificatePassword = sessionStorage.getItem('certificate_password');
      if (!certificatePassword) {

        const { value: password } = await Swal.fire({
          title: 'Senha do certificado',
          input: 'text',
          inputLabel: 'Informe a senha',
          confirmButtonText: 'Assinar',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#780f5a',
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return 'Informe a senha do certificado digital!'
            }
          }
        });

        if (password)
          certificatePassword = Base64.encode(password);

        if (!certificatePassword)
          return;
      }

      modal.toggle();

      this.messagesGenerateExam = {
        message: 'Assinando digitalmente ...',
        image: require('@/assets/mp4/signing.mp4'),
        showBtnClose: false,
        loop: true,
      };

      let certificate = null;

      if (OS.getOS() === 'Windows') {
        let wsCertificates = new WebSocket(Config.protocol_ws + '://localhost:8044/certificates');
        wsCertificates.onopen = () => {
          wsCertificates.send('1');
        }

        wsCertificates.onmessage = async (event) => {
          let certificatesRaw = JSON.parse(event.data);
          wsCertificates.close(1000);

          if (certificatesRaw.length <= 0) {
            this.messagesGenerateExam.showBtnClose = true;
            Alert.toastError('Nenhum certificado encontrado.');
            return;
          }

          let certificates = certificatesRaw.sort((certificateOne, certificateTwo) => {
            let expiryDateOne = new Date(certificateOne.expiryDate.toUpperCase().replace("BRT", "GMT-0300"));
            let expiryDateTwo = new Date(certificateTwo.expiryDate.toUpperCase().replace("BRT", "GMT-0300"));

            return expiryDateTwo - expiryDateOne;
          });

          if (certificates.length === 1) {
            certificate = certificates.alias;
            return;
          }

          let alias = certificates.map(a => a.alias);

          const { value: aliasSelected } = await Swal.fire({
            title: 'Certificados instalados',
            input: 'select',
            inputOptions: {
              alias
            },
            inputPlaceholder: 'Selecione um certificado',
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value !== null) {
                  resolve();
                } else {
                  resolve('Selecione um certificado.');
                }
              });
            }
          });

          certificate = aliasSelected;
        }

        wsCertificates.onerror = () => {
          this.messagesGenerateExam.showBtnClose = true;
          Swal.fire({
            title: "Opsss",
            text: "Não conseguimos identificar o Assinador Ubbimed instalado.",
            icon: "warning",
            confirmButtonText: 'OK',
            confirmButtonColor: '#780f5a',
          }).then(() => {
            window.open('/assinador/download')
          });
        }
      }

      let connectedPdfSign = false;
      let wsSignConnection = new WebSocket(Config.protocol_ws + '://localhost:8044/pdf/sign');
      wsSignConnection.onopen =  () => {
        connectedPdfSign = true;
        let dataToBeSign = {
          certificate_pfx_as_base64: null,
          certificate_password: Base64.decode(passwordCertificate),
          reason: 'Assinatua de documentos Ubbimed.',
          location: this.place.place_name,
          contact: `${this.userAuth.user_name} <${this.userAuth.user_email}>`,
          certificate_alias: certificate,
          file_id: examRequest.id_exam_request,
          file_as_base64: fileToBeSign.file_base64
        };

        wsSignConnection.send(JSON.stringify(dataToBeSign));
      }

      wsSignConnection.onmessage = (message) => {
        let responseSignature = JSON.parse(message.data);
        const file = Base64.str2Blob(responseSignature.file_signed_as_base64)
        const formData = new FormData();
        formData.append('file', file, 'file.pdf')

        let url = `/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/exam-requests/${examRequest.id_exam_request}/upload-signed`;
        http.post(url, formData).then((response) => {
          Alert.toastSuccess('Assinatura realizada com sucesso.')

          this.showPdfViewer(`data:application/pdf;base64,${data.file_base64}`,  data.authenticator.file.file_name, true, modal);

          if (!sessionStorage.getItem('certificate_password')) {
            Swal.fire({
              text: 'Quer salvar a senha para nas próximas assinaturas?',
              icon: 'success',
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: 'Sim, salvar senha',
            }).then(async (result) => {
              if (result.isConfirmed) {
                sessionStorage.setItem('certificate_password', passwordCertificate);
              }
            });
          }
        });

        wsSignConnection.close(1000);
      }

      wsSignConnection.onerror = (error) => {
        Swal.close();
        if (connectedPdfSign) {
          this.messagesGenerateExam = {
            message: 'Ocorreu um erro inesperado com o assinador Ubbimed. (' + error.code + ')',
            image: require('@/assets/mp4/4386-connection-error.mp4'),
            showBtnClose: true,
            loop: true,
          }
        } else {
          this.messagesGenerateExam = {
            message: 'Não foi possível estabelecer uma conexão com o assinador. ' +
                '<br><br>Verifique se o assinador Ubbimed está instalado ou se o mesmo está em execução na sua máquina.',
            image: require('@/assets/mp4/4386-connection-error.mp4'),
            showBtnClose: true,
            loop: true,
          }
        }
      }

      wsSignConnection.onclose = (error) => {
        if (error.code === 1006) {
          this.messagesGenerateExam = {
            message: 'Falha na comunição com o assinador.',
            image: require('@/assets/mp4/4386-connection-error.mp4'),
            showBtnClose: true,
            loop: true,
          }
        } else if (error.code === 1011) {
          this.messagesGenerateExam = {
            message: error.reason,
            image: require('@/assets/mp4/4386-connection-error.mp4'),
            showBtnClose: true,
            loop: true,
          }
        }
      }
    },
    getExamRequests() {
      if (this.schedule == null)
        return;

      http.get(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/exam-requests`).then((response) => {
        this.examRequests = response.data;
      });
    },
    submitFormStore() {
      this.formStore.id_medrec = this.medicalRecord.id_medrec;
      this.formStoreErrors = [];
      http.post(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/exam-requests`, this.formStore).then(_ => {
        this.getExamRequests();
        Alert.toastSuccess("Pedido cadastrado com sucesso.");
        this.formStore = {
          id_medrec: '',
          exam_request_name: '',
          id_exam_prot: '',
        };
        bootstrap.Modal.getInstance(document.getElementById('modal_store_exam_request_' +  this.medicalRecord.id_medrec)).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    deleteExamRequest(examRequestId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o pedido de exame?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/exam-requests/${examRequestId}`).then(_ => {
            this.getExamRequests();
            Alert.toastSuccess("Pedido excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    reopenExamRequest(examRequestId) {
      Swal.fire({
        title: 'Heeyy',
        text: "A reabertura irá cancelar o PDF que já foi gerado. Tem certeza que deseja reabrir o pedido de exame?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, reabrir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.post(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/exam-requests/${examRequestId}/reopen`).then(_ => {
            this.getExamRequests();
            Alert.toastSuccess("Pedido de exame reaberto com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    showPdfViewer(pdfSource,fileName, signed, modalOri) {
      this.pdfSource = pdfSource;
      this.fileName = fileName;
      this.signed = signed;
      modalOri.toggle();
      let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_pdf_viewer_exam_' + this.medicalRecord.id_medrec));
      modal.toggle();
    },
  },
}
</script>

<style scoped>

</style>