<template>
    <Toolbar />
    <div class="container-xxl">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h2 class="card-title fw-bolder">Meu Perfil</h2>
            </div>
            <div class="col-md-7 offset-md-2">
              <form method="post" @submit.prevent="updateUser">
                <div class="card-body border-top p-9">
                  <div class="row mb-6">
                    <label class="col-lg-4 col-form-label fw-bold fs-6">Foto</label>
                    <div class="col-lg-8">
                      <div class="image-input image-input-circle" data-kt-image-input="true"
                           :class="userAuth.user_photo != null ? '' : 'image-input-empty'"
                           :style="{backgroundImage:`url(`+require('@/assets/images/no-photo.png')+`)`}">

                        <div class="image-input-wrapper w-125px h-125px" v-if="userAuth.user_photo != null"
                             :style="{backgroundImage:`url(${url(userAuth.user_photo)})`}">
                        </div>

                        <div class="image-input-wrapper w-125px h-125px" style="background-image: none;" v-else></div>

                        <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                               data-kt-image-input-action="change" data-bs-toggle="tooltip" title=""
                               data-bs-original-title="Alterar Foto">
                          <i class="bi bi-pencil-fill fs-7"></i>
                          <input type="file" ref="photo" name="photo" id="photo" accept=".png, .jpg, .jpeg">
                          <input type="hidden" name="avatar_remove">
                        </label>
                        <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                              data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title=""
                              data-bs-original-title="Cancelar">
                        <i class="bi bi-x fs-2"></i>
                      </span>
                        <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                              data-kt-image-input-action="remove" data-bs-toggle="tooltip" title=""
                              data-bs-original-title="Remover foto">
                        <i class="bi bi-x fs-2"></i>
                      </span>
                      </div>
                      <div class="form-text">Os formatos permitidos são: png, jpg, jpeg.</div>
                    </div>
                  </div>
                  <div class="row mb-6">
                    <label class="col-lg-4 col-form-label required fw-bold fs-6">Nome</label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <input type="text" name="company" class="form-control form-control-lg form-control-solid"
                             placeholder="Seu nome" v-model="form.user_name">
                      <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="row mb-6">
                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                      <span class="required">E-mail</span>
                    </label>
                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                      <input type="email" class="form-control form-control-lg form-control-solid"
                             placeholder="joao@exemplo.com" v-model="form.user_email" disabled>
                      <div class="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                  </div>
                  <div class="row mb-6">
                    <label class="col-lg-4 col-form-label fw-bold fs-6">CPF</label>
                    <div class="col-lg-8 fv-row">
                      <input type="text" name="website" class="form-control form-control-lg form-control-solid" required
                             placeholder="999.999.999-99" v-model="form.user_cpf" v-mask="'###.###.###-##'">
                    </div>
                  </div>
                  <div class="row mb-6">
                    <label class="col-lg-4 col-form-label fw-bold fs-6">Celular</label>
                    <div class="col-lg-8 fv-row">
                      <input type="text" name="website" class="form-control form-control-lg form-control-solid"
                             placeholder="(99) 9 9999-9999" required
                             v-model="form.user_phone" v-mask="['(##) ####-####', '(##) #####-####']">
                    </div>
                  </div>
                  <div class="row mb-6">
                    <label class="col-lg-4 col-form-label fw-bold fs-6">Data nascimento</label>
                    <div class="col-lg-8 fv-row">
                      <input type="text" name="website" class="form-control form-control-lg form-control-solid"
                             v-mask="'##/##/####'"
                             placeholder="DD/MM/YYYY" v-model="form.user_birthdate" required>
                    </div>
                  </div>
                  <div class="text-center pt-15">
                    <button type="submit" class="btn btn-primary" data-kt-users-modal-action="submit">
                      <span class="indicator-label">Atualizar</span>
                      <span class="indicator-progress" v-show="submitted">
                        Por favor aguarde...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import http from "../helpers/http";
import Alert from "../helpers/alert";
import {mask} from "vue-the-mask";
import str from "../helpers/str";
import Toolbar from "../components/layouts/auth/Toolbar.vue";

export default {
  name: "Profile",
  directives: {
    mask,
  },
    components: {
        Toolbar,
    },
  data() {
    return {
      errors: [],
      submitted: false,
      form: {
        user_name: '',
        user_email: '',
        user_cpf: '',
        user_phone: '',
        user_birthdate: '',
      }
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    'form.user_birthdate': {
      handler(val) {
        if (val?.length === 10) {
          let date = moment(val, 'DD/MM/YYYY');
          if (date.isAfter(new Date())) {
            Alert.toastError('Data de nascimento não pode ser maior que hoje.');
            this.form.user_birthdate = '';
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    http.get('/users/me').then((response) => {
      let user = response.data;
      this.form.user_name = user.user_name;
      this.form.user_email = user.user_email;
      this.form.user_cpf = user.user_cpf;
      this.form.user_phone = user.user_phone;
      this.form.user_birthdate = user.user_birthdate_br;
    });

    KTImageInput.createInstances();
  },
  methods: {
    date: moment,
      url(path) {
          return str.url(path)
      },
    updateUser() {
      const form = new FormData();
      form.append('_method', 'put');
      form.append('user_name', this.form.user_name);
      form.append('user_cpf', this.form.user_cpf);
      form.append('user_phone', this.form.user_phone);
      form.append('user_birthdate', this.date(this.form.user_birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'));
      if (this.$refs.photo.files.length > 0)
        form.append('user_photo', this.$refs.photo.files[0]);

      http.post(`/users/${this.userAuth.id_user}/profile`, form).then((response) => {
        Alert.toastSuccess("Usuário atualizado com sucesso.");

        this.$store.commit('auth/setUser', response.data);
      }).catch((error) => {
        if (error.response.data?.errors) {
          this.errors = error.response.data.errors;
        } else {
          Alert.toastError(error.response.data)
        }
      });
    }
  }
}
</script>

<style scoped>

</style>