<template>
  <div v-if="this.id" class="modal fade" tabindex="-1" :id="this.id">
    <div class="modal-dialog modal-sn">
      <form method="post" @submit.prevent="submitForm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isInserting?'Adicionar':'Editar' }} Registro de Dados de Saúde</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div v-for="(storePatientHealthDataValue, index) in storePatientHealthDataValues" class="row mb-4">
              <div class="col-md-4">
                <label :for="'id_health_indicator_' + storePatientHealthDataValue.id_health_indicator" class="required form-label">{{storePatientHealthDataValue.health_indicator_name}}</label>
                <input class="form-control form-control-solid"
                       v-model="storePatientHealthDataValue.health_data_value" :id="'id_health_indicator_' + storePatientHealthDataValue.id_health_indicator" :autofocus="index === 0" required>
              </div>
              <div class="col-md-2 mt-12">
                <div class="fs-6 text-gray-800 fw-bold">{{storePatientHealthDataValue.scale_initials}}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">{{ isInserting?'Cadastrar':'Alterar'}}</button>
          </div>
        </div>
      </form>
    </div>
  </div> <!-- modal_store_patient_health_data -->
</template>

<script>
import http from "../../../helpers/http";
import Alert from "../../../helpers/alert";

export default {
  name: "HealthDataModal",
  props: {
    patientHealthData: Object,
    healthIndicators: Array,
    professionalId: Number,
    patientId: Number,
    schedId: Number,
    id: '',
  },
  data() {
    return {
      storePatientHealthDataValues: [],
      formErrors: [],
    }
  },
  watch: {
    healthIndicators(newHealthIndicators) {
      if (!this.isInserting)
        return null;

      this.initializeHealthIndicators(newHealthIndicators);
    },
    patientHealthData(newPatientHealthData) {
      if (this.isInserting) {
        this.initializeHealthIndicators(this.healthIndicators);
        return null;
      }

      const arrayTmp = [];
      newPatientHealthData.health_indicators.forEach( function(healthIndicator) {
        if (healthIndicator.health_indicator_type === 'MA') {
          arrayTmp.push({
            id_health_indicator: healthIndicator.id_health_indicator,
            health_indicator_name: healthIndicator.health_indicator_name,
            health_data_value: healthIndicator.patient_health_data_values.health_data_value,
            scale_initials: healthIndicator.scale.scale_initials
          });
        }
      });
      this.storePatientHealthDataValues = arrayTmp;
    }
  },
  computed: {
    isInserting() {
      return this.patientHealthData == null;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    submitForm() {
      this.formErrors = [];

      const formStore = {
        id_user: this.userAuth.id_user,
        id_health_indicator_group: this.healthIndicators[0].id_health_indicator_group,
        id_sched: this.schedId,
        health_indicators: this.storePatientHealthDataValues.map( function(element) {
          return {id_health_indicator: element.id_health_indicator, health_data_value: element.health_data_value};
        })
      };

      if (this.isInserting) {
        http.post(`/professionals/${this.professionalId}/patients/${this.patientId}/health-data`, formStore).then(() => {
          Alert.toastSuccess("Registro cadastrado com sucesso.");
          bootstrap.Modal.getInstance(document.getElementById(this.id)).toggle();
          this.$emit('onClose');
        }).catch(e => {
          Alert.toastError(e.response.data.message??e.response.data);
          if (e.response.data.errors) {
            this.formErrors = e.response.data.errors;
          }
        });
      } else {
        http.put(`/professionals/${this.professionalId}/patients/${this.patientId}/health-data/${this.patientHealthData.id_patient_health_data}`, formStore).then(() => {
          Alert.toastSuccess("Registro alterado com sucesso.");
          bootstrap.Modal.getInstance(document.getElementById(this.id)).toggle();
          this.$emit('onClose');
        }).catch(e => {
          Alert.toastError(e.response.data.message??e.response.data);
          if (e.response.data.errors) {
            this.formErrors = e.response.data.errors;
          }
        });
      }
    },

    initializeHealthIndicators(healthIndicators) {
      const arrayTmp = [];
      healthIndicators.forEach( function(healthIndicator) {
        if (healthIndicator.health_indicator_type === 'MA') {
          arrayTmp.push({
            id_health_indicator: healthIndicator.id_health_indicator,
            health_indicator_name: healthIndicator.health_indicator_name,
            health_data_value: '',
            scale_initials: healthIndicator.scale.scale_initials
          });
        }
      });
      this.storePatientHealthDataValues = arrayTmp;
    },
  }
}
</script>

<style scoped>

</style>