<template>
    <Toolbar />
    <div class="container-xxl">
        <div class="card">
        <div class="card-header">
          <div class="card-title">
            <div class="d-flex align-items-center position-relative my-1">
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"></path>
                </svg>
              </span>
              <input type="text" data-kt-user-table-filter="search" v-model="search"
                     class="form-control form-control-solid w-250px ps-14"
                     placeholder="Pesquisar ...">
            </div>
          </div>
          <div class="card-toolbar">
            <div class="col-md-6">
              <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                      title="Nova especialidade" data-bs-target="#modal_store"
                      @click="form.id_spec=''">
                <i class="bi bi-plus-lg"></i> Adicionar
              </button>
            </div>
          </div>
        </div>
        <div class="card-body py-4">
          <table class="table table-row-bordered gy-5">
            <thead>
            <tr class="fw-bold fs-6 text-muted">
              <th>Área</th>
              <th>Especialidade</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="specialty in specialtiesFiltered">
              <td>{{ specialty.kind_name }}</td>
              <td>{{ specialty.spec_name }}</td>
              <td>
                <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1" @click="deleteSpecialty(specialty.id_spec)"
                        data-bs-toggle="tooltip" title="Excluir Especialidade">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="modal_store">
        <div class="modal-dialog modal-lg">
          <form method="post" @submit.prevent="submitFormStore">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Adicionar Especialidade</h5>
                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
                  <i class="bi bi-x-lg"></i>
                </div>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-6">
                      <div class="mt-4">
                        <label for="id_specialty_store" class="required form-label">Área</label>
                        <select class="form-control form-control-solid" id="id_kind"
                                aria-label="Selecione a área" v-model="form.id_kind" @change="loadSpecialtiesFromKind">
                          <option :value="kind.id_kind" v-for="kind in kinds">
                            {{ kind.kind_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-6">
                      <div class="mt-4">
                        <label for="id_specialty_store" class="required form-label">Especialidade</label>
                        <select class="form-control form-control-solid" id="id_specialty_store"
                                aria-label="Selecione o especialidade" v-model="form.id_spec">
                          <option :value="specialty.id_spec" v-for="specialty in allSpecialties">
                            {{ specialty.spec_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                <button type="submit" class="btn btn-primary">Cadastrar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Specialty",
    components: {
        Toolbar,
    },
  data() {
    return {
      search: '',
      specialties: [],
      kinds: [],
      allSpecialties: [],
      form: {
        id_kind: '',
        id_spec: '',
      },
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    specialtiesFiltered() {
      return this.specialties.filter(specialty => {
        return specialty.spec_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  mounted() {
    this.loadKinds();
    this.loadSpecialties();
  },
  methods: {
    loadKinds() {
      http.get(`/kinds`).then((response) => {
        this.kinds = response.data;
      });
    },
    loadSpecialtiesFromKind() {
      http.get(`/kinds/${this.form.id_kind}/specialties`).then((response) => {
        this.allSpecialties = response.data;
      });
    },
    loadSpecialties() {
      http.get(`/places/${this.place.id_place}/specialties`, {params: {with_exams: false}}).then((response) => {
        this.specialties = response.data;
      });
    },
    submitFormStore() {
      http.post(`/places/${this.place.id_place}/specialties`, this.form).then(_ => {
        this.loadSpecialties();
        Alert.toastSuccess("Especialidade cadastrado com sucesso.");

        this.form = {
          id_kind: '',
          id_spec: '',
        };

        bootstrap.Modal.getInstance(document.getElementById('modal_store')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data);
      });
    },
    deleteSpecialty(specialtyId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover a especialidade?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secondary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/specialties/${specialtyId}`).then(_ => {
            this.loadSpecialties();
            Alert.toastSuccess("Especialidade excluída com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
  }
}
</script>

<style scoped>

</style>