<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center bg-ubbi" style="background-color: #780f5a;">
      <div class="d-flex flex-column flex-center p-10">
        <div class="card card-flush w-lg-950px">
          <div class="card-body">
            <div v-if="isRejected" class="text-center">
              <img alt="Logo" :src="require('@/assets/images/undraw_medicine_b-1-ol.svg')" class="h-200px">
              <br><br><br>
              <h1>Seu convite para o local "{{infos.place_name}}" foi cancelado!</h1>
              <br><br><br>
              <a class="btn btn-primary" :href="siteUrl">
                Ir para o site
              </a>
            </div>
            <div v-else-if="isAccepted" class="text-center">
              <img alt="Logo" :src="require('@/assets/images/undraw_medicine_b-1-ol.svg')" class="h-200px">
              <br><br><br>
              <h1>Seu convite para o local "{{infos.place_name}}" foi aceito!</h1>
              <br><br><br>
              <router-link class="btn btn-primary" to="/">
                Acessar
              </router-link>
            </div>
            <div v-else-if="isInvalid" class="text-center">
              <img alt="Logo" :src="require('@/assets/images/thinking_face-cuate.svg')" class="h-200px">
              <br>
              <h1>{{ invalidMessage }}</h1>
              <br><br>
              <a class="btn btn-primary" :href="siteUrl">
                Ir para o site
              </a>
            </div>
            <div v-else>
              <div  class="text-center">
                <h1 class="fw-bolder text-gray-900 mb-7">Olá {{infos.user_name}}</h1>

                <div class="fw-semibold fs-6 text-gray-500 mb-7">
                  Você tem certeza que deseja {{labelAction}} o convite <br>
                  para acessar o(a) {{infos.place_name}} na plataforma da Ubbimed?
                </div>
              </div>

              <div v-if="isAccept">
                <div class="row" v-if="infos.user_name">
                  <div class="col-md-8 offset-lg-2">
                    <div class="alert alert-danger" v-show="messageErrorLogin" role="alert">
                      {{ messageErrorLogin }}
                    </div>

                    <br>
                    <div class="fv-row mb-10 fv-plugins-icon-container">

                      <div class="d-flex flex-stack mb-2">
                        <div class="w-100 d-inline-flex mb-3 px-2 py-3 fw-semibold text-muted bg-secondary bg-opacity-10 border border-secondary border-opacity-10 rounded-2">
                          <i class="bi bi-person-circle fa-1x me-2 mt-1"></i> {{auth.username}}
                        </div>
                      </div>

                      <div class="d-flex flex-stack mb-2">
                        <label class="form-label fw-bolder text-dark fs-6 mb-0">
                          Para continuar, digite sua senha <span class="text-danger">*</span>
                        </label>
                      </div>
                      <input class="form-control form-control-lg form-control-solid"
                             v-model="auth.password" required
                             type="password" name="password" autocomplete="off" placeholder="Digite sua senha">
                    </div>
                  </div>
                </div>
                <div v-else>
                  <StepperRegister @onRegister="onRegister"
                                   :registerParam="{'prof_email': infos.user_email}"/>
                </div>
              </div>

              <div class="text-center" v-if="infos.user_name">
                <button class="btn" :class="isAccept ? 'btn-primary' : 'btn-danger'"
                        @click="handdlerSubmit">
                  Sim, {{labelAction}} convite
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Config from "../../config";
import StepperRegister from "../auth/StepperRegister";

export default {
  name: "InviteUserAction",
  components: {
    StepperRegister
  },
  data() {
    return {
      isInvalid: false,
      invalidMessage: '',
      messageErrorLogin: '',
      isRejected: false,
      isAccepted: false,
      infos: {},
      place: {},
      auth: {
        username: '',
        password: '',
      },
      siteUrl: Config.site_url_prof,
    }
  },
  mounted() {
    if (this.$store.getters['auth/isAuth'] && this.isAccept) {
      this.$store.dispatch("auth/logout");
    }
    this.findInfosToken();
  },
  computed: {
    inviteId() {
      return this.$route.params.inviteId;
    },
    inviteToken() {
      return this.$route.query.token;
    },
    action() {
      return this.$route.params.action;
    },
    placeId() {
      return this.$route.params.placeId;
    },
    isAccept() {
      return this.action === 'accept';
    },
    labelAction() {
      return this.isAccept ? 'aceitar' : 'recusar';
    },
  },
  methods: {
    accept() {
      let params = {'token': this.inviteToken, 'access_type_level': 'PR'};
      return http.patch(`/places/${this.placeId}/invites/${this.inviteId}/accept`, params).then(() => {
        Alert.toastSuccess('Convite aceito com sucesso.');

        this.isAccepted = true;
        this.isRejected = false;
        this.isInvalid = false;
      }).catch((e) => {
        Alert.toastError(e.response.data);
        this.isInvalid = true;
        this.invalidMessage = e.response.data;
      });
    },
    login() {
      this.messageErrorLogin = null;
      return this.$store.dispatch('auth/login', this.auth).then(async () => {

        await this.accept();
      }).catch((error) => {
        this.messageErrorLogin = error.response.data.message ?? error.response.data;
      });
    },
    async onRegister() {
      await this.accept();
    },
    reject() {
      let params = {'token': this.inviteToken};
      http.patch(`/places/${this.placeId}/invites/${this.inviteId}/reject`, params).then(() => {
        Alert.toastSuccess('Convite recusado com sucesso.');

        this.isRejected = true;
        this.isAccepted = false;
        this.isInvalid = false;
      }).catch((e) => {
        Alert.toastError(e.response.data);
      });
    },
    findInfosToken() {
      let params = {params: {'token': this.inviteToken}};
      http.get(`/places/${this.placeId}/invites/${this.inviteId}/infos`, params).then((response) => {

        this.infos = response.data;
        this.auth.username = this.infos.user_email;
        this.isRejected = false;
        this.isAccepted = false;
      }).catch((e) => {
        this.invalidMessage = e.response.data;
        this.isInvalid = true;
      });
    },
    handdlerSubmit() {
      if (!this.isAccept) {
        return this.reject();
      }

      return this.login();
    }
  },
}
</script>

<style scoped>

</style>