<template>

  <div v-if="!hasPermission('List Record Professional')">Você não tem permissão para acessar esta página!</div>

  <div v-else>
      <Toolbar />
      <div class="container-xxl">
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                    transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
              <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="black"></path>
            </svg>
          </span>
            <input type="text" data-kt-user-table-filter="search" v-model="search"
                   class="form-control form-control-solid w-250px ps-14"
                   placeholder="Pesquisar ...">
          </div>
        </div>
        <div class="card-toolbar">
          <div class="col-md-6" v-show="professionals.length > 1">
            <select class="form-select form-control-solid" aria-label="Selecionar Profissional"
                    v-model="currentProfessionalId" @change="loadRectypes($event.target.value)">
              <option value="" disabled selected v-if="professionals.length > 1">--Selecione--</option>
              <option :value="professional.id_prof" v-for="professional in professionals">
                {{ professional.prof_name }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                    v-if="hasPermission('Store Record Professional')"
                    title="Novo Tipo de Registro" data-bs-target="#modal_store_rectype"
                    @click="searchRectype='';formStore.id_rectype=''">
              <i class="bi bi-plus-lg"></i> Adicionar
            </button>
          </div>
        </div>
      </div> <!-- card header -->

      <div class="card-body py-4">
        <table class="table table-row-bordered gy-5">
          <thead>
          <tr class="fw-bold fs-6 text-muted">
            <th>Tipo de Registro</th>
            <th style="text-align: center">Ativo</th>
            <th style="text-align: center">Ações</th>
          </tr>
          </thead>
          <tbody v-for="rectype in rectypesFiltered" :key="rectype.id_rectype">
          <tr>
            <td>{{ rectype.rectype_name }}</td>
            <td style="text-align: center">
              <span class="badge badge-light-success" v-if="rectype.active">Sim</span>
              <span class="badge badge-light-danger" v-else>Não</span>
            </td>
            <td style="text-align: center">
              <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#modal_edit_rectype"
                      v-if="hasPermission('Update Record Professional')"
                      @click="onClickBtnEdit(rectype)"
                      title="Editar">
                <i class="bi bi-pencil-square"></i>
              </button>
              <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      title="Ver detalhes"
                      rel="tooltip"
                      v-if="hasPermission('List Record Professional Attribute')"
                      @click="getAttributes(rectype.id_rectype);">
                <i class="bi bi-eye"></i>
              </button>
              <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      v-if="hasPermission('Delete Record Professional')"
                      @click="deleteRectype(rectype.id_rectype)"
                      data-bs-toggle="tooltip" title="Excluir Tipo de registro">
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
          <tr v-if="!attributesHide  && currentRectypeId === rectype.id_rectype && !loading">
            <td colspan="3">
              <div class="d-flex justify-content-end">
                <button class="btn btn-light-primary me-3" data-bs-toggle="modal"
                        title="Novo Atributo" data-bs-target="#modal_store_attribute"
                        v-if="hasPermission('Store Record Professional Attribute')"
                        @click="clearAttrOptionStore(); clearAttrOptionEdit(); formStoreAttribute.active=true;">
                  <i class="bi bi-plus-lg"></i> Novo Atributo
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="!attributesHide  && (attributes.length) && currentRectypeId === rectype.id_rectype && !loading">
            <td colspan="3">
              <table class="table table-row-bordered table-hover table-light gy-3">
                <thead>
                <tr class="fw-bold fs-6 text-muted">
                  <th></th>
                  <th style="text-align: center">#</th>
                  <th>Atributo</th>
                  <th style="text-align: center">Ativo</th>
                  <th style="text-align: center">Açoes</th>
                </tr>
                </thead>
                <draggable v-model="attributes" :disabled="draging" tag="tbody" item-key="id_rectype_attr"
                           @change="onChangeAttributeOrder();">
                  <template #item="{ element, index }">
                    <tr class="hover-row">
                      <td style="text-align: center; width: 50px">
                        <i class="h1 bi bi-grip-vertical"></i>
                      </td>
                      <td style="text-align: center">
                        {{ index + 1 }}
                      </td>
                      <td>{{ element.label }}</td>
                      <td style="text-align: center">
                        <span class="badge badge-light-success" v-if="element.active">Sim</span>
                        <span class="badge badge-light-danger" v-else>Não</span>
                      </td>
                      <td style="text-align: center">
                        <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                data-bs-toggle="modal"
                                data-bs-target="#modal_edit_attribute"
                                v-if="hasPermission('Update Record Professional Attribute')"
                                @click="onClickBtnEditAttribute(element)"
                                title="Editar">
                          <i class="bi bi-pencil-square"></i>
                        </button>
                        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                v-if="hasPermission('Delete Record Professional Attribute')"
                                @click="deleteAttribute(element.id_rectype_attr)"
                                data-bs-toggle="tooltip" title="Excluir Atributo">
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </td>
          </tr> <!-- attribute table -->
          </tbody>
        </table>
      </div> <!-- card body -->
    </div> <!-- card -->

    <div class="modal fade" tabindex="-1" id="modal_store_rectype">
      <div class="modal-dialog modal-lg">
        <form method="post" @submit.prevent="submitFormStore">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Adicionar Tipo de Registro</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                   aria-label="Fechar">
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mb-6 mt-4">
                <label for="id_rectype_store" class="required form-label">Selecione o Tipo de registro</label>
                <select class="form-control form-select" id="id_rectype_store"
                        aria-label="Selecione o tipo de registro" v-model="formStore.id_rectype" autofocus required>
                  <option value="" disabled selected>--Selecione--</option>
                  <option :value="rectype.id_rectype" v-for="rectype in allRectypes">
                    {{ rectype.rectype_name }}
                  </option>
                </select>
                <small class="text-danger" v-if="formStoreErrors.rectype_name">{{
                    formStoreErrors.rectype_name[0]
                  }}</small>
              </div>
              <div class="col-md-12 mt-6">
                <label for="rectype_active_store" class="required form-label">Ativo?</label>
                <input class="form-check-input" type="checkbox" v-model="formStore.active" id="rectype_active_store"
                       checked>
                <small class="text-danger" v-if="formStoreErrors.active">{{ formStoreErrors.active[0] }}</small>
              </div>
              <div class="col-md-12 mt-6">
                <label for="rectype_auto_include_store" class="required form-label">Incluir automaticamente ao iniciar
                  atendimento?</label>
                <input class="form-check-input" type="checkbox" v-model="formStore.auto_include"
                       id="rectype_auto_include_store" checked>
                <small class="text-danger" v-if="formStoreErrors.auto_include">{{
                    formStoreErrors.auto_include[0]
                  }}</small>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="submit" class="btn btn-primary">Cadastrar</button>
            </div>
          </div>
        </form>
      </div>
    </div> <!-- modal_store_rectype -->

    <div class="modal fade" tabindex="-1" id="modal_edit_rectype">
      <div class="modal-dialog modal-lg">
        <form method="post" @submit.prevent="submitFormEdit">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Editar Tipo de registro</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                   aria-label="Fechar">
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mt-6">
                <label for="rectype_name_edit" class="form-label">Tipo de Registro</label>
                <input class="form-control form-control-solid"
                       disabled="true" v-model="formEdit.rectype_name" id="rectype_name_edit" checked>
                <small class="text-danger" v-if="formEditErrors.rectype_name">{{
                    formEditErrors.rectype_name[0]
                  }}</small>
              </div>
              <div class="col-md-12 mt-6">
                <label for="rectype_active_edit" class="required form-label">Ativo?</label>
                <input class="form-check-input" type="checkbox" v-model="formEdit.active" id="rectype_active_edit"
                       checked autofocus>
                <small class="text-danger" v-if="formEditErrors.active">{{ formEditErrors.active[0] }}</small>
              </div>
              <div class="col-md-12 mt-6">
                <label for="rectype_auto_include_edit" class="required form-label">Incluir automaticamente ao iniciar
                  atendimento?</label>
                <input class="form-check-input" type="checkbox" v-model="formEdit.auto_include"
                       id="rectype_auto_include_edit" checked autofocus>
                <small class="text-danger" v-if="formEditErrors.auto_include">{{
                    formEditErrors.auto_include[0]
                  }}</small>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="submit" class="btn btn-primary">Salvar</button>
            </div>
          </div>
        </form>
      </div>
    </div> <!-- modal_edit_rectype -->

    <div class="modal fade" tabindex="-1" id="modal_store_attribute">
      <div class="modal-dialog modal-lg">
        <form method="post" @submit.prevent="submitFormStoreAttribute">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Adicionar atributo</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar"
                   @click="this.editingStoreOption = false;">
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mb-6 mt-4">
                <label for="id_rectype_attr_store" class="required form-label required">Atributo</label>
                <input class="form-control form-control-solid"
                       v-model="formStoreAttribute.label" id="id_rectype_attr_store" autofocus="true" required>
                <small class="text-danger" v-if="formStoreAttributeErrors.label">{{
                    formStoreAttributeErrors.label[0]
                  }}</small>
              </div>
              <div class="col-md-12 mb-6 mt-4">
                <label for="type_store" class="required form-label required">Tipo de Atributo</label>
                <select class="form-control form-select" id="type_store"
                        aria-label="Selecione o tipo do atributo" v-model="formStoreAttribute.type" required>
                  <option value="" disabled selected>--Selecione--</option>
                  <option :value="attributeType.id" v-for="attributeType in attributeTypes">
                    {{ attributeType.description }}
                  </option>
                </select>
                <small class="text-danger" v-if="formStoreAttributeErrors.type">{{
                    formStoreAttributeErrors.type[0]
                  }}</small>
              </div>
              <div class="row">
                <div class="col-md-6 mt-6">
                  <label for="attr_required_store" class="required form-label">Obrigatório?</label>
                  <input class="form-check-input" type="checkbox" v-model="formStoreAttribute.required"
                         id="attr_required_store" checked>
                  <small class="text-danger"
                         v-if="formStoreAttributeErrors.required">{{ formStoreAttributeErrors.required[0] }}</small>
                </div>
                <div class="col-md-6 mt-4">
                  <label for="attr_active_store" class="required form-label">Ativo?</label>
                  <input class="form-check-input" type="checkbox" v-model="formStoreAttribute.active"
                         id="attr_active_store" checked>
                  <small class="text-danger"
                         v-if="formStoreAttributeErrors.active">{{ formStoreAttributeErrors.active[0] }}</small>
                </div>
              </div>
              <div class="card"
                   v-if="this.formStoreAttribute.type === 'radio' || this.formStoreAttribute.type === 'checkbox'">
                <hr>
                <h5 class="card-title mt-6">Opções</h5>
                <div class="card-body">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        <label for="attr_option_store_option" class="form-label">Opção</label>
                        <input ref="attrOptionsStoreOption" class="form-control form-control-solid" type="text"
                               v-model="attr_option_store.option" id="attr_option_store_option" maxlength="200">
                      </div>
                      <div class="col-md-3 mt-6">
                        <label for="attr_option_store_require_compl" class="form-label">Possui complemento?</label>
                        <input class="form-check-input" type="checkbox" v-model="attr_option_store.require_compl"
                               id="attr_option_store_require_compl">
                      </div>
                      <div class="col-md-3" v-if="attr_option_store.require_compl">
                        <label for="attr_option_store_label" class="form-label">Titulo</label>
                        <input class="form-control  form-control-solid" type="text" v-model="attr_option_store.label"
                               id="attr_option_store_label">
                      </div>
                      <div class="col-md-2 mt-7">
                        <button v-if="!editingStoreOption && hasPermission('Update Record Professional Attribute')" type="button" class="btn btn-primary"
                                @click="addStoreOption()">Incluir
                        </button>
                        <button v-if="editingStoreOption && hasPermission('Delete Record Professional Attribute')" type="button" class="btn btn-primary"
                                @click="updStoreOption()">Alterar
                        </button>
                      </div>
                    </div>
                    <small class="text-danger"
                           v-if="formStoreAttributeErrors.options">{{ formStoreAttributeErrors.options[0] }}</small>
                    <div class="col-md-12 mb-6 mt-4">
                      <table class="table table-row-bordered table-hover table-light gy-3">
                        <thead>
                        <tr class="fw-bold fs-6 text-muted">
                          <th></th>
                          <th style="text-align: center">#</th>
                          <th>Opção</th>
                          <th style="text-align: center">Possui<br>complemento?</th>
                          <th style="text-align: center">TÍtulo do<br>complemento</th>
                          <th style="text-align: center">Opções</th>
                        </tr>
                        </thead>
                        <draggable v-model="this.formStoreAttribute.options" :disabled="draging || editingStoreOption"
                                   tag="tbody" item-key="option">
                          <template #item="{ element, index }">
                            <tr class="hover-row">
                              <td style="text-align: center; width: 10px">
                                <i class="h1 bi bi-grip-vertical"></i>
                              </td>
                              <td style="text-align: center">{{ index + 1 }}</td>
                              <td style="word-wrap: break-word; max-width: 160px;">{{ element.option }}</td>
                              <td style="text-align: center">
                                <span class="badge badge-light-success" v-if="element.require_compl">Sim</span>
                                <span class="badge badge-light-danger" v-else>Não</span>
                              </td>
                              <td style="word-wrap: break-word; max-width: 100px;">{{ element.label }}</td>
                              <td style="text-align: center">
                                <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                        data-bs-toggle="tooltip" title="Editar Opção" @click="edtStoreOption(index);">
                                  <i class="bi bi-pencil-square"></i>
                                </button>
                                <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                        data-bs-toggle="tooltip" title="Excluir Opção" @click="delStoreOption(index);">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </template>
                        </draggable>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="submit" class="btn btn-primary">Cadastrar</button>
            </div>
          </div>
        </form>
      </div>
    </div> <!-- modal_store_attribute -->

    <div class="modal fade" tabindex="-1" id="modal_edit_attribute">
      <div class="modal-dialog modal-lg">
        <form method="post" @submit.prevent="submitFormEditAttribute">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Editar atributo</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                   aria-label="Fechar">
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mb-6 mt-4">
                <label for="id_rectype_attr_edit" class="required form-label required">Atributo</label>
                <input class="form-control form-control-solid"
                       v-model="formEditAttribute.label" id="id_rectype_attr_edit" required>
                <small class="text-danger" v-if="formEditAttributeErrors.label">{{
                    formEditAttributeErrors.label[0]
                  }}</small>
              </div>
              <div class="col-md-12 mb-6 mt-4">
                <label for="type_edit" class="required form-label required">Tipo de Atributo</label>
                <select class="form-control form-select" id="type_edit"
                        aria-label="Selecione o tipo do atributo" v-model="formEditAttribute.type" required>
                  <option :value="attributeType.id" v-for="attributeType in attributeTypes">
                    {{ attributeType.description }}
                  </option>
                </select>
                <small class="text-danger" v-if="formEditAttributeErrors.type">{{
                    formEditAttributeErrors.type[0]
                  }}</small>
              </div>
              <div class="row">
                <div class="col-md-6 mt-2">
                  <label for="attr_required_edit" class="required form-label required">Obrigatório?</label>
                  <input class="form-check-input" type="checkbox" v-model="formEditAttribute.required"
                         id="attr_required_edit" checked>
                  <small class="text-danger"
                         v-if="formEditAttributeErrors.required">{{ formEditAttributeErrors.required[0] }}</small>
                </div>
                <div class="col-md-6 mt-2">
                  <label for="attr_active_edit" class="required form-label required">Ativo?</label>
                  <input class="form-check-input" type="checkbox" v-model="formEditAttribute.active"
                         id="attr_active_edit" checked>
                  <small class="text-danger" v-if="formEditAttributeErrors.active">{{
                      formEditAttributeErrors.active[0]
                    }}</small>
                </div>
              </div>
              <div class="card"
                   v-if="this.formEditAttribute.type === 'radio' || this.formEditAttribute.type === 'checkbox'">
                <hr>
                <h5 class="card-title mt-2">Opções</h5>
                <div class="card-body">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        <label for="attr_option_edit_option" class="form-label">Opção</label>
                        <input ref="attrOptionsEditOption" class="form-control form-control-solid" type="text"
                               v-model="attr_option_edit.option" id="attr_option_edit_option" maxlength="200">
                      </div>
                      <div class="col-md-3 mt-6">
                        <label for="attr_option_edit_require_compl" class="form-label">Possui complemento?</label>
                        <input class="form-check-input" type="checkbox" v-model="attr_option_edit.require_compl"
                               id="attr_option_edit_require_compl">
                      </div>
                      <div class="col-md-3" v-if="attr_option_edit.require_compl">
                        <label for="attr_option_edit_label" class="form-label">Titulo</label>
                        <input class="form-control  form-control-solid" type="text" v-model="attr_option_edit.label"
                               id="attr_option_edit_label">
                      </div>
                      <div class="col-md-2 mt-7">
                        <button v-if="!editingEditOption" type="button" class="btn btn-primary"
                                @click="addEditOption()">Incluir
                        </button>
                        <button v-if="editingEditOption" type="button" class="btn btn-primary" @click="updEditOption()">
                          Alterar
                        </button>
                      </div>
                    </div>
                    <small class="text-danger"
                           v-if="formEditAttributeErrors.options">{{ formEditAttributeErrors.options[0] }}</small>
                    <div class="col-md-12 mb-6 mt-4">
                      <table class="table table-row-bordered table-hover table-light gy-3">
                        <thead>
                        <tr class="fw-bold fs-6 text-muted">
                          <th></th>
                          <th style="text-align: center">#</th>
                          <th>Opção</th>
                          <th style="text-align: center">Possui<br>complemento?</th>
                          <th style="text-align: center">TÍtulo do<br>complemento</th>
                          <th style="text-align: center">Opções</th>
                        </tr>
                        </thead>
                        <draggable v-model="this.formEditAttribute.options" :disabled="draging || editingEditOption"
                                   tag="tbody" item-key="option">
                          <template #item="{ element, index }">
                            <tr class="hover-row">
                              <td style="text-align: center; width: 10px">
                                <i class="h1 bi bi-grip-vertical"></i>
                              </td>
                              <td style="text-align: center">{{ index + 1 }}</td>
                              <td style="word-wrap: break-word; max-width: 160px;">{{ element.option }}</td>
                              <td style="text-align: center">
                                <span class="badge badge-light-success" v-if="element.require_compl">Sim</span>
                                <span class="badge badge-light-danger" v-else>Não</span>
                              </td>
                              <td style="word-wrap: break-word; max-width: 100px;">{{ element.label }}</td>
                              <td style="text-align: center">
                                <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                        data-bs-toggle="tooltip" title="Editar Opção" @click="edtEditOption(index);">
                                  <i class="bi bi-pencil-square"></i>
                                </button>
                                <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                                        data-bs-toggle="tooltip" title="Excluir Opção" @click="delEditOption(index);">
                                  <i class="bi bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </template>
                        </draggable>
                      </table>
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="submit" class="btn btn-primary">Salvar</button>
            </div>
          </div>
        </form>
      </div>
    </div> <!-- modal_edit_attribute -->

  </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import draggable from 'vuedraggable'
import Permission from "../../helpers/permission";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "RecordType",
  components: {
    draggable,
      Toolbar,
  },
  data() {
    return {
      search: '',
      attributesHide: true,
      loading: false,
      draging: false,
      updatingAttribute: false,
      currentProfessionalId: '',
      currentRectypeId: '',
      rectypes: [],
      allRectypes: [],
      attributes: [],
      searchRectype: '',
      attributeTypes: [],
      formStore: {
        id_rectype: '',
        active: true,
        auto_include: false,
      },
      formStoreErrors: [],
      formEdit: {
        id_rectype: '',
        active: false,
        rectype_name: '',
        auto_include: false,
      },
      formEditErrors: [],
      formStoreAttribute: {
        id_rectype_attr: '',
        id_prof: '',
        id_rectype: '',
        label: '',
        order: '',
        type: '',
        options: [],
        active: false,
      },
      attr_option_store: '',

      formStoreAttributeErrors: [],
      formEditAttribute: {
        id_rectype_attr: '',
        id_prof: '',
        id_rectype: '',
        label: '',
        order: '',
        type: '',
        options: [],
        active: false,
      },
      attr_option_edit: '',
      formEditAttributeErrors: [],
      editingStoreOption: false,
      indexStoreOption: false,
      editingEditOption: false,
      indexEditOption: false,
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professionals() {
      return this.$store.state.auth.professionals;
    },
    rectypesFiltered() {
      return this.rectypes.filter(rectype => {
        return rectype.rectype_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    allRectypesFiltered() {
      return this.allRectypes.filter(rectype => {
        return rectype.rectype_name.toLowerCase().includes(this.searchRectype.toLowerCase());
      });
    },
  },
  mounted() {
    if (this.professionals.length > 0) {
      this.currentProfessionalId = this.$store.state.auth.user.id_prof ?? this.professionals[0].id_prof;
      this.loadRectypes(this.currentProfessionalId);
    }
    this.loadAllRectypes();
    this.loadAttributeTypes();
    this.clearAttrOptionStore();
    this.clearAttrOptionEdit();
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadAttributeTypes() {
      this.attributeTypes = [];
      http.get(`/record-types/attribute-types`).then((response) => {
        this.attributeTypes = response.data;
      });
    },
    loadAllRectypes() {
      this.allRectypes = [];
      http.get(`/record-types`).then((response) => {
        this.allRectypes = response.data;
      });
    },
    loadRectypes(professionalId) {
      this.rectypes = [];
      this.currentProfessionalId = professionalId;
      http.get(`/professionals/${professionalId}/record-types`).then((response) => {
        this.rectypes = response.data;
      });
    },
    submitFormStore() {
      this.formStoreErrors = [];
      http.post(`/professionals/${this.currentProfessionalId}/record-types`, this.formStore).then(_ => {
        this.loadRectypes(this.currentProfessionalId);
        Alert.toastSuccess("Tipo de registro cadastrado com sucesso.");
        this.formStore = {
          id_rectype: '',
          active: true
        };
        bootstrap.Modal.getInstance(document.getElementById('modal_store_rectype')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    onClickBtnEdit(rectype) {
      this.formEdit.id_rectype = rectype.id_rectype;
      this.formEdit.active = rectype.active;
      this.formEdit.rectype_name = rectype.rectype_name;
      this.formEdit.auto_include = rectype.auto_include;
    },
    submitFormEdit() {
      const prof_rectype = {
        "id_rectype": this.formEdit.id_rectype,
        "active": this.formEdit.active,
        "auto_include": this.formEdit.auto_include,
      };
      this.formEditErrors = [];
      http.put(`/professionals/${this.currentProfessionalId}/record-types/${this.formEdit.id_rectype}`, prof_rectype).then(() => {
        this.loadRectypes(this.currentProfessionalId);
        Alert.toastSuccess("Tipo de recurso atualizado com sucesso.");

        bootstrap.Modal.getInstance(document.getElementById('modal_edit_rectype')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formEditErrors = e.response.data.errors;
        }
      });
    },
    onClickBtnEditAttribute(attribute) {
      this.formEditAttribute.id_rectype_attr = attribute.id_rectype_attr;
      this.formEditAttribute.id_prof = attribute.id_prof;
      this.formEditAttribute.id_rectype = attribute.id_rectype;
      this.formEditAttribute.label = attribute.label;
      this.formEditAttribute.order = attribute.order;
      this.formEditAttribute.type = attribute.type;
      this.formEditAttribute.options = attribute.options ?? [];
      this.formEditAttribute.active = attribute.active;
      this.formEditAttribute.required = attribute.required;
      this.deletingEditOption = false;
    },
    submitFormStoreAttribute() {
      this.formStoreAttribute.id_prof = this.currentProfessionalId;
      this.formStoreAttribute.id_rectype = this.currentRectypeId;
      this.formStoreAttribute.order = this.attributes.length + 1;
      this.formStoreAttributeErrors = [];
      http.post(`/professionals/${this.currentProfessionalId}/record-types/${this.currentRectypeId}/attributes`, this.formStoreAttribute).then(_ => {
        this.getAttributes(this.currentRectypeId, false);
        Alert.toastSuccess("Atributo cadastrado com sucesso.");
        this.formStoreAttribute = {
          id_rectype_attr: '',
          id_prof: '',
          id_rectype: '',
          label: '',
          order: '',
          active: true,
          required: true,
          options: [],
        };
        bootstrap.Modal.getInstance(document.getElementById('modal_store_attribute')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formStoreAttributeErrors = e.response.data.errors;
        }
      });
    },
    submitFormEditAttribute() {
      this.formEditAttributeErrors = [];
      http.put(`/professionals/${this.currentProfessionalId}/record-types/${this.currentRectypeId}/attributes/${this.formEditAttribute.id_rectype_attr}`, this.formEditAttribute).then(() => {
        this.getAttributes(this.currentRectypeId, false);
        Alert.toastSuccess("Atributo atualizado com sucesso.");
        bootstrap.Modal.getInstance(document.getElementById('modal_edit_attribute')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formEditAttributeErrors = e.response.data.errors;
        }
      });
    },
    deleteRectype(rectypeId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o tipo de registro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.currentProfessionalId}/record-types/${rectypeId}`).then(_ => {
            this.loadRectypes(this.currentProfessionalId);
            Alert.toastSuccess("Tipo de registro excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    deleteAttribute(rectypeAttrId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o atributo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.currentProfessionalId}/record-types/${this.currentRectypeId}/attributes/${rectypeAttrId}`).then(_ => {
            this.getAttributes(this.currentRectypeId, false);
            Alert.toastSuccess("Atributo excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    getAttributes(rectypeId, switchAttributesHide = true) {
      if (!this.attributesHide && switchAttributesHide && this.currentRectypeId === rectypeId) {
        this.attributesHide = true;
        return;
      }
      this.loading = switchAttributesHide ? true : this.loading;
      this.currentRectypeId = rectypeId;
      http.get(`/professionals/${this.currentProfessionalId}/record-types/${rectypeId}/attributes`).then((response) => {
        this.attributes = response.data;
        this.loading = false;
        this.attributesHide = false;
      }).catch(e => {
        Alert.toastError(e.response.data);
        this.loading = false;
      });
    },
    onChangeAttributeOrder() {
      var updAttributes = [];
      this.draging = true;
      this.attributes.forEach(function (attribute, index) {
        if (attribute.order !== index + 1) {
          updAttributes.push({id_rectype_attr: attribute.id_rectype_attr, order: index + 1});
        }
      });
      if (updAttributes.length > 0) {
        http.put(`/professionals/${this.currentProfessionalId}/record-types/${this.currentRectypeId}/attributes/update-orders`, updAttributes).then(() => {
          this.attributes.forEach(function (attribute, index) {
            attribute.order = index + 1;
          });
          this.draging = false;
        }).catch(e => {
          Alert.toastError(e.response.data);
          this.draging = false;
        });
      }
    },
    clearAttrOptionStore() {
      this.attr_option_store =
          {
            option: '',
            require_compl: false,
            label: '',
          };
      this.editingStoreOption = false;
    },
    addStoreOption() {
      var option = JSON.stringify(this.attr_option_store);
      this.formStoreAttribute.options.push(JSON.parse(option));
      this.clearAttrOptionStore();
    },
    edtStoreOption(index) {
      var option = JSON.stringify(this.formStoreAttribute.options[index]);
      this.attr_option_store = JSON.parse(option);
      this.indexStoreOption = index;
      this.editingStoreOption = true;
      this.$refs.attrOptionsStoreOption.focus();
    },
    delStoreOption(index) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o atributo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.formStoreAttribute.options.splice(index, 1);
          this.clearAttrOptionStore();
        }
      });
    },
    updStoreOption() {
      var option = JSON.stringify(this.attr_option_store);
      this.formStoreAttribute.options[this.indexStoreOption] = JSON.parse(option);
      this.clearAttrOptionStore();
    },
    clearAttrOptionEdit() {
      this.attr_option_edit =
          {
            option: '',
            require_compl: false,
            label: '',
          };
      this.editingEditOption = false;
    },
    addEditOption() {
      var option = JSON.stringify(this.attr_option_edit);
      this.formEditAttribute.options.push(JSON.parse(option));
      this.clearAttrOptionEdit();
    },
    edtEditOption(index) {
      var option = JSON.stringify(this.formEditAttribute.options[index]);
      this.attr_option_edit = JSON.parse(option);
      this.indexEditOption = index;
      this.editingEditOption = true;
      this.$refs.attrOptionsEditOption.focus();
    },
    delEditOption(index) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o atributo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.formEditAttribute.options.splice(index, 1);
          this.clearAttrOptionEdit();
        }
      });
    },
    updEditOption() {
      var option = JSON.stringify(this.attr_option_edit);
      this.formEditAttribute.options[this.indexEditOption] = JSON.parse(option);
      this.clearAttrOptionEdit();
    },

  }
}
</script>

<style scoped>
.hover-row:hover {
  cursor: move;
}
</style>