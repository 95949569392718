import http from "../helpers/http";

export class ScheduleService {
    static new() {
        return new ScheduleService();
    };

    arriveSchedule(place, idSched, isSchedArrive) {
        let action = isSchedArrive ? 'not-arrive' : 'arrive';
        return http.patch(`/places/${place.id_place}/schedules/${idSched}/${action}`);
    };

}