<template>
  <div class="col-md-12" v-if="attributeData.type === 'text'">
    <div class="mb-7">
      <label class="fs-6 fw-bold form-label mt-3">
            <span>
              {{ attributeData.label }} <span class="text-danger" v-if="attributeData.required">*</span>
            </span>
      </label>
      <textarea class="form-control form-control-solid" placeholder="Digite aqui..."
                :required="attributeData.required"
                :disabled="disabled"
                :value="attributeData.text"
                rows="5"
                @blur="saveAttributeText($event.target.value)"></textarea>
    </div>
  </div>

  <div class="col-md-6 checkbox-list" v-else-if="attributeData.type === 'checkbox'">
    <div class="mb-10">
      <label class="fs-6 fw-bold mb-2">
        {{ attributeData.label }} <span class="text-danger" v-if="attributeData.required">*</span>
      </label>

      <template v-for="(option, index) in attributeData.options">
        <div class="form-check form-check-custom form-check-solid mt-2">
          <input class="form-check-input" type="checkbox" :value="option.option" :required="attributeData.required"
                 @change="saveAttribute" v-model="attributeData.text" :disabled="disabled"
                 :id="`check-attr-${index}-${attributeData.id_medrec_attr}`"/>
          <label class="form-check-label" :for="`check-attr-${index}-${attributeData.id_medrec_attr}`">
            {{ option.option }}
          </label>
        </div>
      </template>
    </div>
  </div>

  <div class="col-md-6" v-else-if="attributeData.type === 'number'">
    <div class="mb-10">
      <label :for="'attr-number-' + attributeData.id_medrec_attr" class="form-label">
        {{ attributeData.label }} <span class="text-danger" v-if="attributeData.required">*</span>
      </label>
      <input type="number" :id="'attr-number-' + attributeData.id_medrec_attr" v-model="attributeData.text"
             :required="attributeData.required" :disabled="disabled" name="checkbox"
             class="form-control form-control-solid" @change="saveAttribute">
    </div>
  </div>

  <div class="col-md-6" v-else-if="attributeData.type === 'radio'">
    <div class="mb-10">
      <label class="fs-6 fw-bold mb-2">
        {{ attributeData.label }} <span class="text-danger" v-if="attributeData.required">*</span>
      </label>
      <div class="form-check form-check-custom form-check-solid mt-2" v-for="(option, index) in attributeData.options">
        <input class="form-check-input" type="radio" :name="`option-attr-${attributeData.id_medrec_attr}`"
               :id="`option-attr-${index}-${attributeData.id_medrec_attr}`" :value="option.option"
               :required="attributeData.required" v-model="attributeData.text" :disabled="disabled"
               @change="saveAttribute(option)"/>
        <label class="form-check-label" :for="`option-attr-${index}-${attributeData.id_medrec_attr}`">
          {{ option.option }}
        </label>
      </div>
    </div>

    <div class="col-md-12" v-if="attributeData.option?.require_compl">
      <div class="mb-10">
        <label :for="'attr-compl-' + attributeData.id_medrec_attr" class="form-label">
          {{ attributeData.option.label }} <span class="text-danger">*</span>
        </label>
        <input type="text" :id="'attr-compl-' + attributeData.id_medrec_attr"
               v-model="attributeData.text_compl"
               class="form-control form-control-solid" @change="saveAttribute(null)">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    attribute: Object,
    disabled: Boolean,
  },
  created() {
    if (this.attributeData.type === 'checkbox') {
      this.attributeData.text =  Array.isArray(this.attributeData.text) ? this.attribute.text : [];
    }

    if (this.attributeData.type === 'radio') {
      this.attributeData.options.forEach((option) => {
        if (option.option === this.attributeData.text) {
          this.attributeData.option = option;
        }
      });
    }
  },
  data() {
    return {
      attributeData: this.attribute,
    }
  },
  methods: {
    saveAttribute(option = null) {
      if((this.attributeData.type === 'radio') && option != null)
        this.attributeData.option = option;
      this.$emit('onChange', this.attributeData);
    },
    saveAttributeText(value) {
      this.attributeData.text = value;
      this.$emit('onChange', this.attributeData);
    }
  }
}
</script>

<style scoped>

</style>