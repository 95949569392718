<template>

  <div v-if="this.id" class="modal fade" tabindex="-1"  role="document" :id="this.id">
    <div class="modal-dialog  modal-dialog-scrollable  modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">

          <template v-if="isLoading"> Carregando... </template>

          <template v-else>
            <span v-if="showAllPages"> {{ pageCount }} página(s) </span>

            <span v-else>
              <button class="btn btn-sm btn-light-primary  m-1"
                      data-toggle="tooltip"
                      title="Página anterior"
                      :disabled="page <= 1"
                      @click="page--">
                <i class="bi bi-arrow-up-circle"></i>
              </button>

              <label class="m-4">
                {{ page }} / {{ pageCount }}
              </label>

              <button class="btn btn-sm btn-light-primary m-1"
                      :disabled="page >= pageCount"
                      @click="page++"
                      data-toggle="tooltip"
                      title="Próxima página">
                <i class="bi bi-arrow-down-circle"></i>
              </button>

            </span>

            <label class="right m-4">
              <input v-model="showAllPages" type="checkbox" />
              Mostrar todas
            </label>

            <button class="btn btn-sm btn-light-primary m-1" data-toggle="tooltip" title="Imprimir" @click="printDoc()">
              <i class="bi bi-printer"></i>
            </button>

            <button class="btn btn-sm btn-light-primary m-1" data-toggle="tooltip" title="Fazer download" @click="downloadDoc()">
              <i class="bi bi-download"></i>
            </button>

             <span v-if="signed"
                  class="badge fw-bolder text-right mx-2 p-2"
                  :class="signed??false ? 'badge-light-success' : 'badge-light-warning'">
              <i class="bi bi-pen-fill badge-light-success ms-2 me-2"></i> Assinado Digitalmente
            </span>

          </template>

          <button type="button" class="btn-close" aria-label="Fechar" data-bs-dismiss="modal"></button>

        </div>

        <div class="modal-body">
          <vue-pdf-embed
              ref="pdfRef"
              :source="source"
              :page="page"
              width="1100"
              @password-requested="handlePasswordRequest"
              @rendered="handleDocumentRender"
          />
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed';
import Download from "../../../helpers/download";

export default {
  components: {
    VuePdfEmbed
  },
  props: {
    id: String,
    pdfSource: String,
    fileName: String,
    signed: Boolean,
  },
  data() {
    return {
      isLoading: true,
      page: null,
      pageCount: 1,
      showAllPages: true,
      source: '',
    }
  },
  mounted() {
    this.pageCount = 0;
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
    pdfSource(newPdfSource, oldPdfSource) {
      if (newPdfSource !== oldPdfSource) {
        this.source = newPdfSource;
      }
    },
  },
  methods: {
    handleDocumentRender(args) {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? 'Insira a senha novamente' : 'Insira a senha'));
    },
    downloadDoc() {
      let pos = this.pdfSource.indexOf(",")
      Download.base64File('application/pdf', this.pdfSource.substring(pos+1, this.pdfSource.length), this.fileName);
    },
    printDoc() {
      this.$refs.pdfRef.print(300,this.fileName, true);
    },
  }
}
</script>

<style scoped>
  .fixedHeader
  {
    position:fixed;
  }
</style>
