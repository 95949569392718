<template>

  <div class="d-flex flex-wrap flex-stack bg-gray-100">
    <h3 class="fw-bold">
      <span class="fs-6 text-gray-400 fw-semibold ms-1">{{this.attachments.length}} anexos</span></h3>
  </div>

  <div class="row g-6 g-xl-9 mb-6 mb-xl-9 bg-gray-100 pt-2 pb-8">

    <div v-if="!readonly"  class="col-md-6 col-lg-4 col-xl-3 p-0">
      <DropZone :headers="headers"
                :key="dropZoneKey"
                :maxFiles=10
                :maxFileSize=100000000
                class="h-100 border-primary border border-dashed p-8 mx-5"
                :multipleUpload=true
                :ref="'attachment-file1'"
                @uploaded="uploaded"
                @errorUpload="onErrorUpload"
                :url="endpointUpload()">
        <template v-slot:message>
          <p class="mt-10">
            <img :src="require('@/assets/images/files/upload.svg')" class="theme-light-show" alt=""><br><br>
            Clique ou arraste os arquivos aqui para enviar
          </p>
        </template>
      </DropZone>
    </div>

    <div v-for="attachment in attachments" :key="attachment.id_attachment" class="col-md-6 col-lg-4 col-xl-3">
      <div class="card h-100">
        <div class="card-body d-flex justify-content-center text-center flex-column border border-light border-hover-dark p-8">
          <a class="text-gray-800 text-hover-primary d-flex flex-column" @click="downloadFile(attachment)">
            <div v-if="attachment.file?.file_thumbnail" class="symbol symbol-60px">
              <img :src="'data:image/jpeg;base64,'+ attachment.file.file_thumbnail" class="theme-light-show" alt="">
            </div>
            <div v-else class="symbol symbol-60px">
              <img :src="getFileSvg(attachment.file?.file_mime_type)" class="theme-light-show" alt="">
            </div>
            <div class="fs-5 fw-bold mb-2">{{attachment.attachment_name}}</div>
          </a>
          <div class="fs-7 fw-semibold text-gray-400">{{date(attachment.created_at).format('DD/MM/YYYY HH:mm')}}</div>
          <div class="row mt-5">
            <div class="col-md-6">
              <button v-if="!readonly" type="button" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-toggle="tooltip" title="Excluir" @click="deleteFile(attachment.id_attachment)">
                <i class="bi bi-trash"></i>
              </button>
            </div>
            <div class="col-md-6">
              <button type="button" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-toggle="tooltip" title="Download" @click="downloadFile(attachment, true)">
                <i class="bi bi-download"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="modal_download" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" v-if="messagesDownload.showBtnClose">
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
               data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mb-6 mt-4">
            <div class="text-center px-5" :key="messagesDownload.image">
              <video width="200" height="200" autoplay playsinline muted v-if="messagesDownload.image.includes('.mp4')">
                <source :src="messagesDownload.image" type="video/mp4" />
              </video>
              <img v-else-if="messagesDownload.image" :src="messagesDownload.image" alt="Imagem" class="mw-100 h-200px h-sm-325px">
              <p v-html="messagesDownload.message"></p>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="messagesDownload.showBtnClose">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>

  <PdfViewer id="modal_pdf_viewer_attachment"  :pdf-source="this.pdfSource" :file-name="this.fileName" :key="'Attach:'+medicalRecord.id_medrec"></PdfViewer>

</template>

<script>
import 'dropzone-vue/dist/dropzone-vue.common.css';
import DropZone from "dropzone-vue";
import Config from "@/config";
import store from "@/store";
import http from "@/helpers/http";
import Alert from "@/helpers/alert";
import Download from "@/helpers/download";
import Utils from "@/helpers/utils";
import PdfViewer from "../shared/PdfViewer";
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from 'v-viewer'

export default {
  name: "Attachment",
  components: {
    DropZone,
    PdfViewer,
  },
  props: {
    medicalRecord: Object,
    schedule: Object,
    readonly: Boolean,
  },
  data() {
    return {
      headers: {
        'ubbi-app': Config.app_name,
        'ubbi-app-token': Config.app_token,
        'Authorization': `Bearer ${store.state.auth.token}`,
      },
      attachments: [],
      messagesDownload: {
        message: '',
        image: '',
        showBtnClose: false,
      },
      dropZoneKey: 0,
      date: moment,
      pdfSource: '',
      fileName: '',
      viewerImages: [],
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    this.attachments = this.medicalRecord.attachments;
  },
  methods: {
    getAttachments() {
      if (this.schedule == null)
        return;

      http.get(`/professionals/${this.schedule?.id_prof}/patients/${this.schedule?.id_patient}/medical-records/${this.medicalRecord?.id_medrec}/attachments`).then((response) => {
        this.attachments = response.data;
      });
    },
    endpointUpload() {
      return Config.base_url + `/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/attachments/upload`;
    },
    downloadFile(attachment, force = false) {

      let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_download' ));

      if ((attachment.file?.file_mime_type !== 'application/pdf' && attachment.file?.file_mime_type.substring(0,5) !== 'image') || force) {
        modal.toggle();

        this.messagesDownload = {
          message: 'Localizando documento, por favor, aguarde...',
          image: require('@/assets/mp4/8558-floating-document.mp4'),
          showBtnClose: false,
        }
      }

      http.get(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/attachments/${attachment.id_attachment}/download`).then(async (response) => {
        let data = response.data;

        if ( (attachment.file?.file_mime_type !== 'application/pdf' && attachment.file?.file_mime_type.substring(0,5) !== 'image') || force) {
          await new Promise(r => setTimeout(r, 1000));
          this.messagesDownload = {
            message: 'Baixando Documento ...',
            image: require('@/assets/mp4/97795-download-green.mp4'),
            showBtnClose: false,
          }

          Download.base64File(data.file_mime_type, data.file_base64, data.file_name);

          await new Promise(r => setTimeout(r, 1200));
          modal.toggle();

        } else if (attachment.file?.file_mime_type === 'application/pdf') {
          this.pdfSource = `data:application/pdf;base64,${data.file_base64}`;
          this.fileName  = data.file_name;
          return this.showPdfViewer();
        } else if (attachment.file?.file_mime_type.substring(0,5) === 'image') {
          this.viewerImages = [`data:${attachment.file?.file_mime_type};base64,${data.file_base64}`];
          this.fileName  = data.file_name;

          return this.showImageViewer();
        }
      }).catch((e) => {
        this.messagesDownload = {
          message: e.response?.data,
          image: require('@/assets/mp4/4386-connection-error.mp4'),
          showBtnClose: true,
        }
      });
    },
    deleteFile(attachmentId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o Anexo?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.schedule.id_prof}/patients/${this.schedule.id_patient}/medical-records/${this.medicalRecord.id_medrec}/attachments/${attachmentId}`).then(_ => {
            this.getAttachments();
            Alert.toastSuccess("Anexo excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    async uploaded() {
      Alert.toastSuccess('Arquivo enviado com sucesso.');
      this.getAttachments();
      await new Promise(r => setTimeout(r, 2000));
      this.dropZoneKey++;
    },
    getFileSvg(mimeType) {
      return Utils.getFileSvg(mimeType);
    },
    showPdfViewer() {
      console.log('oi2');
      let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_pdf_viewer_attachment'));
      modal.toggle();
    },
    initedImageViewer(imageViewer) {
      this.$imageViewer = imageViewer;
    },
    showImageViewer() {
      const $viewer = viewerApi({
        images: this.viewerImages,
        options: {
          fullscreen: false,
          toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            oneToOne: 1,
            reset: 1,
            prev: 0,
            play: {
              show: 1,
              size: 'large',
            },
            next: 0,
            rotateLeft: 4,
            rotateRight: 4,
            flipHorizontal: 4,
            flipVertical: 4,
          },
        }
      })
    },
    onErrorUpload(error) {
      console.log(error);
      Alert.toastError('Erro ao enviar o arquivo.');
    }
  },

}
</script>

<style scoped>

</style>