<template>
    <Toolbar />
  <div class="card">
    <div class="card-body py-4">

      <div class="text-center px-5">
        <div v-if="loading">
          <video width="400" height="400" autoplay loop playsinline muted>
            <source :src="require('@/assets/mp4/5271-loading-4.mp4')" type="video/mp4" />
          </video>
          <h3 class="text-muted">
            Configurando administradora de cartão <br>
            Aguarde ...
          </h3>
        </div>
        <div v-else-if="error">
          <video width="300" height="300" autoplay loop playsinline muted>
            <source :src="require('@/assets/mp4/4386-connection-error.mp4')" type="video/mp4" />
          </video>
          <h3 class="text-muted">
            Falha ao configurar administradora de cartão <br>
            Tente novamente ou entre em contato com o suporte técnico.
          </h3>
        </div>
        <div v-else>
          <video width="300" height="300" autoplay playsinline muted>
            <source :src="require('@/assets/mp4/96673-success.mp4')" type="video/mp4" />
          </video>
          <h3 class="text-muted">
            Administradora de cartão configurada com sucesso <br>
            Aguarde, estamos redirecionando você para o painel...
          </h3>
        </div>
      </div>
    </div>
  </div>

  <ModalWelcome v-if="showModalWelcome"
                :initialStep="'success'"
                :showModal="!place?.place_welcome" />
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import ModalWelcome from '../ModalWelcome';
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "CardAdministrator",
  components: {
    ModalWelcome,
      Toolbar,
  },
  data() {
    return {
      showModalWelcome: false,
      loading: true,
      error: false,
    }
  },
  created() {
    this.loginCredentials();
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  methods: {
    loginCredentials() {
      let cardAdminId = this.$route.query.state;
      let form = {
        code: this.$route.query.code
      };

      http.post(`/places/${this.place.id_place}/card-administrators/${cardAdminId}/credentials`, form).then(_ => {
        this.loading = false;
        Alert.toastSuccess('Administradora de cartão configurada com sucesso.');

        if (!this.place?.place_welcome) {
          this.showModalWelcome = true;
        } else {
          setTimeout(() => {
            this.$router.push('/card-administrators');
          }, 3000);
        }
      }).catch(e => {
        this.loading = false;
        this.error = true;
        Alert.toastError(e.response.data);
      });
    }
  }
}
</script>

<style scoped>

</style>