<template>
  <div v-if="!hasPermission('List Place Visit Value')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="col-xl-12 mb-xl-10">
          <div class="card h-md-100">
            <div class="card-body pt-5">
              <ul class="nav nav-pills nav-pills-custom mb-3">
                <li class="nav-item mb-3 me-3 me-lg-6" v-for="(kind, index) in kinds">
                  <a class="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden h-40px py-4"
                     data-bs-toggle="pill" :class="{ 'active': kind.id_kind === kindIdSelected }"
                     @click="setKindIdSelected(kind.id_kind, index)">
                    <span class="nav-text text-gray-700 fw-bolder fs-6 lh-1">{{ kind.kind_name }}</span>
                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                  </a>
                </li>
              </ul>
              <div v-if="kindIndexSelected !== null" class="tab-content">
                <div class="tab-pane fade active show" id="{{'tab_' + kindIndexSelected}}">
                  <div class="table-responsive">
                    <table class="table table-row-bordered align-middle gs-0 gy-4 my-0 table-hover">
                      <thead>
                      <tr class="fs-7 fw-bolder text-gray-500 border-bottom-0">
                        <th rowspan="2" class="ps-0 min-w-140px">Especialidade</th>
                        <template v-for="(policy, index) in kinds[kindIndexSelected].canceling_policies"
                                  :key="policy.id_policy_cancel">
                          <th colspan="2" class="text-center min-w-140px"
                              :class="{'bg-gray-200': index % 2 === 0,
                                     'bg-gray-300': index % 2 !== 0
                                    }">
                            {{ policy.policy_cancel_name }}
                          </th>
                        </template>
                      </tr>
                      <tr class="fs-7 fw-bolder text-gray-500 border-bottom-2">
                        <template v-for="(policy, index) in kinds[kindIndexSelected].canceling_policies"
                                  :key="policy.id_policy_cancel">
                          <th class="ps-0 text-center"
                              style="width: 150px;"
                              :class="{'bg-gray-200': index % 2 === 0,
                                     'bg-gray-300': index % 2 !== 0
                                    }">
                            Valor Padrão
                          </th>
                          <th class="ps-0 text-center"
                              style="width: 150px;"
                              :class="{'bg-gray-200': index % 2 === 0,
                                     'bg-gray-300': index % 2 !== 0
                                    }">
                            Valor Personalizado
                          </th>
                        </template>
                      </tr>
                      </thead>
                      <tbody v-for="(specialty,specIndex) in kinds[kindIndexSelected].specialties" :key="specialty.id_spec">
                      <tr :key="'row-'+specialty.id_spec">
                        <td class="ps-0">
                          <span @click="toggleExpand(specIndex)"><i class="bi ms-2 me-4"
                                                                    :class="isExpanded(specIndex) ? 'bi-dash-circle' : 'bi-plus-circle'"></i></span>
                          <span class="text-gray-800 fw-bolder text-hover-primary mb-1 fs-6 text-start pe-0">{{
                              specialty.spec_name
                            }}</span>
                        </td>
                        <template
                            v-for="(policy,policyIndex) in specialty.canceling_policies"
                            :key="policy.id_policy_cancel">
                          <td class="text-center min-w-140px"
                              :class="{'bg-gray-200': policyIndex % 2 === 0,
                                     'bg-gray-300': policyIndex % 2 !== 0
                                    }">
                            {{
                              'R$ ' + maskValue(policy.value_default)
                            }}
                          </td>
                          <td class="text-center min-w-140px pe-4"
                              :class="{'bg-gray-200': policyIndex % 2 === 0,
                                     'bg-gray-300': policyIndex % 2 !== 0
                                    }">
                            <money3
                                class="form-control form-control-sm text-center"
                                v-model="policy.value_place_spec"
                                v-bind="config"
                                @focus="beforeValue=policy.value_place_spec"
                                @blur="updateVisitValue(specialty.id_spec, policy.id_policy_cancel, null, policy.value_place_spec)"></money3>
                          </td>
                        </template>
                      </tr>
                      <tr v-for="(professional) in kinds[kindIndexSelected].specialties[specIndex].professionals"
                          :key="'prof-' + specialty.id_spec"
                          v-if="isExpanded(specIndex)">
                        <td class="ps-0">
                           <span class="text-gray-800 text-hover-primary mb-1 fs-6 pe-0 ms-10">{{
                               professional.prof_name
                             }}</span>
                        </td>
                        <template
                            v-for="(policy,policyIndex) in professional.canceling_policies"
                            :key="policy.id_policy_cancel">
                          <td class="text-center min-w-140px"
                              :class="{'bg-gray-200': policyIndex % 2 === 0,
                                     'bg-gray-300': policyIndex % 2 !== 0
                                    }">
                            {{
                              'R$ ' + maskValue(kinds[kindIndexSelected].specialties[specIndex].canceling_policies[policyIndex].value_default)
                            }}
                          </td>
                          <td class="text-center min-w-140px"
                              :class="{'bg-gray-200': policyIndex % 2 === 0,
                                     'bg-gray-300': policyIndex % 2 !== 0
                                    }">
                            <money3
                                class="form-control form-control-sm text-center"
                                v-model="policy.value_place_prof"
                                v-bind="config"
                                @focus="beforeValue=policy.value_place_prof"
                                @blur="updateVisitValue(specialty.id_spec, policy.id_policy_cancel, professional.id_prof, policy.value_place_prof)">
                            </money3>
                          </td>
                        </template>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Permission from "../../helpers/permission";
import Input from "../attendance/shared/Input";
import {Money3Component} from 'v-money3'
import Alert from "../../helpers/alert";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "VisitValues",
  components: {Input, money3: Money3Component, Toolbar,},
  data() {
    return {
      kinds: [],
      kindIdSelected: '',
      kindIndexSelected: null,
      beforeValue: 0,
      config: {
        masked: false,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        disableNegative: true,
        disabled: !this.hasPermission('Update Place Visit Value'),
        min: null,
        max: null,
        allowBlank: true,
        minimumNumberOfCharacters: 0,
        shouldRound: false,
        focusOnRight: false,
      },
      expandedRows: []
    };
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    this.loadVisitValues();
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadVisitValues() {
      http.get(`/places/${this.place.id_place}/visit-values`).then((response) => {
        this.kinds = response.data;
        if (this.kinds.length > 0) {
          this.setKindIdSelected(this.kinds[0].id_kind, 0);
        }
      });
    },
    setKindIdSelected(kindId, index) {
      this.expandedRows = [];
      this.kindIdSelected = kindId;
      this.kindIndexSelected = index;
    },
    maskValue(value) {
      if (!value) return '';
      const formatedValue = Number(value).toFixed(2);
      return formatedValue.replace('.', ',')
    },
    updateVisitValue(specId, policyId, profId, value) {
      if (this.beforeValue !== value) {
        http.put(`/places/${this.place.id_place}/visit-values`, {
          id_spec: specId,
          id_policy_cancel: policyId,
          id_prof: profId,
          value: value
        }).then(_ => Alert.toastSuccess('Valor atualizado com sucesso')
        ).catch(e => {
          Alert.toastError(e.response.data.message ?? e.response.data);
        });
      }
    },
    toggleExpand(specIndex) {
      const index = this.expandedRows.indexOf(specIndex);
      if (index > -1) {
        this.expandedRows.splice(index, 1);
      } else {
        this.expandedRows.push(specIndex);
      }
    },
    isExpanded(specIndex) {
      return this.expandedRows.includes(specIndex);
    },
  },
}
</script>

<style scoped>
</style>