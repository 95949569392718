<template>
  <div class="card w-100  mt-4" :class="deleted ? 'bg-light-danger' : 'bg-light-warning'" style="position: relative;">
    <div class="card-body m-3 p-4">
      <label v-if="!deleted && canDelete" class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-light-warning shadow" data-bs-toggle="tooltip" title="excluir"  style="position: absolute; top: -5px; right: -5px;">
        <i class="bi bi-trash-fill fs-7" @click="emitDeleteEvent"></i>
      </label>
      <h5 class="card-title">
          <span class="card-label">{{ title }}</span>
      </h5>
      <textarea v-if="!readonly" class="form-control border-0 bg-light-warning"
                v-model="localContent" ref="localContent"
                @input="autoResize"></textarea>
      <p v-else class="card-text" :style="{textDecoration: deleted ? 'line-through' : 'none'}">{{ localContent }}</p>
      <div class="text-end mt-2">
        <button v-if="!readonly" @click="updateContent" class="btn btn-sm btn-primary">Salvar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostIt',
  props: {
    title: String,
    content: String,
    readonly: Boolean,
    deleted: Boolean,
    canDelete: Boolean,
  },
  emits: ['onDeleteClick', 'onStoreClick'],
  data() {
    return {
      localContent: this.content,
    };
  },
  watch: {
    content(newVal) {
      this.localContent = newVal;
    },
  },
    mounted() {
      if (!this.readonly) {
          this.$refs.localContent.focus();
      }
    },
    methods: {
    emitDeleteEvent() {
      this.$emit('onDeleteClick');
    },
    updateContent() {
      this.$emit('onStoreClick', this.localContent);
    },
    autoResize(event) {
      event.target.style.height = 'auto';
      event.target.style.height = event.target.scrollHeight + 'px';
    },
  },
};
</script>

<style scoped>
.btn-close {
  margin-right: -5px; /* Adjust this value as needed to center the button */
}
</style>
