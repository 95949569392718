import { useToast } from 'vue-toastification'
const toast = useToast()

export default class Alert {

    static success(message, title = null) {
        Swal.fire({
            title: title || 'Aeeeh!',
            html: message,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#780f5a',
            timer: 1200,
        });
    }

    static error(message, title = null) {
        Swal.fire({
            title: title || 'Opsss!',
            html: message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#780f5a',
        })
    }

    static toastSuccess(message) {
        toast.success(message);
    }

    static toastInfo(message) {
        toast.info(message);
    }

    static toastError(message) {
        toast.error(message);
    }

    static toastwarning(message) {
        toast.warning(message);
    }
}