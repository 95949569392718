<template>
  <div :key="uniqueKey"
       class="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold" id="#kt_aside_menu" data-kt-menu="true">
    <div class="menu-item">
      <router-link to="/dashboard" class="menu-link" title="Visualizar informações">
        <span class="menu-icon">
          <i class="bi bi-grid fs-3"></i>
        </span>
        <span class="menu-title">Painel</span>
      </router-link>
    </div>

    <div class="menu-item">
      <router-link to="/schedules" class="menu-link" title="Agenda"
                   v-if="hasPermission('List Schedule')">
        <span class="menu-icon">
          <i class="bi bi-calendar2-week fs-3"></i>
        </span>
        <span class="menu-title">Agenda</span>
      </router-link>
    </div>

    <div class="menu-item" v-if="userAuth.id_prof">
        <router-link to="/attendances" class="menu-link" title="Atendimentos">
      <span class="menu-icon">
        <i class="bi bi-clipboard2-pulse fs-3"></i>
      </span>
            <span class="menu-title">Atendimento</span>
        </router-link>
    </div>

    <div class="menu-item">
      <router-link to="/patients" class="menu-link" title="Meus pacientes"
                   v-if="hasPermission('List Patient')">
        <span class="menu-icon">
          <i class="bi bi-people fs-3"></i>
        </span>
        <span class="menu-title">Pacientes</span>
      </router-link>
    </div>

    <div data-kt-menu-trigger="click" data-kt-menu-placement="right-start" class="menu-item py-3">
      <span class="menu-link menu-center" title="Protocolos">
        <span class="menu-icon me-0">
          <i class="bi bi-collection fs-2"></i>
        </span>
        <span class="menu-title">Protocolos</span>
      </span>
      <div class="menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4">
        <div class="menu-item">
          <div class="menu-content">
            <span class="menu-section fs-5 fw-bolder ps-1 py-1">Meus Protocolos</span>
          </div>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/record-types"
                       v-if="hasPermission('List Record Professional')"
                       title="Tipos de Prontuário">
              <span class="menu-icon">
                <i class="bi bi-clipboard2-pulse"></i>
              </span>
            <span class="menu-title">Tipos de Prontuário</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/prescription-protocols"
                       v-if="hasPermission('List Prescription Protocol')"
                       title="Protocolos de Prescrições">
              <span class="menu-icon">
                <i class="bi bi-file-medical"></i>
              </span>
            <span class="menu-title">Protocolos de Prescrições</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/exam-protocols"
                       v-if="hasPermission('List Exam Protocol')"
                       title="Protocolos de Exames">
              <span class="menu-icon">
                <i class="bi bi-journal-medical"></i>
              </span>
            <span class="menu-title">Protocolos de Exames</span>
          </router-link>
        </div>
      </div>
    </div>

    <div data-kt-menu-trigger="click" data-kt-menu-placement="right-start" class="menu-item py-3">
      <span class="menu-link menu-center" title="Meus Cadastros">
        <span class="menu-icon me-0">
          <i class="bi bi-archive fs-2"></i>
        </span>
        <span class="menu-title">Cadastros</span>
      </span>
      <div class="menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4">
        <div class="menu-item">
          <div class="menu-content">
            <span class="menu-section fs-5 fw-bolder ps-1 py-1">Meus Cadastros</span>
          </div>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/users"
             v-if="hasPermission('List User')"
             title="Usuários">
              <span class="menu-icon">
                <i class="bi bi-box2-heart"></i>
              </span>
            <span class="menu-title">Usuários</span>
          </router-link>
        </div>
        <div class="menu-item" v-if="hasPermission('List Professional')">
          <router-link class="menu-link" to="/professionals"
             title="Dados cadastrais do profissional">
              <span class="menu-icon">
                <i class="bi bi-people"></i>
              </span>
            <span class="menu-title">Profissionais</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/place"
             v-if="hasPermission('List Place')"
             title="Dados cadastrais do local de atendimento">
              <span class="menu-icon">
                <i class="bi bi-shop-window"></i>
              </span>
            <span class="menu-title">Local de atendimento</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/payment-methods"
             v-if="hasPermission('List PaymentMethod')"
             title="Formas de recebimento">
              <span class="menu-icon">
                <i class="bi bi-cash-coin"></i>
              </span>
            <span class="menu-title">Formas de recebimento</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/visit-values"
                       v-if="hasPermission('List Place Visit Value')"
                       title="Tabela de Valores">
              <span class="menu-icon">
                <i class="bi bi-cash-coin"></i>
              </span>
            <span class="menu-title">Tabela de Valores</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/exams"
                       v-if="hasPermission('List Place Exams')"
                       title="Exames">
              <span class="menu-icon">
                <i class="bi bi-activity"></i>
              </span>
            <span class="menu-title">Exames</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/specialties"
                       v-if="hasPermission('List Place Specialty')"
                       title="Exames">
              <span class="menu-icon">
                <i class="bi bi-mortarboard"></i>
              </span>
            <span class="menu-title">Especialidades</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/access-types"
                       v-if="hasPermission('List Place Access Type')"
                       title="Permissões">
              <span class="menu-icon">
                <i class="bi bi-cash-coin"></i>
              </span>
            <span class="menu-title">Permissões</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/insurances"
             v-if="hasPermission('List Place Insurance')"
             title="Convênios">
              <span class="menu-icon">
                <i class="bi bi-building"></i>
              </span>
            <span class="menu-title">Convênios Atendidos</span>
          </router-link>
        </div>
        <div class="menu-item">
          <router-link class="menu-link" to="/holidays"
                       v-if="hasPermission('List Place Holiday')"
                       title="Feriados">
              <span class="menu-icon">
                <i class="bi bi-calendar-x"></i>
              </span>
            <span class="menu-title">Feriados</span>
          </router-link>
        </div>
      </div>
    </div>

   </div>
</template>

<script>

import Permission from "../../../helpers/permission";
export default {
    name: "Menu",
    data() {
        return {
            uniqueKey: this.date().unix().toString(),
        }
    },
    computed: {
        userAuth() {
            return this.$store.state.auth.user || {};
        },
    },
    watch: {
        '$store.state.auth.permissions': {
            handler(newPermissions) {
                this.uniqueKey = this.date().unix().toString()
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        date: moment,
        hasPermission(permission) {
            return Permission.has(permission);
        },
    },
}
</script>

<style scoped>

</style>