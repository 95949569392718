<template>
  <div class="modal fade" tabindex="-1" id="modal_professionals">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Profissionais por exame <br>
              <span>{{ specialty?.spec_name }}</span>
            </h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">

              <div class="col-md-12">
                <input type="text" class="form-control" v-model="searchTerm"
                       placeholder="Pesquisar profissional">
              </div>

              <div class="col-md-12">
                <table class="table table-row-bordered gy-5">
                  <thead>
                  <tr class="fw-bold fs-6 text-muted">
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>CPF</th>
                    <th>Ações</th>
                  </tr>
                  </thead>
                  <tbody v-for="(professional) in filteredProfessionals(newProfessionals)">
                  <tr>
                    <td>
                      <span class="text-gray-800 fw-bolder text-hover-primary fs-6 text-start pe-0 me-5">
                        {{ professional.prof_name }}
                      </span>
                    </td>
                    <td>
                      {{ professional.prof_email }}
                    </td>
                    <td>
                      {{ professional.prof_cpf }}
                    </td>
                    <td>
                      <button class="btn btn-sm btn-danger me-1" v-if="professional.available_to_patient !== null"
                              @click="removeSpec(professional)" data-bs-toggle="collapse"
                              :data-bs-target="'#subTable' + professional.id_prof" aria-expanded="false"
                              title="Adicionar" type="button">
                        <i class="bi bi-plus-circle"></i> Remover configuração
                      </button>
                      <button class="btn btn-sm btn-success me-1" v-else
                              @click="professional.available_to_patient = true"
                              :data-bs-target="'#subTable' + professional.id_prof" aria-expanded="false"
                              title="Adicionar" type="button" data-bs-toggle="collapse">
                        <i class="bi bi-plus-circle"></i> Configurar
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12" :id="'subTable' + professional.id_prof" class="collapse" :class="professional.available_to_patient !== null ? 'show' : ''">
                      <div>
                        <div class="col-md-6">
                          <div class="mb-10">
                            <label for="interval_minutes" class="required form-label">
                              Tempo médio de atendimento <small>(Em minutos)</small>
                            </label>
                            <input type="number" placeholder="Ex. 30" v-model="professional.interval_minutes"
                                   class="form-control form-control-solid">
                            <small>(não preencher se para usar configuração global)</small>
                          </div>
                        </div>
                        <div class="row col-md-12">
                          <div class="col-md-6">
                            <div class="mb-10">
                              <label for="min_patient_age" class="form-label">
                                Idade minima de atendimento <small>(em anos)</small>
                              </label>
                              <input type="number" placeholder="Ex. 0" v-model="professional.min_patient_age"
                                     class="form-control form-control-solid">
                              <small>(não preencher se para usar configuração global)</small>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-10">
                              <label for="max_patient_age" class="form-label">
                                Idade máxima de atendimento" <small>(em anos)</small>
                              </label>
                              <input type="number" placeholder="Ex. 120" v-model="professional.max_patient_age"
                                     class="form-control form-control-solid">
                              <small>(não preencher se para usar configuração global)</small>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <fieldset class="border p-3 rounded">
                            <legend class="w-auto px-2 fs-6 fw-bold mb-4">
                              Disponível para agendamento pelo paciente?
                            </legend>
                            <br>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                id="spec_available_to_patient_true"
                                :value="true"
                                v-model="professional.available_to_patient"
                              />
                              <label for="spec_available_to_patient_true" class="form-check-label">Disponível</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                id="spec_available_to_patient_false"
                                :value="false"
                                v-model="professional.available_to_patient"
                              />
                              <label for="spec_available_to_patient_false" class="form-check-label">Indisponível</label>
                            </div>
                          </fieldset>
                        </div>

                        <div v-if="JSON.stringify(professionals.find((p) => p.id_prof === professional.id_prof)) !== JSON.stringify(professional)">
                          <button type="button" class="btn btn-light me-2" @click="cancelUpdate(professional)">
                            Cancelar
                          </button>
                          <button type="button" class="btn btn-primary" @click="updateSpec(professional)">
                            Salvar
                          </button>
                        </div>

                        <hr>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Modal from "../../helpers/modal";

export default {
  name: "ModalProfessionals",
  props: ['specialty'],
  data() {
    return {
      searchTerm: '',
      newProfessionals: [],
      professionals: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    Modal.onShow('#modal_professionals', () => this.loadProfessionals());
  },
  methods: {
    filteredProfessionals(professionals) {
      if (!this.searchTerm) {
        return professionals;
      }

      return professionals.filter(professional =>
        professional.prof_name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    loadProfessionals() {
      http.get(`/places/${this.place.id_place}/kinds/${this.specialty.id_kind}/exams/${this.specialty.id_spec}/professionals`).then((response) => {
        this.professionals = response.data;
        this.newProfessionals = this.professionals.map(item => ({...item}));
      });
    },
    removeSpec(professional) {
      let index = this.professionals.findIndex((p) => p.id_prof === professional.id_prof);

      professional.available_to_patient = null;
      this.newProfessionals[index].available_to_patient = null;
      this.updateSpec(index);
    },
    updateSpec(professional) {
      let index = this.professionals.findIndex((p) => p.id_prof === professional.id_prof);

      const makeRequest = () => {
        http.put(`/places/${this.place.id_place}/professionals/${this.newProfessionals[index].id_prof}/specialties/${this.specialty.id_spec}`, this.newProfessionals[index]).then(_ => {
          this.loadProfessionals();
          Alert.toastSuccess("Registro atualizado com sucesso.");
        }).catch(e => {
          Alert.toastError(e.response.data.message ?? e.response.data);
        });
      }

      if (this.professionals[index].interval_minutes != null && this.newProfessionals[index].interval_minutes !== this.professionals[index].interval_minutes) {
        Swal.fire({
          title: 'Intervalo de atendimento',
          text: "Gostaria de ajustar o tempo médio de atendimento dos agendamentos futuros?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, ajustar!',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: 'btn btn-secondary'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.newProfessionals[index].update_sched_new_interval_minutes = true;
          }

          makeRequest()
        });
      } else {
        makeRequest();
      }
    },
    cancelUpdate(professional) {
      let index = this.professionals.findIndex((p) => p.id_prof === professional.id_prof);
      this.newProfessionals[index] = {...this.professionals[index]};
    },
  }
}
</script>

<style scoped>

</style>