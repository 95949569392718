<script>
export default {
    name: "BusyRateLegend"
}
</script>

<template>
    <div><span class="badge mx-1" :style="{backgroundColor: '#198754', padding: '.5em .5em'}">Até 25%</span></div>
    <div><span class="badge" :style="{color: 'black', backgroundColor: '#ffc107', padding: '.5em .5em'}">Até 50%</span></div>
    <div><span class="badge mx-1" :style="{backgroundColor: '#fd7e14', padding: '.5em .5em'}">Até 75%</span></div>
    <div><span class="badge" :style="{backgroundColor: '#dc3545', padding: '.5em .5em'}">Até 99%</span></div>
    <div><span class="badge mx-1" :style="{backgroundColor: '#6f42c1', padding: '.5em .5em'}">100%</span></div>
</template>

<style scoped>

</style>