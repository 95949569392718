<script>
export default {
  name: "ScrollUpDown",
  data() {
    return {
      isPageDown: false,
    }
  },
  methods: {
    handleScroll() {
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (this.isPageDown) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: scrollHeight - clientHeight, behavior: 'smooth' });
      }

      this.isPageDown = !this.isPageDown;
      this.$emit('onPageDownUp');
    },
  }
}
</script>

<template>
  <button class="btn btn-primary scroll-button" style="opacity: 0.7;"
          :title="isPageDown ? 'Ir para o começo da página' : 'Ir para final da pagina'"
          @click="handleScroll">
    <i class="bi bi-arrow-up-circle" v-show="isPageDown"></i>
    <i class="bi bi-arrow-down-circle" v-show="!isPageDown"></i>
  </button>
</template>

<style>
  .scroll-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
</style>