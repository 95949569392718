<template>
  <div v-if="!hasPermission('List Place Holiday')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                    transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
              <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="black"></path>
            </svg>
          </span>
            <input type="text" data-kt-user-table-filter="search" v-model="search"
                   class="form-control form-control-solid w-250px ps-14"
                   placeholder="Pesquisar ...">
          </div>
        </div>
        <div class="card-toolbar">
          <div class="col-md-6">
            <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                    title="Novo feriado" data-bs-target="#modal_store"
                    v_if="hasPermission('Store Place Holiday')"
                    @click="resetForm()">
              <i class="bi bi-plus-lg"></i>Adicionar
            </button>
          </div>
        </div>
      </div>
      <div class="card-body py-4">
        <table class="table table-row-bordered gy-5">
          <thead>
          <tr class="fw-bold fs-6 text-muted">
            <th>Data</th>
            <th>Nome do Feriado</th>
            <th>Padrão?</th>
            <th>Ativo?</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="holiday in holidaysFiltered">
            <td>{{ date(holiday.place_holiday_date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
            <td>{{ holiday.place_holiday_name }}</td>
            <td>
              <span class="badge badge-light-success" v-if="holiday.default_holiday">Sim</span>
              <span class="badge badge-light-danger" v-else>Não</span>
            </td>
            <td>
              <span class="badge badge-light-success" v-if="holiday.place_holiday_active">Sim</span>
              <span class="badge badge-light-danger" v-else>Não</span>
            </td>
            <td>
              <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      @click="deleteHoliday(holiday.id_place_holiday)"
                      v-if="(!holiday.default_holiday) && hasPermission('Delete Place Holiday')"
                      data-bs-toggle="tooltip" title="Excluir">
                <i class="bi bi-trash"></i>
              </button>
              <button type="button" @click="onClickBtnEdit(holiday)"
                      class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      data-bs-target="#modal_edit" data-bs-toggle="modal"
                      v-if="hasPermission('Update Place Holiday')"
                      title="Editar">
                <i class="bi bi-pen"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="modal_store">
      <div class="modal-dialog modal-lg">
        <form method="post" @submit.prevent="submitFormStore">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Adicionar Feriado</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                   data-bs-dismiss="modal" aria-label="Fechar">
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-4">
                  <label class="fs-6 fw-bold mb-2 required">Data do Feriado</label>
                  <Calendar class="form-control form-control-sm form-control-solid" v-model="form.place_holiday_date"
                            dateFormat="dd/mm/yy"></Calendar>
                </div>
                <div class="col-md-6">
                  <label class="fs-6 fw-bold mb-2 required">Nome do Feriado</label>
                  <input type="text" class="form-control form-control-solid"
                         placeholder="Ex. Natal" v-model="form.place_holiday_name"/>
                </div>
                <div class="col-md-2 mt-10">
                  <label class="form-check form-switch form-check-custom form-check-solid form-check-inline">
                    <input class="form-check-input" type="checkbox" name="place_holiday_active"
                           v-model="form.place_holiday_active"/>
                    <span class="form-check-label fw-bold text-gray-700 fs-6">
                  Ativo?
                </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="submit" class="btn btn-primary">Cadastrar</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="modal_edit">
      <div class="modal-dialog modal-lg">
        <form method="post" @submit.prevent="submitFormUpdate">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Editar administradora de cartão</h5>
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                   data-bs-dismiss="modal" aria-label="Fechar">
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-4">
                  <label class="fs-6 fw-bold mb-2 required">Data do Feriado</label>
                  <Calendar class="form-control form-control-sm form-control-solid" v-model="form.place_holiday_date"
                            dateFormat="dd/mm/yy" :readonly="form.default_holiday"></Calendar>
                </div>
                <div class="col-md-6">
                  <label class="fs-6 fw-bold mb-2 required">Nome do Feriado</label>
                  <input type="text" class="form-control form-control-solid"
                         placeholder="Ex. Natal" v-model="form.place_holiday_name" :readonly="form.default_holiday"/>
                </div>
                <div class="col-md-2 mt-10">
                  <label class="form-check form-switch form-check-custom form-check-solid form-check-inline">
                    <input class="form-check-input" type="checkbox" name="place_holiday_active"
                           v-model="form.place_holiday_active"/>
                    <span class="form-check-label fw-bold text-gray-700 fs-6">
                  Ativo?
                </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
              <button type="submit" class="btn btn-primary">Atualizar</button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Permission from '../../helpers/permission';
import {mask} from "vue-the-mask";
import Calendar from "primevue/calendar";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Holidays",
  directives: {
    mask,
  },
  components: {
    Calendar,
      Toolbar,
  },
  data() {
    return {
      search: '',
      holidays: [],
      form: {
        id_place_holiday: '',
        id_place: '',
        place_holiday_name: '',
        place_holiday_date: '',
        place_holiday_active: true,
      },
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    holidaysFiltered() {
      return this.holidays.filter(holiday => {
        return holiday.place_holiday_name.toLowerCase().includes(this.search.toLowerCase())
      });
    },
  },
  mounted() {
    this.loadHolidays();
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadHolidays() {
      http.get(`/places/${this.place.id_place}/holidays`).then((response) => {
        this.holidays = response.data;
      });
    },
    onClickBtnEdit(holiday) {
      this.form = {
        id_place: holiday.id_place,
        id_place_holiday: holiday.id_place_holiday,
        place_holiday_name: holiday.place_holiday_name,
        place_holiday_date: this.date(holiday.place_holiday_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        place_holiday_active: holiday.place_holiday_active,
        default_holiday: holiday.default_holiday
      };
    },
    submitFormUpdate() {
      this.form.id_place = this.place.id_place;
      http.put(`/places/${this.place.id_place}/holidays/${this.form.id_place_holiday}`, this.form).then(_ => {
        this.loadHolidays();
        Alert.toastSuccess("Feriado atualizado com sucesso.");

        this.resetForm();

        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_edit')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
    submitFormStore() {
      this.form.id_place = this.place.id_place;
      http.post(`/places/${this.place.id_place}/holidays`, this.form).then(_ => {
        this.loadHolidays();
        Alert.toastSuccess("Feriado cadastrado com sucesso.");

        this.resetForm();

        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_store')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
    deleteHoliday(placeHolidayId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o feriado?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secondary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/holidays/${placeHolidayId}`).then(_ => {
            this.loadHolidays();

            Alert.toastSuccess("Feriado excluído com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    resetForm() {
      this.form = {
        id_place: '',
        id_place_holiday: '',
        place_holiday_name: '',
        place_holiday_date: '',
        place_holiday_active: true,
        default_holiday: false
      };
    },
  }
}

</script>

<style scoped>

</style>