import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './websocket';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import PrimeVue from 'primevue/config';

import languageBr from './assets/pt-br'

createApp(App)
    .use(store)
    .use(router)
    .use(Toast)
    .use(PrimeVue, {
        locale: languageBr["pt-br"],
        zIndex: {
            modal: 1056,        //dialog, sidebar
            overlay: 1056,      //dropdown, overlaypanel
            menu: 1056,         //overlay menus
            tooltip: 1056       //tooltip
        }
    })
    .mount('#kt_body')
