<template>
  <div id="kt_header" style="" class="header align-items-stretch">
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
      <div class="d-flex align-items-center d-lg-none ms-n1 me-2" title="Mostrar menu">
        <div class="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
             id="kt_aside_mobile_toggle">
          <span class="svg-icon svg-icon-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="black"/>
              <path opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="black"/>
            </svg>
          </span>
        </div>
      </div>
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a @click.prevent href="#" class="d-lg-none">
          <img alt="Logo" :src="require('@/assets/images/logo_ubbi.png')" class="h-30px"/>
        </a>
      </div>


      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu"
               data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
               data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end"
               data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true"
               data-kt-swapper-mode="prepend"
               data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">

            <router-link to="/places" class="text-primary fw-bold cursor-pointer mt-2" style="font-size: 1.1rem;">
              <img class="mh-lg-55px me-4" :src="place?.place_logo??require('@/assets/images/no-photo.png')"
                   alt="image">
              {{ place?.place_logo ? '' : place?.place_name }}
            </router-link>
          </div>
        </div>

        <div class="d-flex align-items-stretch flex-shrink-0">
          <div class="d-flex align-items-center ms-1 ms-lg-3">
            <button type="button" class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                    title="Copiar link do profissional" @click="getLinkProfessional(userAuth.id_prof)"
                    data-bs-toggle="modal" data-bs-target="#modal-whatsapp">
              <i class="bi bi-whatsapp fs-2"></i>
            </button>
          </div>
          <div class="d-flex align-items-center ms-1 ms-lg-3" v-if="userAuth.id_prof">
            <router-link to="/documents" title="Documentos do profissional"
                         class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px">
              <i class="bi bi-file-earmark-person-fill fs-2"></i>
            </router-link>
          </div>
          <div class="d-flex align-items-center ms-1 ms-lg-3" v-if="false">
            <div
                class="btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px pulse pulse-success"
                id="kt_drawer_chat_toggle">
              <i class="bi bi-app-indicator fs-2"></i>
              <span class="pulse-ring w-45px h-45px"></span>
            </div>
          </div>
          <div class="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
            <div class="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click"
                 data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
              <img :src="url(userAuth.user_photo)" v-if="userAuth.user_photo" alt="Minha foto">
              <img :src="require('@/assets/images/no-photo.png')" alt="image" v-else/>
            </div>
            <div
                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                data-kt-menu="true">
              <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                  <div class="symbol symbol-50px me-5">
                    <img :src="url(userAuth.user_photo)" v-if="userAuth.user_photo"
                         alt="Minha foto">
                    <img :src="require('@/assets/images/no-photo.png')" alt="image" v-else/>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">{{ userAuth.first_name }}
                      <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span>
                    </div>
                    <a @click.prevent href="#" class="fw-bold text-muted text-hover-primary fs-7">
                      {{ userAuth.user_email }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="separator my-2"></div>
              <div class="menu-item px-5">
                <router-link to="/places" class="menu-link px-5">Locais</router-link>
              </div>
              <div class="menu-item px-5">
                <router-link to="/profile" class="menu-link px-5">Meu Perfil</router-link>
              </div>
              <div class="menu-item px-5 my-1">
                <router-link to="/change-password" class="menu-link px-5">
                  Trocar senha
                </router-link>
              </div>
              <div class="menu-item px-5">
                <a href="#" @click.prevent="logout" class="menu-link px-5">Sair</a>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center d-none ms-2" title="Show header menu">
            <div class="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                 id="kt_header_menu_mobile_toggle">
              <span class="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                        fill="black"/>
                    <path opacity="0.3"
                          d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                          fill="black"/>
                  </svg>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="modal-whatsapp">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Recomendações para o WhatsApp Bussiness</h5>
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
               data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <br>
              <p>
                <b>
                  Quer reduzir suas faltas e aumentar seu número de pacientes? <br>
                  Permita que seus pacientes agendem consultas com você via WhatsApp, 24 horas por dia, 7 dias por
                  semana.
                </b>
              </p>
              <br> <br>
              <p>Vamos configurar seu WhatsApp Bussiness e vincular à sua página da Ubbimed.</p>

              <ol>
                <li>
                  Abra o seu Aplicativo do WhatsApp Bussiness
                </li>
                <li>
                  Clique no ícone <i class="bi bi-three-dots-vertical"></i> e depois em <b>Configurações</b>
                </li>
                <li>
                  Clique em <b>Ferramentas Comerciais</b>
                </li>
                <li>
                  <b>Mensagem de ausência</b>
                </li>
                <li>
                  <p>Copie e cole a mensagem abaixo no campo de resposta automatica.</p>
                  <textarea rows="5" class="form-control" v-model="messageWithWhatsAppLink"></textarea>
                  <br>
                  <button class="btn btn-primary" @click="copyLinkOrMessageLink(true)">
                    Copiar mensagem
                  </button>
                  <br>
                  <br>
                </li>
                <li>
                  Em <b>Horário Programado</b> selecione a opção <b>Enviar Sempre</b>.
                  <br>
                  Adicione a <b>data de Início</b> e <b>data de Término</b>.
                  <br>
                  Em <b>Destinatário</b> selecione a opção <b>Todos</b>
                </li>
              </ol>

              <p>Pronto! Agora sua agenda ficará visível para os seus pacientes 24h por dia, 7 dias por semana 😉</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-success" @click="copyLinkOrMessageLink(false)">
            Copiar link
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/helpers/http";
import Alert from "@/helpers/alert";
import str from "../../../helpers/str";

export default {
  name: "Header",
  data() {
    return {
      messageWithWhatsAppLink: `Olá,
Agradecemos pelo contato.
Agora você pode agendar sua consulta direto do seu celular.
Para isso, clique no link abaixo:
`,
    }
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user || {};
    },
    place() {
      return this.$store.state.auth.place;
    },
  },
  methods: {
      url(path) {
          return str.url(path)
      },
    getLinkProfessional(professionalId) {
      if (!professionalId)
        return;

      return http.get(`/places/${this.place.id_place}/professionals/${professionalId}/links/profile`).then((r) => {
        this.messageWithWhatsAppLink = this.messageWithWhatsAppLink.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
        this.messageWithWhatsAppLink = this.messageWithWhatsAppLink + r.data;

        return r;
      });
    },
    async copyLinkOrMessageLink(withMessage = false) {
      let professionals = this.$store.state.auth.professionals;
      let professionalId = professionals[0].id_prof;

      if (professionals.length > 1) {
        let inputOptions = [];
        professionals.forEach((professional) => {
          inputOptions[[professional.id_prof]] = professional.prof_name;
        });

        const {value: prof} = await Swal.fire({
          title: 'Profissional',
          input: 'select',
          inputOptions: inputOptions,
          inputPlaceholder: 'Selecione o profissional',
          showCancelButton: false,
          confirmButtonText: '<i class="bi bi-clipboard-check-fill text-white"></i> Copiar',
          inputValidator: (value) => {
            if (!value) {
              return 'Informe o profissional.'
            }
          }
        });

        professionalId = prof;
      }

      if (!professionalId || professionalId === 'undefined')
        return;

      console.log(withMessage)
      this.getLinkProfessional(professionalId).then((response) => {
        if (withMessage) {
          navigator.clipboard.writeText(this.messageWithWhatsAppLink);
          Alert.toastInfo("Mensagem copiada com sucesso.");

          return;
        }

        let link = response.data;
        navigator.clipboard.writeText(link);

        Alert.toastInfo("Link copiado com sucesso.")
      });
    },
    logout() {
      Swal.fire({
        title: 'Sair',
        text: "Tem certeza que deseja sair?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#780f5a',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Sair!',
        cancelButtonText: "Não"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push('/');
          });
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
