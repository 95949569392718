<template>
  <div class="modal fade" tabindex="-1" :id="'modal_store_exam_' + idModal">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submit">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Adicionar exame</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <InputSearchExam v-model="searchExam" @onSetSearchExam="this.setSearchExam" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Cadastrar</button>
          </div>
        </div>
      </form>
    </div>
  </div> <!-- modal_store_exam -->
</template>

<script>
import InputSearchExam from "./InputSearchExam";

export default {
  name: "SearchExam",
  components: {
    InputSearchExam
  },
  props: {
    examRequest: Object,
    idModal: null,
  },
  data() {
    return {
      formStoreExam: {
        id_exam_request: '',
        id_exam: '',
      },
      searchExam: '',
    }
  },
  methods: {
    submit() {
      this.$emit('onSubmitFormStoreExam', this.formStoreExam);
      this.formStoreExam = {
        id_exam_request: '',
        id_exam: '',
      };

      this.searchExam = '';
    },
    setSearchExam(exam) {
      this.formStoreExam.id_exam = exam.id_exam;
    },
  },
}
</script>

<style scoped>

</style>