<script>
import Dropdown from "primevue/dropdown";

export default {
    name: "DropdownUbbi",
    props: ['options', 'filter', 'optionLabel', 'optionValue', 'id', 'placeholder', 'showClear'],
    emits: ['change', 'update:modelValue'],
    components: {
        Dropdown
    },
}
</script>

<template>
    <Dropdown :options="options"
              :filter="filter"
              :showClear="showClear"
              @change="$emit('change', $event.value); $emit('update:modelValue', $event.value)"
              :optionLabel="optionLabel"
              :optionValue="optionValue"
              empty-message="Nenhuma opção"
              empty-filter-message="Nenhuma opção encontrada"
              :id="id"
              class="form-control form-control-solid w-full md:w-14rem"
              :placeholder="placeholder"
              style="display: flex; padding: 0.25rem .75rem"
    />
</template>

<style>
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0.75rem !important;
}
.p-dropdown-trigger {
    margin-right: -14px;
}
</style>