<template>
  <div class="card mb-5 mb-xl-8">
    <div class="card-header">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder text-gray-800">Paciente</span>
      </h3>
      <div class="card-toolbar text-end">
        <button type="button" class="btn btn-sm" @click="toggleExpandPatientData">
          <i class="bi bi-arrow-left-circle"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex flex-center flex-column mb-5">
        <slot name="image" />
        <div class="symbol symbol-100px symbol-circle mb-7 pt-15" v-if="!$slots.image">
          <img :src="url(patient?.patient_photo)" v-if="patient?.patient_photo" alt="Foto do patiente">
          <img :src="require('@/assets/images/no-photo.png')" alt="image" v-else/>
        </div>
        <a href="#" class="fs-3 text-gray-800 text-hover-primary fw-bolder mb-1">{{patient?.patient_name }}</a>
        <div class="fs-5 fw-bold text-muted text-center mb-6">
          <span v-if="patient?.patient_birthdate">
            {{patient?.patient_full_age}} <br>
            {{date(patient?.patient_birthdate).format('DD/MM/YYYY')}}
          </span>
        </div>
        <div v-if="schedule" class="row rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6 w-100">
          <div class="col-md-4 mt-2">
            <i class="bi bi-alarm h1"></i>
          </div>
          <div class="col-md-8">
            <span class="h1">{{ activeTimerString }}</span>
          </div>
          <div class="fw-bold text-muted text-center">Duração da consulta</div>
        </div>
      </div>
      <div class="d-flex flex-stack fs-4 py-3">
        <div class="fw-bolder">
          Dados Cadastrais
        </div>
      </div>
      <div class="separator separator-dashed my-3"></div>
      <div>
        <div class="py-5 fs-6">
          <div v-if="schedule?.payment_method?.paymeth_name" class="fw-bolder mt-5">Forma de pagamento</div>
          <div v-if="schedule?.payment_method?.paymeth_name" class="text-gray-600">{{schedule?.payment_method?.paymeth_name}}</div>
          <div v-if="schedule?.sched_time" class="fw-bolder mt-5">Hora</div>
          <div v-if="schedule?.sched_time" class="text-gray-600">{{schedule?.sched_time}}</div>
          <div class="fw-bolder mt-5">CPF</div>
          <div class="text-gray-600">{{patient?.patient_cpf}}</div>
          <div class="fw-bolder mt-5">Telefone</div>
          <div class="text-gray-600">{{patient?.patient_phone}}</div>
          <div class="fw-bolder mt-5">E-mail</div>
          <div class="text-gray-600">{{patient?.user_email}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import str from "../../../helpers/str";

export default {
  name: "PatientCard",
  props: {
    patient: Object,
    schedule: Object,
  },
  data() {
    return {
      activeTimerString: 'Calculando...',
      counter: { seconds: 0, timer: null },
    }
  },
  async mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    this.stopTimer();
  },
  watch: {
    schedule(newSchedule, oldSchedule) {
       this.startTimer();
    }
  },
  methods: {
    date: moment,
      url(path) {
          return str.url(path)
      },
    _readableTimeFromSeconds: function(seconds) {
      const hours = 3600 > seconds ? 0 : parseInt(seconds / 3600, 10)
      return {
        hours: this._padNumber(hours),
        seconds: this._padNumber(seconds % 60),
        minutes: this._padNumber(parseInt(seconds / 60, 10) % 60),
      }
    },
    _padNumber: number =>  (number > 9 || number === 0) ? number : "0" + number,
    toggleExpandPatientData() {
      this.$emit('onToggleExpandPatientData', true);
    },
    startTimer: function () {
      if (this.schedule?.sched_start_timezone == null)
          return;

      const started = moment(this.schedule?.sched_start_timezone)

      this.counter.seconds = parseInt(moment.duration(moment().diff(started)).asSeconds())
      this.counter.timer = setInterval(() => {
        const time = this._readableTimeFromSeconds(++this.counter.seconds)
        this.activeTimerString = `${time.hours}:${time.minutes}:${time.seconds}`
      }, 1000)
    },
    stopTimer: function () {
      clearInterval(this.counter.timer);
      this.counter = { seconds: 0, timer: null }
      this.activeTimerString = 'Calculating...'
    },
  }
}
</script>

<style scoped>

</style>