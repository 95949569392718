<template>
  <div v-if="!hasPermission('List Place Access Type')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div id="kt_content_container" class=" container-xxl ">
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
              <div class="col-md-4" v-for="accessType in accessTypes">
                <div class="card card-flush h-md-100">
                  <div class="card-header">
                    <div class="card-title">
                      <h2>{{ accessType.access_type_name }}</h2>
                      <span class="badge rounded-pill ms-3"
                            :class="accessType.access_type_active ? 'badge-light-success' : 'badge-light-danger'">
                        {{ accessType.access_type_active ? 'Ativo' : 'Inativo' }}</span>
                      <span v-if="accessType.access_type_level !== 'CU'" class="badge rounded-pill ms-3 badge-light">Padrão do sistema</span>
                    </div>
                  </div>
                  <div class="card-body pt-1">
                    <div class="fw-bold text-gray-600 mb-5">Total de usuário desta regra: {{ accessType.users?.filter(user => user.is_member === true)?.length }}
                    </div>
                    <div class="symbol-group symbol-hover mb-3">
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip"
                           :title="user.user_name" v-for="user in accessType.users?.filter(user => user.is_member === true)"
                           :data-bs-original-title="user.user_name">
                        <img class="symbol-label fw-bolder w-100 " :src="url(user.user_photo)" v-if="user.user_photo"
                             :alt="user.user_name">
                        <span v-else class="symbol-label bg-ubbi text-inverse-warning fw-bolder">
                              {{ user.user_name.charAt(0).toUpperCase() }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex flex-column text-gray-600">
                      <div v-for="(group,index) in accessType.group_permissions">
                        <div v-if="index < 7" class="d-flex align-items-center py-2"><span
                            class="bullet bg-primary me-3"></span>
                          {{ group.group_permission_name }}
                        </div>
                      </div>
                      <div v-if="accessType?.group_permissions?.length > 7" class="d-flex align-items-center py-2">
                        <em>e mais {{ accessType.group_permissions.length - 10 }}</em>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer flex-wrap pt-0">
                    <button type="button" class="btn btn-light btn-active-light-primary my-1 me-4" data-bs-toggle="modal"
                            @click="this.selectedAccessType = accessType"
                            data-bs-target="#modal_users_role">Ver Usuários
                    </button>
                    <button type="button" class="btn btn-light btn-active-light-primary my-1" data-bs-toggle="modal"
                            @click="loadGroupPermissions(accessType)"
                            v-if="hasPermission('Update Place Access Type')"
                            data-bs-target="#modal_update_role">Editar Regra
                    </button>
                  </div>
                </div>
              </div>
              <div class="ol-md-4" v-if="hasPermission('Store Place Access Type')">
                <div class="card h-md-100">
                  <div class="card-body d-flex flex-center">
                    <button type="button" class="btn btn-clear d-flex flex-column flex-center" data-bs-toggle="modal"
                            data-bs-target="#modal_add_role" @click="addNewAcessType">
                      <img src="img/add_new_role.png" alt=""
                           class="mw-100 mh-150px mb-7">
                      <div class="fw-bold fs-3 text-gray-600 text-hover-primary">Adicionar nova Regra</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalUsersAccessType
          :accessType="this.selectedAccessType"
          :users="this.selectedAccessType.users"
          @onClose="this.loadAccessTypes()"/>

      <ModalUpdateAccessType
          :accessType="this.selectedAccessType"
          :groupPermissions="this.groupPermissions"
          @onClose="this.loadAccessTypes()"/>

      <ModalAddAccessType
          :accessType="this.selectedAccessType"
          :groupPermissions="this.groupPermissions"
          @onClose="this.loadAccessTypes()"/>
  </div>
</template>

<script>
import Permission from "../../helpers/permission";
import http from "../../helpers/http";
import ModalUsersAccessType from "./ModalUsersAccessType";
import ModalUpdateAccessType from "./ModalUpdateAccessType";
import ModalAddAccessType from "./ModalAddAccessType";
import str from "../../helpers/str";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "AccessType",
  components: {
    ModalUsersAccessType,
    ModalUpdateAccessType,
    ModalAddAccessType,
      Toolbar,
  },
  data() {
    return {
      accessTypes: [],
      groupPermissions: [],
      selectedAccessType: ''
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    this.loadAccessTypes();
  },
  methods: {
      url(path) {
          return str.url(path)
      },
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadAccessTypes() {
      http.get(`/places/${this.place.id_place}/access-types`).then((response) => {
        this.accessTypes = response.data;
      });
    },
    loadGroupPermissions(accessType) {
      this.selectedAccessType = accessType;
      http.get(`/places/${this.place.id_place}/access-types/${accessType.id_access_type}/group-permissions`).then((response) => {
        this.groupPermissions = response.data;
      });
    },
    addNewAcessType() {
      http.get(`/places/${this.place.id_place}/group-permissions`).then((response) => {
        this.selectedAccessType = {
          id_access_type: '',
          access_type_name: '',
          access_type_active: true,
          access_type_level: 'CU',
          users: [],
          group_permissions: this.groupPermissions = response.data
        };
      });
    }
  }
}
</script>

<style scoped>

</style>