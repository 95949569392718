<template>

  <div class="modal fade" id="modal_add_role" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="fw-bold">Cadastrar Nova Regra</h2>
          <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-roles-modal-action="close">
            <i class="ki-outline ki-cross fs-1"></i></div>
        </div>
        <ModalBodyAccessType
            :groupPermissions=this.groupPermissions
            :accessType=this.accessType
            @onSave=this.storePermissions()
            @onCancel=this.closeModal()
        />
      </div>
    </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import ModalBodyAccessType from "./ModalBodyAccessType";

export default {
  name: "ModalAddAccessType",
  props: {
    groupPermissions: null,
    accessType: null,
  },
  components: {
    ModalBodyAccessType,
  },
  emits: ["onClose"],
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    const modalElement = document.getElementById('modal_add_role');
    modalElement.addEventListener('hidden.bs.modal', this.closeModal);
  },
  beforeUnmount() {
    const modalElement = document.getElementById('modal_add_role');
    modalElement.removeEventListener('hidden.bs.modal', this.closeModal);
  },
  methods: {
    storePermissions() {
      const allowedPermissions = this.groupPermissions.flatMap(groupPermission =>
          groupPermission.permissions.filter(permission => permission.allowed).map(permission => permission.id_permission)
      );

      const notAllowedPermissions = this.groupPermissions.flatMap(groupPermission =>
          groupPermission.permissions.filter(permission => !permission.allowed).map(permission => permission.id_permission)
      );

      const data = {
        access_type: this.accessType,
        allowed_permissions: allowedPermissions,
        not_allowed_permissions: notAllowedPermissions
      }

      http.post(`/places/${this.place.id_place}/access-types`, data).then(_ => {
        this.closeModal();
        Alert.toastSuccess('Regra cadastrada com sucesso!');
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
    closeModal() {
      this.$emit('onClose');
      const modalElement = document.getElementById('modal_add_role');
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>