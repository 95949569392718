<script>
import {ScheduleService} from "../../service/scheduleService";
import Alert from "../../helpers/alert";

export default {
    name: "BtnArriveSchedule",
    props: ['scheduleEdit', 'classes', 'btnText'],
    emits: ['onArriveSchedule'],
    computed: {
        place() {
            return this.$store.state.auth.place;
        },
        userAuth() {
            return this.$store.state.auth.user;
        },
    },
    methods: {
        date: moment,
        arriveSchedule() {
            ScheduleService.new().arriveSchedule(this.place, this.scheduleEdit.id_sched, this.scheduleEdit.sched_arrive).then(() => {
                Alert.toastSuccess("Agendamento atualizada com sucesso.");

                this.$emit('onArriveSchedule', this.scheduleEdit.id_sched);
            }).catch((e) => {
                Alert.toastError(e.response.data);
            });
        },
    }
}
</script>

<template>
    <button v-show="!scheduleEdit?.sched_online && (scheduleEdit?.sched_status === 'CO' || scheduleEdit?.sched_status === 'AG' || scheduleEdit?.sched_arrive) && !scheduleEdit.sched_end && date(scheduleEdit?.schedule_date_time).isSame(date(), 'day')"
            @click.stop="this.arriveSchedule"
            type="button"
            :title="scheduleEdit?.sched_arrive ? 'Estornar chegada' : 'Marcar chegada'"
            class="btn me-2 mb-2 btn-flex"
            :class="classes + (scheduleEdit?.sched_arrive ? ' btn-warning' : ' btn-success')">
        {{btnText ?? ''}}
    </button>
</template>

<style scoped>

</style>