<template>
  <div class="modal fade" id="ModalProfSpecSelect" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Selecione as especilidades</h5>
          <button type="button" class="btn btn-sm btn-outline btn-outline-danger btn-active-light-danger ms-20 me-2" @click="unselectAllSpecialties" data-bs-toggle="tooltip" title="Desmarcar todos">
            <i class="bi bi-x-circle text-danger"></i>
          </button>
          <button type="button" class="btn btn-sm btn-outline btn-outline-success btn-active-light-success me-2" @click="selectAllSpecialties" data-bs-toggle="tooltip" title="Marcar todos">
            <i class="bi bi-check-circle text-success"></i>
          </button>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ul class="list-group">
            <li v-for="specialty in specialties" :key="specialty.id_spec" @click="selectSpecialty(specialty)" :class="['list-group-item', { active: isSpecialtySelected(specialty) }]">
              {{ specialty.spec_name }}
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary" @click="saveSelection">Replicar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalProfSpecSelect",
  props: {
    specialties: {
      type: Array,
      required: true,
    },
  },
  emits: ['onReplicate'],
  data() {
    return {
      selectedSpecialties: [],
    }
  },
  methods: {
    selectSpecialty(specialty) {
      const index = this.selectedSpecialties.findIndex(s => s.id_spec === specialty.id_spec);
      if (index === -1) {
        this.selectedSpecialties.push(specialty);
      } else {
        this.selectedSpecialties.splice(index, 1);
      }
    },
    saveSelection() {
      this.$emit('onReplicate', this.selectedSpecialties.map(specialty => specialty.id_spec));
    },
    isSpecialtySelected(specialty) {
      return this.selectedSpecialties.some(s => s.id_spec === specialty.id_spec);
    },
    selectAllSpecialties() {
      this.selectedSpecialties = [...this.specialties];
    },
    unselectAllSpecialties() {
      this.selectedSpecialties = [];
    }
  }
}
</script>