<template>
    <Toolbar />
  <div class="container-xxl">
    <div class="d-flex flex-column flex-xl-row">
      <div class="flex-column" v-if="isExpandPatientData">
        <button type="button" class="btn btn-white btn-active-light-primary btn-sm mr-10"
                @click="toggleExpandPatientData">
          <i class="bi bi-arrow-right-circle"></i>
        </button>
      </div>
      <div class="flex-column flex-lg-row-auto w-100 w-xl-300px mb-10" v-else>
        <PatientCard :patient="patient" @onToggleExpandPatientData="toggleExpandPatientData" />
      </div>
      <div class="flex-lg-row-fluid ms-lg-5">
        <History :schedule="schedule"
                 :record-types="recordTypes"
                 :exam-protocols="examProtocols"
                 :prescription-protocols="prescriptionProtocols"
                 :presc-types="prescTypes"
                 :drugs-admins="drugsAdmins"
                 :documentTemplates="documentTemplates"
                 :healthIndicatorGroups="healhImdicatorGroups"
                 :key="'history-' + patient?.id_patient"
                 :certifiers="certifiers"/>
      </div>
    </div>
  </div>
</template>

<script>

import PatientCard from "../../attendance/shared/PatientCard";
import History from "../../attendance/tab/History";
import http from "../../../helpers/http";
import Toolbar from "../../../components/layouts/auth/Toolbar.vue";

export default {
  name: "MedicalRecord",
  data() {
    return {
      professionalId: '',
      patientId: '',
      patient: '',
      recordTypes: [],
      examProtocols: [],
      prescriptionProtocols: [],
      prescTypes: [],
      drugsAdmins: [],
      documentTemplates: [],
      healhImdicatorGroups: [],
      certifiers: [],
      isExpandPatientData: false,
    }
  },
  components: {
    PatientCard,
    History,
      Toolbar,
  },
  created() {
    this.professionalId = this.$route.query.prof_id;
    this.patientId = this.$route.params.patientId;
    this.findPatient();
    this.getRecordTypes();
    this.getExamProtocols();
    this.getPrescriptionProtocols();
    this.getPrescTypes();
    this.getDrugAdmin();
    this.getDocumentTemplates();
    this.getHealthIndicatorGroups();
    this.getCertifiers();
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
    schedule() {
      return {
        id_sched: null,
        id_patient: this.patientId,
        id_prof: this.professionalId,
        availability: {
          id_prof: this.professionalId,
        },
      };
    }
  },
  methods: {
    findPatient() {
      return http.get(`/places/${this.place.id_place}/professionals/${this.professionalId}/patients/${this.patientId}`).then((response) => {
        this.patient = response.data;
      }).catch((e) => {
        let title = e.response.status === 404 ? 'Paciente não encontrado.' : 'Falha ao carregar o paciente.';
        Swal.fire({
          title: title,
          text: 'O paciente não existe.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: "btn btn-danger",
          }
        }).then(() => {
          this.$router.push('/patients');
        });
      });
    },
    getRecordTypes() {
      if (!this.professionalId)
        return;

      http.get(`/professionals/${this.professionalId}/record-types/actives`).then((response) => {
        this.recordTypes = response.data;
      });
    },
    getPrescriptionProtocols() {
      if (!this.professionalId)
        return;

      http.get(`/professionals/${this.professionalId}/prescription-protocols`).then((response) => {
        this.prescriptionProtocols = response.data;
      });
    },
    getPrescTypes() {
      if (this.schedule?.availability === null)
        return;

      this.precrTypes = [];
      http.get(`/prescription-types`).then((response) => {
        this.prescTypes = response.data;
      });
    },
    getDrugAdmin() {
      http.get(`drug-administrations`).then((response) => {
        this.drugsAdmins = response.data;
      });
    },
    getExamProtocols() {
      if (!this.professionalId)
        return;

      http.get(`/professionals/${this.professionalId}/exam-protocols`).then((response) => {
        this.examProtocols = response.data;
      });
    },
    toggleExpandPatientData() {
      this.isExpandPatientData = !this.isExpandPatientData;
    },
    getDocumentTemplates() {
      if (!this.professionalId)
        return;

      http.get(`/places/${this.place.id_place}/professionals/${this.professionalId}/document-templates`).then((response) => {
        this.documentTemplates = response.data;
      });
    },
    getHealthIndicatorGroups() {
      if (!this.professionalId)
        return;

      http.get(`/professionals/${this.professionalId}/health-indicator-groups`).then((response) => {
        this.healhImdicatorGroups = response.data;
      });
    },
    getCertifiers() {
      http.get(`/digital-certificates/certifiers`).then((response) => {
        this.certifiers = response.data;
      });
    }
  },
}
</script>

<style scoped>

</style>