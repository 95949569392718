<template>
  <div v-if="this.id" class="modal fade" data-keyboard="false" tabindex="-1" :id="this.id">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Selecione o Tipo de Cerficado para Assinatura Digital</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="col-md-12 mb-6 mt-4">
              <div class="form-check" v-for="certifier in certifiers">
                <input class="btn-check" style=": 0" type="radio" :id="'radio_'+this.id+'_'+certifier.id" :value="certifier.id" v-model="selectedCertifier" data-bs-toggle="modal" @click="go(certifier)">
                <label class="btn btn-outline btn-outline-dashed btn-active-light-primary p-4 d-flex align-items-center  mb-5 mt-5" :for="'radio_'+this.id+'_'+certifier.id">{{certifier.description}}</label>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SelectCertifier",
  emits: ["onSelect"],
  props: {
    id: String,
    certifiers: Array,
  },
  data() {
    return {
      selectedCertifier: "",
    }
  },
  mounted() {
    this.selectedCertifier = this.$store.state.auth.certifier?.id ?? "";
  },
  methods: {
    go(certifier) {
      this.$store.state.auth.certifier = certifier;
      this.$emit("onSelect", certifier);
    }
  },
}

</script>

<style scoped>

</style>