export default class Utils {

    static getFileIcon(fileMimeType) {
        if (fileMimeType === 'application/pdf')
            return 'bi-file-pdf'
        else if (fileMimeType === 'application/vnd.ms-excel')
            return 'bi-file-excel'
        else if (fileMimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            return 'bi-file-excel'
        else if (fileMimeType === 'application/msword')
            return 'bi-file-word'
        else if (fileMimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            return 'bi-file-word'
        else if (fileMimeType?.substring(0,5) === 'image')
            return 'bi-file-image'
        else if (fileMimeType?.substring(0,5) === 'video')
            return 'bi-film'
        else if (fileMimeType?.substring(0,5) === 'audio')
            return 'bi-music'
        else
            return 'bi-file-medical';
    };

    static getFileSvg(fileMimeType) {
        if (fileMimeType === 'application/pdf')
            return require('@/assets/images/files/pdf.svg')
        else if (fileMimeType === 'application/vnd.ms-excel')
            return require('@/assets/images/files/xls.svg')
        else if (fileMimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            return require('@/assets/images/files/xls.svg')
        else if (fileMimeType === 'application/msword')
            return require('@/assets/images/files/doc.svg')
        else if (fileMimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            return require('@/assets/images/files/doc.svg')
        else if (fileMimeType?.substring(0,5) === 'image')
            return require('@/assets/images/files/blank-image.svg')
        else if (fileMimeType?.substring(0,5) === 'video')
            return require('@/assets/images/files/video.svg')
        else if (fileMimeType?.substring(0,5) === 'audio')
            return require('@/assets/images/files/audio.svg')
        else if (fileMimeType === 'application/zip')
            return require('@/assets/images/files/zip.svg')
        else if (fileMimeType === 'text/html')
            return require('@/assets/images/files/html.svg')
        else if (fileMimeType === 'application/xml')
            return require('@/assets/images/files/xml.svg')
        else if (fileMimeType === 'pplication/atom+xml')
            return require('@/assets/images/files/xml.svg')
        else
            return require('@/assets/images/files/file.svg')
    };

    static isValidTime(time) {
        const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(time);
    }
}