<template>

  <table class="table table-row-bordered table-hover table-light gy-3">
    <thead>
    <tr class="fw-bold fs-6 text-muted">
      <th style="text-align: center">#</th>
      <th>Medicamento</th>
      <th>Posologia</th>
      <th style="text-align: center">Administração</th>
      <th v-if="!readonly" style="text-align: center">Açoes</th>
    </tr>
    </thead>
    <tr v-for="(prescDrug, index) in prescDrugs" :key="prescDrug.id_presc_drug">
      <td style="text-align: center">{{ index + 1 }}</td>
      <td style="white-space:pre-wrap; word-wrap:break-word">{{ prescDrug.drug_name }}</td>
      <td>{{ prescDrug.dosage_desc }}</td>
      <td style="text-align: center">{{ prescDrug.drug_administrations?.drug_admin_name }}</td>
      <td v-if="!readonly && !prescription.presc_request_generated" style="text-align: center">
        <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                data-bs-toggle="modal"
                :data-bs-target="'#modal_edit_drug_' + this.prescription.id_presc"
                @click="onClickBtnEditDrug(prescDrug)"
                title="Editar">
          <i class="bi bi-pencil-square"></i>
        </button>
        <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1" @click="deleteDrug(prescDrug.id_presc_drug)"
                data-bs-toggle="tooltip" title="Excluir Prescrição">
          <i class="bi bi-trash"></i>
        </button>
      </td>
    </tr>
  </table> <!-- Table -->

  <div class="modal fade" tabindex="-1" :id="'modal_store_drug_' + prescription.id_presc">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submitFormStoreDrug">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Adicionar medicamento</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="col-md-12 mb-6 mt-4">
              <label for="drug_name_drug_store" class="required form-label required">Medicamento</label>
              <textarea type="text" class="form-control form-control-solid"
                     v-model="formStoreDrug.drug_name" id="drug_name_drug_store" autofocus required rows="3">
              </textarea>
              <small class="text-danger" v-if="formStoreDrugErrors.drug_name">{{ formStoreDrugErrors.drug_name[0] }}</small>
            </div>
            <div class="col-md-12 mb-6 mt-4">
              <label for="dosage_desc_drug_store" class="required form-label required">Posologia</label>
              <input class="form-control form-control-solid"
                     v-model="formStoreDrug.dosage_desc" id="dosage_desc_drug_store" required>
              <small class="text-danger" v-if="formStoreDrugErrors.dosage_desc">{{ formStoreDrugErrors.dosage_desc[0] }}</small>
            </div>
            <div class="col-md-12 mb-6 mt-4">
              <label for="drug_admin_store" class="required form-label required">Via de Administração</label>
              <select class="form-control form-select" id="drug_admin_store"
                      aria-label="Selecione a via de adminstração" v-model="formStoreDrug.id_drug_admin" required>
                <option value="" disabled selected>--Selecione--</option>
                <option :value="drugAdmin.id_drug_admin" v-for="drugAdmin in drugAdmins">
                  {{ drugAdmin.drug_admin_name }}
                </option>
              </select>
              <small class="text-danger" v-if="formStoreDrugErrors.id_drug_admin">{{ formStoreDrugErrors.id_drug_admin[0] }}</small>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Cadastrar</button>
          </div>
        </div>
      </form>
    </div>
  </div> <!-- modal_store_drug -->

  <div class="modal fade" tabindex="-1" :id="'modal_edit_drug_' + prescription.id_presc">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submitFormEditDrug">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Alterar medicamento</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="col-md-12 mb-6 mt-4">
              <label for="drug_name_drug_edit" class="required form-label required">Medicamento</label>
              <textarea type="text" class="form-control form-control-solid"
                     v-model="formEditDrug.drug_name" id="drug_name_drug_edit" autofocus required rows="3">
              </textarea>>
              <small class="text-danger" v-if="formEditDrugErrors.drug_name">{{ formEditDrugErrors.drug_name[0] }}</small>
            </div>
            <div class="col-md-12 mb-6 mt-4">
              <label for="dosage_desc_drug_edit" class="required form-label required">Posologia</label>
              <input class="form-control form-control-solid"
                     v-model="formEditDrug.dosage_desc" id="dosage_desc_drug_edit" required>
              <small class="text-danger" v-if="formEditDrugErrors.dosage_desc">{{ formEditDrugErrors.dosage_desc[0] }}</small>
            </div>
            <div class="col-md-12 mb-6 mt-4">
              <label for="drug_admin_edit" class="required form-label required">Via de Administração</label>
              <select class="form-control form-select" id="drug_admin_edit"
                      aria-label="Selecione a via de adminstração" v-model="formEditDrug.id_drug_admin" required>
                <option value="" disabled selected>--Selecione--</option>
                <option :value="drugAdmin.id_drug_admin" v-for="drugAdmin in drugAdmins">
                  {{ drugAdmin.drug_admin_name }}
                </option>
              </select>
              <small class="text-danger" v-if="formEditDrugErrors.id_drug_admin">{{ formEditDrugErrors.id_drug_admin[0] }}</small>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Salvar</button>
          </div>
        </div>
      </form>
    </div>
  </div> <!-- modal_edit_drug -->

</template>

<script>
import http from "../../../helpers/http";
import Alert from "../../../helpers/alert";

export default {
  name: "PrescriptionDrugs",
  props: {
    medicalRecord: Object,
    prescription: Object,
    readonly: false,
    drugAdmins: Array,
  },
  data() {
    return {
      prescDrugs: [],
      formStoreDrug: {
        id_presc: '',
        id_drug_admin: '',
        drug_name: '',
        dosage_desc: '',
      },
      formStoreDrugErrors: [],
      formEditDrug: {
        id_presc: '',
        id_drug_admin: '',
        drug_name: '',
        dosage_desc: '',
      },
      formEditDrugErrors: [],
    }
  },
  mounted() {
    this.prescDrugs = this.prescription.drugs;
  },
  methods: {
    getPrescDrugs() {
      http.get(`/professionals/${this.medicalRecord.id_prof}/patients/${this.medicalRecord.id_patient}/medical-records/${this.prescription.id_medrec}/prescriptions/${this.prescription.id_presc}/drugs`).then((response) => {
        this.prescDrugs = response.data;
      });
    },
    submitFormStoreDrug() {
      this.formStoreDrug.id_presc = this.prescription.id_presc;
      http.post(`/professionals/${this.medicalRecord.id_prof}/patients/${this.medicalRecord.id_patient}/medical-records/${this.prescription.id_medrec}/prescriptions/${this.prescription.id_presc}/drugs`, this.formStoreDrug).then(_ => {
        this.formStoreDrug = {
          id_presc: '',
          id_drug_admin: '',
          drug_name: '',
          dosage_desc: '',
        };
        this.getPrescDrugs();
        Alert.toastSuccess("Medicamento cadastrado com sucesso.");
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
      });
    },
    onClickBtnEditDrug(prescDrug) {
      this.formEditDrug.id_presc_drug = prescDrug.id_presc_drug;
      this.formEditDrug.id_presc = prescDrug.id_presc;
      this.formEditDrug.id_drug_admin = prescDrug.id_drug_admin;
      this.formEditDrug.drug_name = prescDrug.drug_name;
      this.formEditDrug.dosage_desc = prescDrug.dosage_desc;
    },
    deleteDrug(presDrugId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o medicamento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/professionals/${this.medicalRecord.id_prof}/patients/${this.medicalRecord.id_patient}/medical-records/${this.prescription.id_medrec}/prescriptions/${this.prescription.id_presc}/drugs/${presDrugId}`).then(_ => {
            this.getPrescDrugs();
            Alert.toastSuccess("Medicamento excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },
    submitFormEditDrug() {
      this.formEditDrugErrors = [];
      http.put(`/professionals/${this.medicalRecord.id_prof}/patients/${this.medicalRecord.id_patient}/medical-records/${this.prescription.id_medrec}/prescriptions/${this.prescription.id_presc}/drugs/${this.formEditDrug.id_presc_drug}`, this.formEditDrug).then(() => {
        this.getPrescDrugs();
        Alert.toastSuccess("Medicamento atualizado com sucesso.");
        bootstrap.Modal.getInstance(document.getElementById('modal_edit_drug_' + this.prescription.id_presc)).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formEditDrugErrors = e.response.data.errors;
        }
      });
    },
  },
}
</script>

<style scoped>

</style>