export default class Base64 {
    static decode(str) {
        return window.atob(str)
    }

    static encode(str) {
        return window.btoa(str)
    }

    static str2Blob(str, type = 'application/pdf') {
        const byteString = this.decode(str)

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: type })
    }
}