<template>
  <div class="card card-flush h-100">
    <div class="card-header border-0 pt-5" style="padding: 2rem 0.4rem;">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder text-dark">Taxa de Ocupação</span>
      </h3>
      <div class="d-flex justify-content-around pt-2">
          <BusyRateLegend />
      </div>
    </div>
    <div class="card-body" style="padding: 0 5px 0.4rem 5px;">
        <div class="flex flex-wrap gap-3 p-fluid">
            <div class="flex-auto">
              <Calendar v-model="baseDate"
                        @dateSelect="$emit('onSelectDate', $event)"
                        inline showWeek @monthChange="handleMonthChange">
                <template #date="slotProps">
                  <div v-if="hasEvent(slotProps.date)">
                    <div
                        :style="{ color: getTextColor(slotProps.date), backgroundColor: getBackgoundColor(slotProps.date), padding: '1px', borderRadius: '50%', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}"
                        :title="`${getEventRate(slotProps.date)}%`">
                      <strong>{{ slotProps.date.day }}</strong>
                    </div>
                  </div>
                  <div v-else>
                    <strong style="text-decoration: line-through">
                      {{ slotProps.date.day }}
                    </strong>
                  </div>
                </template>
              </Calendar>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Calendar from "primevue/calendar";
import BusyRateLegend from "./BusyRateLegend.vue";

export default {
  name: "BusyRate",
  props: ['profId', 'specId'],
  components: {
      BusyRateLegend,
      Calendar,
  },
  data() {
    return {
      events: [],
      baseDate: '',
    }
  },
  watch: {
    profId() {
      this.loadBusyRate(this.baseDate);
    },
    specId() {
      this.loadBusyRate(this.baseDate);
    },
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    this.baseDate = this.date().startOf('month').format('YYYY-MM-DD');
    this.loadBusyRate(this.baseDate);
  },
  methods: {
    date: moment,
    handleMonthChange(newDate) {
      const formattedDate = `${newDate.year}-${(newDate.month).toString().padStart(2, '0')}-01`;
      this.loadBusyRate(formattedDate);
    },
    loadBusyRate(date) {
        if (!this.profId) {
            return;
        }

      const startDate = this.date(date).format('YYYY-MM-DD');
      const endDate = this.date(date).endOf('month').format('YYYY-MM-DD');

      return http.get(`/places/${this.place.id_place}/dashboard/professionals/${this.profId}/busy-rate`, {
        params: {
          id_spec: this.idSpec,
          start_date: startDate,
          end_date: endDate,
          general: false,
        }
      }).then((response) => {
        this.events = response.data;
      });
    },
    hasEvent(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      return this.events.some(event => event.date === searchDate);
    },
    getBackgoundColor(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      const event = this.events.find(event => event.date === searchDate);

      if (event?.background_color) {
        const {r, g, b} = this.hexToRgb(event.background_color);
        return `rgba(${r}, ${g}, ${b}, ${event.opacity})`;
      }

      return null;
    },
    hexToRgb(hex) {
      let r = 0, g = 0, b = 0;
      if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
      } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
      }
      return {r, g, b};
    },
    getTextColor(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      const event = this.events.find(event => event.date === searchDate);
      return event?.text_color;
    },
    getEventRate(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      const event = this.events.find(event => event.date === searchDate);
      return event?.rate;
    },
  },
}

</script>

<style>
.p-calendar {
    max-width: 100%;
    width: 100%;
}

.calendar-container {
    display: flex;
    justify-content: center;
}
.p-datepicker table th {
    padding: 0.1rem;
}

.p-datepicker table td {
    padding: 0.1rem;
}
</style>