export default class db {
    static storeToken(token) {
        localStorage.setItem('token_professional', token);
        return token;
    }
    static get token() {
        return localStorage.getItem('token_professional');
    }
    static get professionals() {
        return JSON.parse(localStorage.getItem('professionals'));
    }
    static storeProfessionals(professionals) {
        localStorage.setItem('professionals', JSON.stringify(professionals));
        return professionals;
    }
    static get place() {
        return JSON.parse(localStorage.getItem('place'));
    }
    static storePlace(place) {
        localStorage.setItem('place', JSON.stringify(place));
        return place;
    }
    static storeUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
        return user;
    }
    static get user() {
        return JSON.parse(localStorage.getItem('user'));
    }
    static get certifier() {
        return JSON.parse(localStorage.getItem('certifier'));
    }
    static storeCertifier(certifier) {
        localStorage.setItem('certifier', JSON.stringify(certifier));
        return certifier;
    }
    static get permissions() {
        if (!localStorage.getItem('permissions'))
            return [];

        return JSON.parse(localStorage.getItem('permissions'));
    }
    static storePermissions(permissions) {
        localStorage.setItem('permissions', JSON.stringify(permissions));
        return permissions;
    }
}
