<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <div class="d-flex flex-lg-row-fluid">
        <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
          <img class="theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20"
               :src="require('@/assets/images/undraw_my_password_re_ydq7.svg')" alt="" />
          <h1 class="text-gray-800 fs-2qx fw-bold text-center mb-7">O software médico feito para você</h1>
          <div class="text-gray-600 fs-base text-center fw-semibold">
            Todas as ferramentas que o seu consultório precisa para crescer, em um único lugar.
          </div>
        </div>
      </div>
      <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
        <div class="bg-body d-flex flex-center rounded-4 w-md-600px p-10">
          <div class="w-md-400px">
            <form class="form w-100" id="kt_new_password_form"
                  method="post" @submit.prevent="resetPassword">
              <div class="text-center mb-10">
                <h1 class="text-dark fw-bolder mb-3">Alterar senha</h1>
                <div class="text-gray-500 fw-semibold fs-6">Informe os dados abaixo
                  <router-link to="/login" class="link-primary fw-bold">Entrar</router-link>
                </div>
              </div>
              <div class="fv-row mb-8" data-kt-password-meter="true">
                <div class="mb-1">
                  <div class="position-relative mb-3">
                    <input class="form-control bg-transparent" type="password" v-model="formPassword.user_password"
                           placeholder="Senha" name="password" autocomplete="off" />
                    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                          data-kt-password-meter-control="visibility">
												<i class="bi bi-eye-slash fs-2"></i>
												<i class="bi bi-eye fs-2 d-none"></i>
											</span>
                  </div>
                  <div class="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                    <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                  </div>
                </div>
                <div class="text-muted">Tente usar letras, números e caracteres especiais.</div>
              </div>
              <div class="fv-row mb-8">
                <input type="password" placeholder="Repita a senha" name="confirm-password"
                       v-model="formPassword.user_password_confirmation"
                       autocomplete="off" class="form-control bg-transparent" />
              </div>
              <div class="d-grid mb-10">
                <button type="submit" id="kt_new_password_submit" :disabled="submitted" class="btn btn-primary">
                  <span class="indicator-label">Enviar</span>
                  <span class="indicator-progress" v-show="submitted">Por favor aguarde...
										<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";

export default {
  name: "ResetPassword",
  data() {
    return {
      errors: [],
      submitted: false,
      formPassword: {
        user_email: '',
        user_password: '',
        user_password_confirmation: '',
        token: '',
      }
    }
  },
  mounted() {
    this.formPassword.token = this.$route.query.token;
    this.formPassword.user_cpf = this.$route.query.user_cpf;
    this.formPassword.user_email = this.$route.query.user_email;
  },
  methods: {
    resetPassword() {
      this.submitted = true;
      http.post(`/auth/password/reset`, this.formPassword).then((response) => {
        this.$store.commit('auth/setUser', response.data.user);
        this.$store.commit('auth/setToken', response.data.token);

        this.submitted = false;
        Alert.toastSuccess("Senha alterada com sucesso.");

        this.formPassword = {
          user_email: '',
          user_password: '',
          user_password_confirmation: '',
        }

        this.$router.push('/login');
      }).catch((error) => {
        this.submitted = false;
        if (error.response.data?.errors) {
          this.errors = error.response.data.errors;
        } else {
          Alert.toastError(error.response.data)
        }
      });
    }
  }
}
</script>

<style scoped>

</style>