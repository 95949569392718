<script>
import Alert from "../../helpers/alert";
import http from "../../helpers/http";

export default {
  name: "BtnCancelSchedule",
  props: ['scheduleEdit', 'classes', 'btnText'],
  emits: ['onCancelSchedule', 'onUncancelSchedule'],
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    date: moment,
    confirmCancel() {
      Swal.fire({
        title: "Quem cancelou a consulta?",
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: "Profissional",
        denyButtonText: `Paciente`,
        confirmButtonColor: "#780f5a",
        denyButtonColor: "rgba(120,15,90,0.8)",
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelSchedule('PROFESSIONAL');
        } else if (result.isDenied) {
          this.cancelSchedule('PATIENT');
        }
      });
    },
    cancelSchedule(whoCanceled) {
      let scheduleId = this.scheduleEdit.id_sched;
      http.patch(`/places/${this.place.id_place}/schedules/${scheduleId}/cancel`, {canceled_by: whoCanceled}).then(() => {
        Alert.toastSuccess("Agendamento cancelado com sucesso.");

        this.$emit('onCancelSchedule');
        this.toggleModal();
      }).catch((e) => {
        Alert.toastError(e.response.data);
      });
    },
    uncancelSchedule() {
      let scheduleId = this.scheduleEdit.id_sched;
      http.patch(`/places/${this.place.id_place}/schedules/${scheduleId}/uncancel`).then(() => {
        Alert.toastSuccess("Cancelamento estornado com sucesso.");

        this.$emit('onUncancelSchedule');
      }).catch((e) => {
        Alert.toastError(e.response.data);
      });
    },
  }
}
</script>

<template>
  <button
    v-if="!scheduleEdit?.is_sched_canceled && !scheduleEdit?.sched_arrive && !scheduleEdit?.sched_start"
    class="btn btn-danger btn-flex me-2 mb-2" type="button"
    @click="confirmCancel">
    Cancelar consulta
  </button>
  <button
    v-if="scheduleEdit?.is_sched_canceled"
    class="btn btn-outline btn-outline-danger btn-flex me-2 mb-2" type="button"
    @click="uncancelSchedule">
    Estornar Cancelamento
  </button>
</template>

<style scoped>

</style>